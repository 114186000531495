import React, { useEffect, useMemo, } from 'react';
import ActionComponents from './ActionComponents';
import {
  Button,
  Loading,
  Table,
} from 'components';
import { READY_STATE, minute } from 'constants/index';
import { format } from 'date-fns';
import { useParams, } from 'react-router-dom';
import { 
  getSessionsWatching as getSessionsWatchingApi,
  getSessionsWatchingRecords as getSessionWatchingRecordsApi,
} from 'services/api/organization/session';
import { useAutoRefresh } from 'utils/hooks/useAutoRefresh';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiEmpty,
  UiActionWrapper,
  UiDateTimeWrapper,
  UiDateBox,
  UiUpdateTime,
  UiMonitorSessionTable,
} from './MonitorSessionTable.style';


/**
 * 看課管理頁面之表格內容
 */

const schema={
  time: { name: '時間', defaultValue: '' },
  // subject: { name: '科目', defaultValue: '' },
  sessionName: { name: '課程名稱', defaultValue: '' },
  status: { name: '課程狀態', defaultValue: '' },
  productName: { name: '課程類型', defaultValue: '' },
  className: { name: '班級名稱', defaultValue: '' },
  // nickname: { name: '機構暱稱', defaultValue: '' },
  studentInClassStatusText: { name: '學生異常', defaultValue: '/' },
  studentInClassStatus: { name: '學生連線', defaultValue: '/' },
  hostName: { name: '授課教師', defaultValue: '' },
  teacherInClassStatusText: { name: '授課教師異常', defaultValue: '/' },
  teacherInClassStatus: { name: '授課教師連線', defaultValue: '/' },
  owner: { name: '導師', defaultValue: '' },
  sessionId: { name: '教室id', defaultValue: '' },
};

// table 假資料
// const tableData = [
//   {
//     id: 1,
//     time: '09:00~10:00',
//     subject: '國語',
//     className: '班級1',
//     owner: '導師1',
//     sessionName: '首冊_接續第二課',
//     nickname: '機構暱稱1',
//     aaa: '學生異常1',
//     bbb: '學生連線1',
//     hostName: '授課教師1',
//     ccc: '授課教師異常1',
//     ddd: '授課教師連線1',
//   },
//   {
//     id: 2,
//     time: '11:00~12:00',
//     subject: '英文',
//     className: '班級2',
//     owner: '導師2',
//     sessionName: '龍騰高一上',
//     nickname: '機構暱稱2',
//     aaa: '學生異常2',
//     bbb: '學生連線2',
//     hostName: '授課教師2',
//     ccc: '授課教師異常2',
//     ddd: '授課教師連線2',
//   }
// ];


export const MonitorSessionTable = () => {
  const { organizationId, } = useParams();
  const [{
    isDidMount,
    isLoading,
    pagesDisplay,
    nowPage,
    rowsPage,
    totalPage,
    liveData,
    oneBoardData,
  }, setState ] = useSetState({
    isDidMount: false,
    isLoading: true,
    pagesDisplay: [20, 100],
    nowPage: 0,
    rowsPage: 100,
    totalPage: 0,
    liveData: null,
    oneBoardData: null,
  });

  /* 改變表格頁數 */
  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setState({ nowPage: newPage, rowsPage: newRowsPage });
  };

  /* 從 API 取得家教資料 */
  const fetchLiveData = async () => {
    setState({ isLoading: true });
    const { isSuccess, data } = await getSessionsWatchingApi(organizationId, nowPage * rowsPage, rowsPage);
    if(isSuccess) setState({ 
      liveData: data.sessions,
      totalPage: data.total,
    });
  };

  /* 設定 OneBoardApi payload */
  const setOneBoardApiPayload = () => {
    const payload = { 'sessions': [] };
    const params = [];
    
    liveData.forEach((item) => {
      const nowAt = Date.now();
      const startAt = item.startAt;
      const endAt = item.endAt;
      const isWatching = nowAt > startAt - 5 * minute && nowAt < endAt;

      // 是否在監控時間內（上課前五分鐘、上課中）
      if(isWatching){
        const sessionId = item?.id;   // 課程id
        const userId = item?.userId;  // 學生id
        const hostId = item?.hostId;  // 老師id
        const param = {
                        'sessionId': sessionId,
                        'users': [
                          userId,
                          hostId
                        ]
                      };

        // 將學生資料參數置於需求之API
        params.push(param);
      }
    });

    payload.sessions = params;
    return payload;
  };

  /* 取得 OneBoardApi payload */
  const getOneBoardApiPayload = () => {
    return setOneBoardApiPayload();
  };

  /* 從 API 取得OneBoard資料 */
  const fetchOneBoardData = async () => {
    const oneBoardData = [];
    const payload = getOneBoardApiPayload();  // 取得 OneBoardApi payload
    const { isSuccess, data } = await getSessionWatchingRecordsApi(payload);

    isSuccess &&
      data.forEach(item => {
        oneBoardData.push([item.users[0], item.users[1]]);
      });

    setState({ oneBoardData });
  };

  /* 取得課程狀態 */
  const getStatus = (status) => {
    if (status === 'new') return READY_STATE.NEW;
    if (status === 'ready') return READY_STATE.READY;
  };

  /* 設定顯示於表格上之課程資料 */
  const setSessionsData = (zip) => {
    const data = [];

    for(const entry of zip){
      data.push({
        sessionId: entry.liveData.id,
        userId: entry.liveData.userId,
        hostId: entry.liveData.hostId,
        type: entry.liveData.type,
        users: entry.liveData.users,
        resources: entry.liveData.resources,
        startAt: entry.liveData.startAt,
        endAt: entry.liveData.endAt,
        time: `${format(new Date(entry.liveData.startAt), 'HH:mm')}~${format(new Date(entry.liveData.endAt), 'HH:mm')}`,
        subject: entry.liveData.subjects[0].name,
        className: entry.liveData.groupName,
        owner: entry.liveData.groupOwnerName,
        sessionName: entry.liveData.name,
        // nickname: entry.liveData.userName,   //機構暱稱
        status: getStatus(entry.liveData.status),
        productName: entry.liveData.product.productName,
        studentInClassStatusText: entry.oneBoardData?.[0]?.inClassStatusText ?? '/',
        studentInClassStatus: entry.oneBoardData?.[0]?.internetStatusText ?? '/',
        hostName: entry.liveData.hostName,
        teacherInClassStatusText: entry.oneBoardData?.[1]?.inClassStatusText ?? '/',
        teacherInClassStatus: entry.oneBoardData?.[1]?.internetStatusText ?? '/',
      });
    }

    return data;
  };
  
  /* click 資訊更新 */
  const onClickUpdateHandler = () => {
    setState({ isLoading: true });
    fetchOneBoardData();
  };

  /* 課程資料「未進班狀態」置頂 */
  const sessionsDataSortByWarningField = (data) => {
    data.sort((a, b) => {
      const teacherInClassStatusTextA = a.teacherInClassStatusText;
      const teacherInClassStatusTextB = b.teacherInClassStatusText;

      if(teacherInClassStatusTextA === '未進班' && teacherInClassStatusTextB === '未進班') return 0;
      if(teacherInClassStatusTextA === '未進班') return -1;
      if(teacherInClassStatusTextB === '未進班') return 1;
    });
  };

  /* 設定警告欄位 */
  const setWarningField = (text) => {
    const style = {
      height: '35px',
      lineHeight: '35px',
      backgroundColor: '#F44336',
      color: '#FFF',
    };
    return <div style={style}>{text}</div>;
  };

  /* 課程資料 特殊欄位設定 */
  const exceptionFieldHandler = (data) => {
    data.forEach((value, index) => {
      // 「授課教師異常」欄位，設定警告style
      let teacherInClassStatusText = value.teacherInClassStatusText;
      if(teacherInClassStatusText === '未進班')
        data[index].teacherInClassStatusText = setWarningField(teacherInClassStatusText);
    });
  };

  /* 過濾掉取消的課程 */
  const filterCanceledSessions = (zip) => {
    return zip.filter(item => item.liveData.status !== 'cancel');
  };

  /* 設定完整資料（live API, OneBoardAPI）、資料排序、欄位style 於 sessionsData 狀態 */
  const sessionsData = useMemo(() => {
    if(isDidMount){
      let data = [],
          zip = [];

      // 未結束之課程寫入至 zip
      zip = liveData
              .filter((item) => item.endAt > Date.now())
              .map((data, i) => {
                return {
                  liveData: data,
                  oneBoardData: oneBoardData[i]
                };
              });
          
      // 已結束之課程寫回至 zip
      liveData.filter((item) => item.endAt <= Date.now())
              .forEach((data) => zip.push({ 
                                  liveData: data, 
                                  oneBoardData: []
                                }));

      zip = filterCanceledSessions(zip);

      // 設定組成資料至 data 變數
      data = setSessionsData(zip);

      // 課程資料「未進班狀態」置頂
      sessionsDataSortByWarningField(data);

      // 特殊欄位設定
      exceptionFieldHandler(data);
  
      setState({ 
        isLoading: false,
      });

      return data;
    }
  }, [oneBoardData]);

  /* 自動重整 */
  useAutoRefresh(15, fetchLiveData);

  useEffect(() => {
    setState({ isDidMount: true });
  }, []);

  /* trigger SessionWatching API */
  useEffect(() => {
    (isDidMount && organizationId) && fetchLiveData();
  }, [isDidMount, organizationId, nowPage, rowsPage]);

  /* trigger SessionsWatchingRecords API */
  useEffect(() => {
    (isDidMount && liveData) && fetchOneBoardData();

    const dailyList = setInterval(() => {
      (isDidMount && liveData) && fetchOneBoardData();
    }, 30000);

    return () => clearInterval(dailyList);
  }, [isDidMount, liveData]);

  return (
    <>
      {
        isLoading 
          ? <Loading />
          : totalPage === 0 
              ? <UiEmpty>今日沒有課程</UiEmpty>
              : <>
                  <UiActionWrapper>
                    <Button
                      buttonColor='info'
                      onClick={onClickUpdateHandler}
                    >資訊更新</Button>
                  </UiActionWrapper>

                  <UiDateTimeWrapper>
                    <UiDateBox>課程日期：{format(new Date(), 'MM/dd')}</UiDateBox>
                    <UiUpdateTime>上次更新時間：{format(new Date(), 'HH:mm:ss')}</UiUpdateTime>
                  </UiDateTimeWrapper>

                  <UiMonitorSessionTable>
                    <Table
                      schema={schema}
                      data={sessionsData}
                      ActionComponents={ActionComponents}
                      pagesDisplay={pagesDisplay}
                      totalPage={totalPage}
                      nowPage={nowPage}
                      rowPage={rowsPage}
                      changePage_Rows={changePage_Rows}
                    />
                  </UiMonitorSessionTable>
                </>
      }
    </>
  );
};
