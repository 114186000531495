import styled from 'styled-components';

export const UiSessionSummaryWrapper = styled.div`
    margin-bottom: 65px;
`;

export const UiTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 22px;
`;

export const UiClassNameBox = styled.div`
	color: #242C3F;
    font-family: Noto Sans CJK TC;
	font-size: 2rem;
	font-weight: 400;
`;

export const UiClassOwnerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
`;

export const UiClassOwnerIconBox = styled.div`
	display: flex;
	align-items: center;
	margin: 0 6.67px;
`;

export const UiOwnerBox = styled.div`
	color: ${({ color }) => color || '#454B5C'};
    font-family: Noto Sans CJK TC;
	font-size: 1.35rem;
	font-weight: 400;
`;

export const UiHorizontalLine = styled.hr`
	height: 1px;
	border: none;
	background-color: #D5D7DE;
	margin-top: 0;
	margin-bottom: 48px;
`;

export const UiVerticalLine = styled.hr`
	height: 16px;
	width: 2px;
	background: #D5D7DE;
	border: none;
	border-radius: 2px;
	margin: 0 16px;
`;

export const UiContentWrapper = styled.div`
    display: flex;
    overflow-x: auto;

    ::-webkit-scrollbar{ height: 6px; };
    ::-webkit-scrollbar-thumb{ 
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.3);
    };
`;

export const UiContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :nth-child(1){ 
        margin-right: 16px;
        width: 400px;
    };
    :nth-child(2){
        margin-right: 16px;
        width: 300px;
    };
    :nth-child(3){ 
        width: 300px;
    };
`;

export const UiSessionSummaryBox = styled.div`
    min-height: 336px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border: 1px solid #E4E7EC;
    border-radius: 8px;
`;

export const UiSummaryItemBox = styled.div`
    color: ${({ color }) => color || '#0E172C' };
    font-family: ${({ fontFamily }) => fontFamily };
    font-size: ${({ fontSize }) => fontSize || '1rem' };
    font-weight: 600;
    margin: 8px 0;
`;

export const UiSessionInfoBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 160px;
    min-width: 300px;
    background: #FFF;
    border: 1px solid #E4E7EC;
    border-radius: 8px;
`;

export const UiInfoIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    min-height: 64px;
    min-width: 64px;
    background: #F38A04;
    border-radius: 20px;
`;

export const UiInfoContentBox = styled.div`
    flex: 0.6;
`;

export const UiInfoTitle = styled.div`
    font-size: 20px;
`;

export const UiInfoContent = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const UiInfoContentItem = styled.div`
    font-weight: 700;
    vertical-align: text-bottom;

    :nth-child(1){ font-size: 40px; }
    :nth-child(2){
        font-size: 20px;
        transform: translate(10px, -9px);
    }
`;