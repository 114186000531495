import styled from 'styled-components';

export const UiExamModal = styled.div`
	display: block;
`;

export const UiFormBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
	flex-wrap: wrap;

	&::after {
		position: absolute;
		top: -8px;
		left: ${({position})=> position};
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #e4e7ec transparent;
		transition: 0.35s;
		content: '';
	}

	&::before {
		position: absolute;
		top: -7px;
		left: ${({position})=> position};
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #fff transparent;
		z-index: 1;
		transition: 0.35s;
		content: '';
	}
`;

export const UiTable = styled.table`
	margin: 0;
	padding: 0;
	width: 100%;
	background: none;
	border: 1px solid #d5d7de;
	border-collapse: collapse;
	border-spacing: 0;
	background-image: none;
`;

export const UiTr = styled.tr`
	cursor: pointer;
	transition: 0.35s;

	& + tr {
		border-top: 1px solid #d5d7de;
	}

	&:hover {
		background-color: #f5f5f5;
	}
`;

export const UiTd = styled.td`
	padding: 4px 33px;
	font-size: 12px;
	letter-spacing: 0.05em;
`;

export const UiErrorMsg = styled.div`
	width: 70%;
	font-size: 12px;
	color: #f00;
	line-height: 1.5;
`;
