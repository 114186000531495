import React from 'react';
import PropTypes from 'prop-types';
import { UiNumberInput, UiNumberText } from './NumberInput.style';
import { useSetState } from 'utils/hooks/useSetState';
import {
  TextField as MatTextField,
} from '@material-ui/core';


/**
 * input 計算文字限制
 */

export const NumberInput = ({
  label,
  maxLength = 35,
  value,
  onChange = () => {},
  disabled = false
}) => {
  const [{
    errorMsg,
  }, setState] = useSetState({
    errorMsg: null,
  });

  /* 驗證 input value */
  const validateInput = (value) => {
    const re = new RegExp(/^\s+/);  // 開頭空白

    if(re.test(value)){
      !value.replace(re, '') 
        ? setState({ errorMsg: '不可只有空白' })
        : setState({ errorMsg: null });
    }else if(value.length === 0){
      setState({ errorMsg: '此欄位為必填項目' });
    }else{
      setState({ errorMsg: null });
    }
  };

  /* 格式化 input value */
  const formatInput = (value) => {
    const re = new RegExp(/^\s+/);
    return value.replace(re, ''); // 刪除開頭空白 
  };

  const onChangeHandle = e => {
    validateInput(e.target.value);
    const value = formatInput(e.target.value);
    onChange(value);
  };

  return (
    <UiNumberInput>
      <MatTextField
        label={label}
        inputProps={{
          maxLength
        }}
        variant="outlined"
        onChange={onChangeHandle}
        value={value}
        disabled={disabled}
        helperText={errorMsg}
        error={errorMsg && true}
      />
      <UiNumberText>{value.length || 0} / {maxLength}</UiNumberText>
    </UiNumberInput>
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool
};


