import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const UiResourceInput = styled.div`
	> .content {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 4px;

		> input {
			margin-right: 10px;
			padding: 4px 8px;
			font-size: 14px;
			background-color: transparent;
			border: none;
			flex: 1;
		}

		> button {
			position: relative;
			padding: 8px;
			width: 100px;
			height: 40px;
			font-size: 14px;
			color: ${({disabled}) => disabled ? '##D5D7DE' : '#fff'};
			background: ${({disabled, isLoading}) => isLoading ? '#121232' : (disabled ? '#F2F4F8' : '#121232')};
			border: none;
			border-radius: 2px;
			line-height: 24px;
			cursor: ${({disabled}) => disabled ? 'notAllow' : 'pointer'};
		}
	}

	.footer {
		margin-top: 8px;

		.content {
			display: flex;
			justify-content: flex-end;

			> .errorMsg {
				color: #f00;
				flex: 1;
			}

			.remind {
				> svg {
					font-size: 18px;
					color: #121232;
				}
			}
		}

		> .tags {
			display: flex;
			align-items: center;
			width: 100%;
			flex-wrap: wrap;
			flex: 1;

			> .tag {
				display: flex;
				align-items: center;
				margin-right: 8px;
				padding: 0 8px;
				color: #fff;
				background-color: #121232;
				border-radius: 4px;
				margin-bottom: 8px;

				> .close {
					margin-top: 4px;
					margin-left: 4px;
					cursor: pointer;

					> svg {
						font-size: 18px;
					}
				}
			}
		}
	}
`;

export const UiCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	color: #fff;
`;
