import styled from 'styled-components';

export const UiSessionPerformance = styled.div`
    margin-bottom: 64px;
`;

export const UiTitleWrapper = styled.div`
    color: #242C3F;
    font-family: Noto Sans CJK TC;
	font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 18px;
`;

export const UiTableWrapper = styled.div`
    display: flex;
    overflow-x: auto;
    
    ::-webkit-scrollbar{ height: 6px };
    ::-webkit-scrollbar-thumb{
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.3);
    }
`;