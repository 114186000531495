import React from 'react';
import SVG from './svg';
import { Tooltip as MatTooltip } from '@material-ui/core';
import { UiSvgIcon, UiSvgIconCircleBg } from './Icon.style';


export const IconSvg = ({
  title,
  className,
  name,
  color,
  onClick,
  circleBg = false,
  size,
}) => {
  return (
    <>
      {
        circleBg ? <MatTooltip title={title}>
          <UiSvgIconCircleBg>
            <UiSvgIcon 
              onClick={onClick} 
              className={className} 
              component={SVG[name]} 
              color={color}
              fontSize={size}
            />
          </UiSvgIconCircleBg>
        </MatTooltip>
          : <UiSvgIcon
              onClick={onClick} 
              className={className} 
              component={SVG[name]} 
              color={color}
              fontSize={size}
            />
      }
    </>
  );
};
