import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar as MatAppBar,
  Toolbar as MatToolbar,
  Tabs as MatTabs,
  Tab as MatTab
} from '@material-ui/core';


// import { UiBsHeaderTab } from './BsHeaderTab.style';


/**
 * 上方header tab bar
 */

export const BsHeaderTab = ({
  data,
  activeIndex = 0,
  onChange = () => {},
}) => {

  return (
    <MatAppBar
      component="div"
      color="primary"
      position="static"
      elevation={0}
    >
      <MatToolbar>
        <MatTabs 
          value={activeIndex} 
          textColor="inherit" 
          onChange={onChange}
        >
          {
            data.map(tab => (
              <MatTab
                key={tab.label}
                textColor="inherit"
                label={tab.label}
                onClick={tab.onClick}
              />
            )
            )
          }
        </MatTabs>
      </MatToolbar>
    </MatAppBar>
  );
};

BsHeaderTab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string
    })
  ),
  activeIndex: PropTypes.number,
  onChange: PropTypes.func,
};


