import React,{ useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useClass } from 'store/class';
import { Table, Select, Icon, Button, Breadcrumbs, BsModal, ExamModal } from 'components';
import { useAlert } from 'utils/hooks/useAlert';
import { TextField as MatTextField } from '@material-ui/core';
import { UiExamTable, UiExamIcons } from './ExamTable.style';
import { usePagination } from 'utils/hooks/usePagination';
import {
  getOrganizationGroupExamList,
  getOrganizationGroupExamOptions,
  deleteOrganizationGroupExam,
  editOrganizationGroupExam
} from 'services/api/organization/exam';
import { getOrganizationStaffsByClass } from 'services/api/organization/teacher';
import { UiActionBox, UiflexBox } from 'styles';
import { format, getUnixTime } from 'date-fns/esm';


/**
 * 測驗列表
 */

 const schema = {
  name: {
    name: '測驗名稱',
    defaultValue: '',
  },
  eduSubjectName:{
    name:'學制科目',
    defaultValue: '',
  },
  questionAmount:{
    name: '題數',
    defaultValue: ''
  },
  startTime: {
    name: '開始時間',
    defaultValue: '',
    isCanSort: true
  },
  endTime: {
    name: '結束時間',
    defaultValue: '',
    isCanSort: true
  },
  ownerId:{
    name: '派卷老師',
    defaultValue: '',
  },
  markersName:{
    name: '批改老師',
    defaultValue: '',
  },
  testedUsersCount: {
    name: '應試人數',
    defaultValue: ''
  },
};

export const ExamTable = () => {
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const [{ classes }] = useClass();
  const { setAlert } = useAlert();

  const [{
    nowPage,
    rowsPage,
    subjectKey,
    sortKey,
    sortOrder,
    searchValue,
    placeholder,
    subjectMap,
    subjectOption,
    targetExamId,
    deleteModalState,
    isLoading,
    step,
    isOpenModal,
    isLoadingModal,
    okText,
    cancelText,
    teacherNowPage,
    teacherRowsPage,
    teacherSearchKey,
    teacherSearchValue,
    teachersData,
    teachersTotal,
    examData,
    isCanPrevious,
    subjectPlaceholder
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    subjectKey: '',
    sortKey:'',
    sortOrder:'desc',
    searchValue: '',
    placeholder: '搜尋測驗名稱',
    subjectMap: [],
    subjectOption: null,
    targetExamId: '',
    deleteModalState: false,
    isLoading: false,
    step: 'setUp',
    isOpenModal: false,
    isLoadingModal: false,
    okText: '下一步',
    cancelText: '取消',
    teacherNowPage: 0,
    teacherRowsPage: 10,
    teacherSearchKey: '',
    teacherSearchValue: '',
    teachersData: [],
    teachersTotal: 0,
    examData: null,
    isCanPrevious: true,
    subjectPlaceholder: '全部科目'
  });

  // 麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes?.dataInfo?.name || ''} - 試卷管理`,
      link: `/organization/${organizationId}/class/${classId}/exam`,
      icon: 'file'
    }
  ];

  const { data , total , run , remove, edit } = usePagination(
    (params) => getOrganizationGroupExamList({
      organizationId,
      groupId:classId,
      educationalSystem: classes.dataInfo.educationName,
      subject: subjectKey === 'all' ? '' : subjectKey,
      examName: searchValue,
      sortKey,
      sortOrder : sortKey ? sortOrder : '' ,
      ...params
    }),
    {
      resKey:'exams',
      resKeyId:'id',
      deps: [organizationId,classId],
      page:nowPage,
      limit:rowsPage,
      formatResult: data => data.map(item=> {
        const nowTime = getUnixTime(new Date(format(Date.now(),'yyyy/MM/dd HH:mm'))) * 1000;
        let testedUsersCount = '';
        if(nowTime > item.endAt && item.testedUsers.length === 0) {
          testedUsersCount = '全部缺考';
        }else {
          testedUsersCount = `${(item.testedUsers.length || 0).toString()} / ${classes.dataInfo.users.length}`;
        }
        return {
          ...item,
          testedUsersCount,
          startTime: format(item.startAt,'yyyy/MM/dd HH:mm'),
          endTime: format(item.endAt,'yyyy/MM/dd HH:mm'),
          markersName: item.markers.length > 0 ? item.markers.join(',') : ''
        };
      }),
    }
  );

  // 科目 select
  const onSubjectSelectHandler = value => {
    setState({
      subjectKey: value,
    });
  };

  // input change
  const onSearchChange = event => {
    setState({
      searchValue: event.target.value,
    });
  };

  const onFilterListHandle = async () => {
    setState({
      nowPage:0,
      rowsPage: 10
    });
    run({ isNeedReset:true });
  };

  // 換頁
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  //刪除測驗
  const onfetchDeleteExam = async () => {
    const params = {
      organizationId,
      groupId:classId,
      examId:targetExamId,
    };
    setState({
      isLoading: true,
    });
    const { isSuccess } = await deleteOrganizationGroupExam(params);
    if(isSuccess){
      setAlert('刪除成功!', 'success');
      remove({id: targetExamId , keyName:'id'});
    }else{
      setAlert('刪除失敗!', 'error');
    }
    setState({
      isLoading: false,
      deleteModalState:false,
      targetExamId:''
    });
  };

  //關閉刪除彈窗
  const onCloseDeleteModal = () => {
    setState({
      targetExamId: '',
      deleteModalState: false,
    });
  };

  // 操作
  const ActionComponents = ({ params }) => {
    const nowTime = getUnixTime(new Date(format(Date.now(),'yyyy/MM/dd HH:mm'))) * 1000;
    const { id,startAt,endAt,name,markers,period,testedUsers } = params;
    const onEdit = () => {
      if(nowTime > endAt) return;
      if(startAt > nowTime) {
        setState({
          isCanPrevious:true,
          step:'setUp',
          okText: '下一步',
          cancelText: '取消',
        });
      }else if(nowTime > startAt){
        setState({
          isCanPrevious:false,
          step: 'choose',
          okText: '派卷',
          cancelText: '上一步',
        });
      }
      setState({
        targetExamId: id,
        isOpenModal: true,
        examData:{
          examPeriod:period,
          startAt,
          endAt,
          examName:name,
          correctTeachers:markers
        }
      });
    };

    const onDelete = () => {
      setState({
        targetExamId: id,
        deleteModalState: true,
      });
    };

    const goResults = () => {
      history.push({
        pathname:`/organization/${organizationId}/class/${classId}/exam/${id}/results`,
        state: {
          examName:name
        }
      });
    };

    const isCanEnterAnswerResults = (testedUsers.length === 0 && nowTime > endAt) || startAt > nowTime;
    const answerResultsText = startAt > nowTime ? '尚未開始' : '作答情況';

    return (
      <UiExamIcons>
        <Icon.Svg
          name="EditPen"
          onClick={onEdit}
          color={nowTime > endAt ? '#E5E4E0':'#121232'}
        />
        <Icon.Svg
          name="Delete"
          onClick={onDelete}
        />
        <Button onClick={goResults} disabled={isCanEnterAnswerResults}>{answerResultsText}</Button>
      </UiExamIcons>
    );
  };

  const onSort = (type) => {
    const typeKey = {
      startTime:'startAt',
      endTime:'endAt',
    };
    switch(type){
      case 'startTime':
        setState({
          sortKey: 'startAt',
        });
        break;
      case 'endTime':
        setState({
          sortKey: 'endAt'
        });
        break;
      default:
        break;
    }
    if(sortKey === typeKey[type]){
      setState({
        sortOrder: sortOrder === 'desc' ? 'asc' : 'desc',
        nowPage:0,
      });
    }else{
      setState({
        sortOrder: 'asc',
        nowPage:0,
      });
    }
  };

  // 選單 API
  const fetchOrganizationGroupPaperOptions = async () => {
    const params = {
      organizationId,
      groupId: classId,
    };

    const { data, isSuccess } = await getOrganizationGroupExamOptions(params);
    if(isSuccess){
      const { subjects } = data;
      setState({
        subjectMap: subjects,
      });
    } else {
      setAlert('取得選項失敗!', 'error');
    }
  };

  // 派卷彈窗 ok
  const onModalSubmitHandle = async () => {
    if (step === 'setUp') {
      setState({
        step: 'choose',
        okText: '派卷',
        cancelText: '上一步',
      });
    }

    if (step === 'choose') {
      const { examPeriod, startAt, endAt, examName, correctTeachers } = examData;

      let payload = {
        organizationId,
        groupId: classId,
        examId:targetExamId,
        markers: correctTeachers,
      };

      if(isCanPrevious){
        payload = {
          ...payload,
          name: examName,
          startAt,
          endAt,
          period: parseInt(examPeriod),
        };
      }

      const { isSuccess,data, error } = await editOrganizationGroupExam(payload);
      if (isSuccess) {
        setAlert('派卷成功', 'success');
        setState({
          isOpenModal:false
        });
        edit({id:targetExamId, keyName:'id', newData: data});
      } else {
        const { errorCode } = error;
        if (errorCode === 192013) {
          setAlert('此班級沒有學生', 'error');
        } else {
          setAlert('派卷失敗', 'error');
        }
      }
    }
  };

  // 派卷彈窗 cancel
  const onModalCancelHandle = () => {
    switch (step) {
      case 'setUp':
        setState({
          isOpenModal: !isOpenModal,
        });
        break;
      case 'choose':
        setState({
          step: 'setUp',
          okText: '下一步',
          cancelText: '取消',
        });
        break;
      default:
        break;
    }
  };
  // 派卷彈窗 老師列表 搜尋
  const onTeacherInputChange = value => {
    setState({
      teacherSearchValue: value,
    });
  };

  // 派卷彈窗 老師列表 放大鏡
  const onTeacherInputClick = () => {
    if(!teacherSearchKey) return;

    setState({
      nowPage: 0,
    });

    const params = {
      organizationId,
      classId,
      nowPage: 0,
      rowsPage: teacherRowsPage,
      [teacherSearchKey]: teacherSearchValue,
    };

    getOrganizationStaffsByClass(params);
  };

  // 派卷彈窗 老師列表 下拉選單
  const onTeacherSelectHandler = value => {
    setState({
      teacherSearchKey: value,
      teacherNowPage: 0
    });
  };

  const onChangePageHandle = (e, newPage) => {
    setState({
      teacherNowPage: newPage
    });
  };

  const onChangeRowsPerPageHandle = e => {
    setState({
      teacherNowPage: 0,
      teacherRowsPage: parseInt(e.target.value, 10),
    });
  };

  const onChangeExamModal = examData => {
    setState({
      examData
    });
  };

  // teacher list for class
  const fetchOrganizationStaffsByClass = async params => {
    const { isSuccess, data } = await getOrganizationStaffsByClass(params);
    if (isSuccess) {
      setState({
        teachersData: data.userProfiles,
        teachersTotal: data.total,
      });
    } else {
      setAlert('取得老師列表失敗', 'error');
    }
  };

  useEffect(() => {
    const params = {
      organizationId,
      classId,
      nowPage: teacherNowPage,
      rowsPage: teacherRowsPage,
    };
    fetchOrganizationStaffsByClass(params);
  }, []);

  useEffect(() => {
    fetchOrganizationGroupPaperOptions();
  }, []);

  const emptySubjectOption = () => {
    const emptyOption = [{
      name: '尚無資料',
      value: '',
    }];
    setState({
      subjectOption: emptyOption,
      subjectKey: null,
      subjectPlaceholder: '尚無資料'
    });
  };

  useEffect(() => {
    const educationKey = classes.dataInfo?.educationName;
    if(educationKey) {
      if(!subjectMap[educationKey]) {
        emptySubjectOption();
      } else {
        const subjectOption = subjectMap[educationKey].map(item=>({
          ...item,
          value: item.code,
        }));

        const nextSubject = subjectOption.length > 0 ? [
          {
            code:'all',
            value:'all',
            name:'全部科目'
          },
          ...subjectOption
        ] : [];
        setState({
          subjectOption : nextSubject,
          subjectKey: '',
          subjectPlaceholder: '全部科目'
        });
      }
    }
    setState({
      searchValue: '',
    });
  }, [classes.dataInfo.educationName,subjectMap]);

  useEffect(() => {
    if(sortKey === '') return;
    onFilterListHandle();
  }, [sortKey,sortOrder]);


  return (
    <UiExamTable>
      {
        classId && 
          <UiActionBox>
            <Breadcrumbs list={BreadcrumbsList} />
          </UiActionBox>
      }
      <UiActionBox>
        <UiflexBox>
          <Select
            label={subjectPlaceholder}
            options={subjectOption}
            submitHandler={onSubjectSelectHandler}
            disabled={subjectKey === null}
          />
          <MatTextField
            variant='outlined'
            value={searchValue}
            placeholder={placeholder}
            onChange={onSearchChange}
            disabled={subjectKey === null}
          />
          <Button
            buttonColor='info'
            onClick={onFilterListHandle}
            disabled={subjectKey === null}
          >
              <Icon.Svg
                name="Zoom"
                color="#fff"
              />
          </Button>
        </UiflexBox>
        <Button
          buttonColor='info'
          icon='exitToApp'
          onClick={() => { history.goBack(); }}
        >
          回到上一頁
        </Button>
      </UiActionBox>
      <Table
        data={data}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={total}
        ActionComponents={ActionComponents}
        onSort={onSort}
        nowPage={nowPage}
      />
      <BsModal
        open={deleteModalState}
        title="是否刪除測驗"
        isFull={true}
        onOk={onfetchDeleteExam}
        onCancel={onCloseDeleteModal}
        isLoading={isLoading}
      >
        若學生已完成測驗，作答資料將一併刪除，是否確認刪除該測驗？
      </BsModal>
      <ExamModal
        type='edit'
        examData={examData}
        editStatus={step}
        title="派卷設定"
        isOpen={isOpenModal}
        isLoading={isLoadingModal}
        okText={okText}
        cancelText={cancelText}
        teacherData={teachersData}
        teacherTotal={teachersTotal}
        teacherNowPage={teacherNowPage}
        teacherRowsPage={teacherRowsPage}
        onSubmitHandle={onModalSubmitHandle}
        onCancelHandle={onModalCancelHandle}
        onTeacherSelectHandler={onTeacherSelectHandler}
        onTeacherInputChange={onTeacherInputChange}
        onTeacherInputClick={onTeacherInputClick}
        onChangePageHandle={onChangePageHandle}
        onChangeRowsPerPageHandle={onChangeRowsPerPageHandle}
        onChange={onChangeExamModal}
        isCanPrevious={isCanPrevious}
      />
    </UiExamTable>
  );
};
