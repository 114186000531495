import styled from 'styled-components';

export const UiSessionRecordWrapper = styled.div`
    margin-bottom: 64px;
`;

export const UiTitleWrapper = styled.div`
    color: #242C3F;
    font-family: Noto Sans CJK TC;
	font-size: 1.6rem;
	font-weight: 400;
	margin-bottom: 18px;
`;

export const UiTableWrapper = styled.div`
    display: flex;
    overflow-x: auto;
    padding-top: 30px;

    ::-webkit-scrollbar{ height: 6px };
    ::-webkit-scrollbar-thumb{
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

export const UiTimeLineContainer = styled.div`
`;

export const UiTimeLineRow = styled.div`
    display: flex;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

export const UiTimeLineRail = styled.div`
    height: 8px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FBE4E0;
    margin: auto;
`;

export const UiTimeLineTrack = styled.div`
    height: inherit;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: #EC7963;

    left: ${({left}) => `${left}px` };
	width: ${({right, left}) =>`${right - left}px`};
`;

export const UiTimeLineBlock = styled.div`
    width: 100px;
    color: #FFF;
    font-size: 1rem;
    font-weight: 500;
    padding: 16px;
    text-align: center;
    position: relative;

    background: ${({ bgc }) => bgc || '#FAFAFC'};

    :nth-child(2), :nth-child(3n + 5) {
        min-width: 216px;
        border-left: 1px solid rgb(249, 199, 79);
        border-right: 1px solid rgb(249, 199, 79);
    }

    &.head {
        height: 54px;

        @media screen and (max-width: 1280px) {
            height: 53px;
        }
    }

    &.body {
        height: 51px;

        @media screen and (max-width: 1280px) {
            height: 49.5px;
        }
    }
`;

export const UiTimeText = styled.div`
    color: #242C3F;
    font-size: 1rem;
    font-weight: 600;
    line-height: 30px;
    position: absolute;
    top: -30px;

    :nth-child(1){ left: -20px };
    :nth-child(2){ left: calc(100% - 20px) };
`;
