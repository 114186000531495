import { useEffect } from 'react';
import { minute, hours } from 'constants/index';

/**
 * 自動重新整理（自動呼叫程式）
 * ex. 13:15、14:15、15:15 ... 自動重新整理
 * 
 * @param {integer} timeMinute
 * @param {function} callback 
 */

export const useAutoRefresh = (
  timeMinute,
  callback,
) => {

  useEffect(() => {
    const d = new Date();
    let nowMinutes = d.getMinutes();
    let timeout = 0;
    let refresh = null;

    if(nowMinutes <= timeMinute){
      timeout = (15 - nowMinutes) * minute;
    }else{
      timeout = (60 - nowMinutes + timeMinute) * minute;
    }

    setTimeout(() => {
      callback();
      
      refresh = setInterval(() => {
        callback();
      }, hours);
    }, timeout);

    return () => clearInterval(refresh);
  }, []);
};