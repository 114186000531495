import { useSelector, useDispatch } from 'react-redux';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { useParams, useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';
import { uploadPdfFile as uploadPdfFileApi } from 'services/api/pdfConvert';
import {
  getAllResourcesList as getAllResourcesListApi,
  getResourcesList as getResourcesListApi,
  delBook as delBookApi,
  oneClassDelBook as oneClassDelBookApi,
  createResources as createResourcesApi,
  refreshResourceState as refreshResourceStateApi,
  getBookstoreBooks as getBookstoreBooksApi,
  getBookstoreTags as getBookstoreTagsApi
} from 'services/api/organization/resources';
import { convertArrayToMap } from 'utils/array';

const GET_RESOURCELIST = 'GET_RESOURCELIST';
const GET_ALL_RESOURCELIST = 'GET_ALL_RESOURCELIST';
const DEL_RESOURCE = 'DEL_RESOURCE';
const CREATE_RESOURCE = 'CREATE_RESOURCE';
const REFRESH_RESOURCE = 'REFRESH_RESOURCE';
const GET_BOOKSTORE_BOOKS = 'GET_BOOKSTORE_BOOKS';
const GET_BOOKSTORE_TAGS = 'GET_BOOKSTORE_TAGS';
const GET_BOOKSTORE_GRADE_TAGS = 'GET_BOOKSTORE_GRADE_TAGS';
const GET_BOOKSTORE_SUBJECT_TAGS = 'GET_BOOKSTORE_SUBJECT_TAGS';

const initState = {
  resources: {
    data: [],
    total: 0
  },
  allResourceList: {
    data: [],
    total: 0
  },
  bookstoreBooks: {
    data: [],
    total: 0
  },
  bookstoreTags: {
    data: {},
    dataMap: {}
  },
  gradeTags: {
    data: []
  },
  subjectTags: {
    data: []
  }
};

const actions = {
  getResources: ({ data, total }) => ({
    type: GET_RESOURCELIST,
    payload: { data, total },
  }),
  getAllResource: ({ data, total }) => ({
    type: GET_ALL_RESOURCELIST,
    payload: { data, total },
  }),
  delResource: params => ({
    type: DEL_RESOURCE,
    payload: params
  }),
  createReource: params => ({
    type: CREATE_RESOURCE,
    payload: params
  }),
  refreshResources: params => ({
    type: REFRESH_RESOURCE,
    payload: params
  }),
  getBookstoreBooks: params => ({
    type: GET_BOOKSTORE_BOOKS,
    payload: params
  }),
  getBookstoreTags: ({ data, category }) => ({
    type: GET_BOOKSTORE_TAGS,
    payload: {
      data,
      category
    }
  }),
  getGradeTags: ({ data }) => ({
    type: GET_BOOKSTORE_GRADE_TAGS,
    payload: {
      data,
    }
  }),
  getSubjectTags: ({ data }) => ({
    type: GET_BOOKSTORE_SUBJECT_TAGS,
    payload: {
      data,
    }
  })
};

export const useResource = () => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const isHome = Boolean(useRouteMatch({ path: '/home' }));
  const {
    resources,
    allResourceList,
    bookstoreBooks,
    bookstoreTags,
    gradeTags,
    subjectTags
   } = useSelector(state => state.resource);
  const { organizationId, classId } = useParams();

  //資源列表table用
  const getResourcesList = async params => {
    const { data, isSuccess, error } = await getResourcesListApi(isHome)(organizationId, classId)(params);
    if (isSuccess) {
      const { resources, total } = data;
      const resourcesData = resources.map(item => {
        item.id = item.resourceId;
        item['createTime'] = format(item.createdAt, 'yyyy-MM-dd HH:mm:ss');
        return item;
      });
      dispatch(actions.getResources({ data: resourcesData, total }));
      return resourcesData;
    } else {
      const errorCode = error.errorCode;
      if(errorCode === 70003){
        dispatch(actions.getResources({ data: [], total: 0 }));
      }else{
        setAlert('搜尋資源錯誤', 'error', errorCode);
      }
    }
  };

  //獲取全部的資源
  const getAllResource = async params => {
    if (allResourceList.length > 0) return allResourceList;
    const { data: { resources, total } } = await getAllResourcesListApi(isHome)(organizationId, classId)(params);
    dispatch(actions.getAllResource({ data: resources, total }));
    return resources;
  };

  //刪除資源
  const delResource = async resourceId => {
    try {
      await delBookApi(resourceId);
      const { isSuccess, error } = await oneClassDelBookApi(organizationId, resourceId);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
      dispatch(actions.delResource({ resourceId }));
      getResourcesList({ nowPage: 0, rowsPage: 10, });
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
  };

  //新增資源
  const createReource = async params => {
    try {
      let formData = new FormData();
      const { svg_realtext, compressPdfImg, file } = params;
      if (!file) return;
      const uploadParams = {
        type: svg_realtext ? 'svg_realtext' : 'svg_shapetext',
        compressImg: compressPdfImg ? true : false,
        creater: organizationId
      };
      formData.append('pdf', file[0]);
      formData.append('process', uploadParams.type);

      const result = await uploadPdfFileApi(uploadParams, formData);
      if (!result.errorMessage) {
        const data = {
          resource: result
        };
        const { isSuccess, error } = await createResourcesApi(organizationId, data);
        if (!isSuccess) throw error;
        dispatch(actions.createReource(params));
        getResourcesList({ nowPage: 0, rowsPage: 10, });
        setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      }
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
    }
  };

  //重新整理列表
  const refreshResources = async () => {
    try {
      const { isSuccess, error } = await refreshResourceStateApi(organizationId);
      if (!isSuccess) throw error;
      dispatch(actions.refreshResources());
      getResourcesList({ nowPage: 0, rowsPage: 10, });
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  const getBookstoreBooks = async(params) => {
    const { data, isSuccess, error } = await getBookstoreBooksApi(organizationId)(params);
    if(isSuccess){
      const { books, total } = data;
      dispatch(actions.getBookstoreBooks({ data: books, total }));
      return books;
    }else{
      const errorCode = error.errorCode;
      if(errorCode === 70003){
        dispatch(actions.getBookstoreBooks({ data: [], total: 0 }));
      }else{
        setAlert('搜尋資源錯誤', 'error', errorCode);
      }
    }
  };

  const getBookstoreTags = async ({ category, educationalSystem }) => {
    const { data } = await getBookstoreTagsApi(organizationId)({ category, educationalSystem });
    dispatch(actions.getBookstoreTags({ category, data }));
    return data;
  };

  const getGradeTags = async educationalSystem => {
    const category = 'Grade';
    const data = await getBookstoreTags({ category, educationalSystem });
    dispatch(actions.getGradeTags({ data }));
    return data;
  };

  const getSubjectTags = async educationalSystem => {
    const category = 'Subject';
    const data = await getBookstoreTags({ category, educationalSystem });
    dispatch(actions.getSubjectTags({ data }));
    return data;
  };

  return [
    { resources, allResourceList, bookstoreBooks, bookstoreTags, gradeTags, subjectTags }, // state
    {
      getAllResource,
      getResourcesList,
      delResource,
      createReource,
      refreshResources,
      getBookstoreBooks,
      getBookstoreTags,
      getGradeTags,
      getSubjectTags
     }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_RESOURCELIST: {
      const { data, total } = action.payload;
      return {
        ...state,
        resources: {
          data,
          total
        }
      };
    }
    case GET_ALL_RESOURCELIST: {
      const { data, total } = action.payload;
      return {
        ...state,
        allResourceList: {
          data,
          total
        }
      };
    }
    case GET_BOOKSTORE_BOOKS: {
      const { data, total } = action.payload;
      return {
        ...state,
        bookstoreBooks: {
          data,
          total
        },
      };
    }
    case GET_BOOKSTORE_TAGS: {
      const { data, category } = action.payload;
      return {
        ...state,
        bookstoreTags: {
          data: {
            ...state.bookstoreTags.data,
            [category]: data
          },
          dataMap: {
            ...state.bookstoreTags.dataMap,
            [category]: convertArrayToMap(data,'tagId')
          }
        }
      };
    }
    case GET_BOOKSTORE_GRADE_TAGS: {
      console.log(action);
      const { data } = action.payload;
      return {
        ...state,
        gradeTags: { data }
      };
    }
    case GET_BOOKSTORE_SUBJECT_TAGS: {
      console.log(action);
      const { data } = action.payload;
      return {
        ...state,
        subjectTags: { data }
      };
    }
    default:
      return state;
  }
};

export default reducer;
