import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  Divider as MatDivider,
  ListItem as MatListItem,
  ListItemIcon as MatListItemIcon
} from '@material-ui/core';

export const UiBsNavigator = styled.div`
	display: block;
`;

export const UiItem = styled(MatListItem)`
	padding-top: 1px;
	padding-bottom: 1px;
	background-color: ${({ theme }) => theme.palette.dark.block};

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.palette.dark.blockhover};
	}

	&:disabled {
		color: #ddd;
	}
`;

export const UiItemIcon = styled(MatListItemIcon)`
	margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
	min-width: auto;
`;

export const UiCategoryHeader = styled(MatListItem)`
	padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
	padding-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
	color: ${({ theme }) => theme.palette.common.white};
	background-color: ${({ theme }) => theme.palette.dark.block};
`;


export const UiItemCategory = styled(UiItem)`
	padding-top: ${({ theme }) => `${theme.spacing(2)}px`};
	padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
	background-color: ${({ theme }) => theme.palette.dark.blocktitle};
	box-shadow: 0 -1px 0 #404854 inset;
`;


export const UiOfficialName = styled.span`
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 27px;
		font-size: 1.5rem;
		color: ${({ theme }) => theme.palette.common.white};
		cursor: pointer;
		user-select: none;

		> img {
			width: 100%;
		}
`;

export const UiLink = styled(NavLink)`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	text-decoration: none;
	color: rgba(255, 255, 255, 0.7);

	&.active {
		color: #4fc3f7;
	}
`;

export const UiDivider = styled(MatDivider)`
	margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const UiTable = styled.table`
	margin: 0;
	padding: 0;
	width: 100%;
	background: none;
	border: 1px solid #d5d7de;
	border-collapse: collapse;
	border-spacing: 0;
	background-image: none;
`;

export const UiTr = styled.tr`
	cursor: pointer;
	transition: 0.35s;

	& + tr {
		border-top: 1px solid #d5d7de;
	}

	&:hover {
		background-color: #f5f5f5;
	}
`;

export const UiTh = styled.th`
	padding: 4px 33px;
	font-size: 12px;
	text-align: left;
	color: #fff;
	background-color: #242c3f;
	letter-spacing: 0.05em;
`;

export const UiTd = styled.td`
	padding: 4px 33px;
	font-size: 12px;
	letter-spacing: 0.05em;
`;

export const UiContent = styled.div`
	overflow-x: hidden;
	margin-top: 16px;
	max-height: 452px;
`;


