import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';
import { useClass } from 'store/class';
import { Table, Select, Icon, Button, Breadcrumbs, ExamModal, BsModal } from 'components';
import { TextField as MatTextField } from '@material-ui/core';
import { UiActionBox, UiflexBox } from 'styles';
import { UiExamPaperTable, UiExamIcons } from './ExamPaperTable.style';
import { usePagination } from 'utils/hooks/usePagination';
import { getGroupInfoById } from 'services/api/organization/class';
import {
  getOrganizationGroupPaperList,
  getOrganizationGroupPaperOptions,
  createOrganizationGroupExam,
} from 'services/api/organization/exam';
import { getOrganizationStaffsByClass } from 'services/api/organization/teacher';


/**
 * 試卷列表
 */


const schema = {
  schoolYear: {
    name: '學年度',
    defaultValue: '',
  },
  eduSubjectName:{
    name:'學制科目',
    defaultValue: '',
  },
  paperName: {
    name: '試卷名稱',
    defaultValue: '',
  },
  groupUsageCount: {
    name: '已派卷次數',
    defaultValue: ''
  },
};

export const ExamPaperTable = () => {
  const history = useHistory();

  const { organizationId, classId } = useParams();
  const { setAlert } = useAlert();

  // store class
  const [{ classes }] = useClass();

  const [{
    nowPage,
    rowsPage,
    subjectKey,
    searchValue,
    placeholder,
    step,
    paperId,
    subjectMap,
    subjectOption,
    isOpenModal,
    isLoadingModal,
    okText,
    cancelText,
    teacherNowPage,
    teacherRowsPage,
    teacherSearchKey,
    teacherSearchValue,
    examData,
    teachersData,
    teachersTotal,
    isPromptModal,
    classUsers,
    subjectPlaceholder,
    examModalTitle,
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    subjectKey: '',
    searchValue: '',
    placeholder: '搜尋試卷名稱',
    step: 'setUp',
    paperId: '',
    subjectMap: [],
    subjectOption: [],
    isOpenModal: false,
    isLoadingModal: false,
    okText: '下一步',
    cancelText: '取消',
    teacherNowPage: 0,
    teacherRowsPage: 10,
    teacherSearchKey: '',
    teacherSearchValue: '',
    examData: null,
    teachersData: [],
    teachersTotal: 0,
    isPromptModal: false,
    classUsers: [],
    subjectPlaceholder:'全部科目',
    examModalTitle: '我要派卷',
  });

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes?.dataInfo?.name || ''} - 試卷管理`,
      link: `/organization/${organizationId}/class/${classId}/exam`,
      icon: 'file'
    }
  ];

  const { data , total , run } = usePagination(
    (params) => getOrganizationGroupPaperList({
      organizationId,
      groupId:classId,
      educationalSystem: classes.dataInfo?.educationName,
      subject: subjectKey === 'all' ? '' : subjectKey,
      paperName: searchValue,
      ...params
    }),
    {
      resKey:'papers',
      resKeyId:'paperId',
      deps:[organizationId, classId, classes.dataInfo?.educationName],
      page:nowPage,
      limit:rowsPage,
      formatResult: data => data.map(item=> {
        return {
          ...item,
          groupUsageCount: item.groupUsageCount.toString()
        };
      })
    });

  // 科目 select
  const onSubjectSelectHandler = value => {
    setState({
      subjectKey: value,
    });
  };

  // input change
  const onSearchChange = event => {
    setState({
      searchValue: event.target.value,
    });
  };

  // 換頁
  const changePage_Rows = params => {
    // newPage 第幾頁
    // newRowsPage 一頁幾筆
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  // 沒有學生的彈窗控制
  const onPrompt = () => {
    setState({
      isPromptModal:!isPromptModal
    });
  };

  // 操作
  const ActionComponents = ({ params }) => {
    const { paperId } = params;
    const isCantCreate = classUsers?.length > 0;
    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${paperId}`;
      window.open(url);
    };

    const onCreateExam = () => {
      setState({
        paperId,
        isOpenModal: true,
      });
    };

    return (
      <UiExamIcons>
        <Icon.Svg
          name="Visibility"
          onClick={onViewSession}
        />
        <Icon.Svg
          name="Plane"
          onClick={isCantCreate ? onCreateExam : onPrompt}
        />
      </UiExamIcons>
    );
  };

  const emptySubjectOption = () => {
    const emptyOption = [{
      name: '尚無資料',
      value: '',
    }];
    setState({
      subjectOption: emptyOption,
      subjectKey: null,
      subjectPlaceholder: '尚無資料'
    });
  };

  const onFilterListHandle = async () => {
    run({ isNeedReset:true });
  };

  // 派卷彈窗 ok
  const onModalSubmitHandle = async () => {
    if (step === 'setUp') {
      setState({
        step: 'choose'
      });
    }

    if (step === 'choose') {
      const { examPeriod, startAt, endAt, examName, correctTeachers } = examData;

      let payload = {
        organizationId,
        groupId: classId,
        paperId,
        name: examName,
        period: parseInt(examPeriod),
        markers: correctTeachers,
      };

      if(startAt && endAt) {
        payload = {
          ...payload,
          startAt,
          endAt
        };
      }

      setState({
        isLoadingModal: true,
      });

      const { isSuccess, error } = await createOrganizationGroupExam(payload);
      setState({
        isLoadingModal: false,
      });

      if (isSuccess) {
        setAlert('派卷成功', 'success');
        setState({
          isOpenModal: false,
        });
      } else {
        const { errorCode } = error;
        if (errorCode === 192013) {
          setAlert('此班級沒有學生', 'error');
        } else {
          setAlert('派卷失敗', 'error');
        }
      }
    }
  };

  // 派卷彈窗 cancel
  const onModalCancelHandle = () => {
    switch (step) {
      case 'setUp':
        setState({
          isOpenModal: !isOpenModal,
        });
        break;
      case 'choose':
        setState({
          step: 'setUp',
        });
        break;
      default:
        break;
    }
  };

  // 派卷彈窗 老師列表 下拉選單
  const onTeacherSelectHandler = value => {
    setState({
      teacherSearchKey: value,
      teacherNowPage: 0
    });
  };

  // 派卷彈窗 老師列表 搜尋
  const onTeacherInputChange = value => {
    setState({
      teacherSearchValue: value,
    });
  };

  // 派卷彈窗 老師列表 放大鏡
  const onTeacherInputClick = () => {
    if(!teacherSearchKey) return;

    setState({
      nowPage: 0,
    });

    const params = {
      organizationId,
      classId,
      nowPage: 0,
      rowsPage: teacherRowsPage,
      [teacherSearchKey]: teacherSearchValue,
    };

    fetchOrganizationStaffsByClass(params);
  };

  const onChangePageHandle = (e, newPage) => {
    setState({
      teacherNowPage: newPage
    });
  };

  const onChangeRowsPerPageHandle = e => {
    setState({
      teacherNowPage: 0,
      teacherRowsPage: parseInt(e.target.value, 10),
    });
  };

  const onChangeExamModal = examData => {
    setState({
      examData
    });
  };

  // 選單 API
  const fetchOrganizationGroupPaperOptions = async () => {
    const params = {
      organizationId,
      groupId: classId,
    };

    const { data, isSuccess } = await getOrganizationGroupPaperOptions(params);
    if(isSuccess){
      const { educationalSystems, subjects } = data;

      const educationalSystemOption = educationalSystems.map(item => ({
        ...item,
        value: item.code,
      }));
      const nextOptions = [
        {
          code:'all',
          name:'全部學制',
          value:'all'
        },
        ...educationalSystemOption
      ];
      setState({
        educationalSystemOption: nextOptions,
        subjectMap: subjects,
      });
    } else {
      setAlert('取得選項失敗!', 'error');
    }
  };

  // teacher list for class
  const fetchOrganizationStaffsByClass = async params => {
    const { isSuccess, data } = await getOrganizationStaffsByClass(params);
    if (isSuccess) {
      setState({
        teachersData: data.userProfiles,
        teachersTotal: data.total,
      });
    } else {
      setAlert('取得老師列表失敗', 'error');
    }
  };

  const fetchClasses = async () => {
    const { isSuccess,data } = await getGroupInfoById(organizationId,classId);
    if(isSuccess) {
      setState({
        classUsers: data.users,
      });
    }else{
      setAlert('取得學生列表失敗', 'error');
      setState({
        classUsers: [],
      });
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchOrganizationGroupPaperOptions();
  }, []);

  useEffect(() => {
    const educationKey = classes.dataInfo?.educationName;
    if(educationKey) {
      if(!subjectMap[educationKey]) {
        emptySubjectOption();
      } else {
        const subjectOption = subjectMap[educationKey].map(item=>({
          ...item,
          value: item.code,
        }));
        const nextSubject = subjectOption.length > 0 ? [
          {
            code:'all',
            value:'all',
            name:'全部科目'
          },
          ...subjectOption
        ] : [];
        setState({
          subjectOption : nextSubject,
          subjectKey: '',
          subjectPlaceholder: '全部科目'
        });
      }
    }
    setState({
      searchValue: '',
    });
  }, [classes.dataInfo?.educationName, subjectMap]);

  useEffect(() => {
    if (step === 'setUp') {
      setState({
        okText: '下一步',
        cancelText: '取消',
        examModalTitle: '我要派卷'
      });
    }

    if (step === 'choose') {
      const params = {
        organizationId,
        classId,
        nowPage: teacherNowPage,
        rowsPage: teacherRowsPage,
      };
      fetchOrganizationStaffsByClass(params);

      setState({
        okText: '派卷',
        cancelText: '上一步',
        examModalTitle: '選擇批改老師',
      });
    }
  }, [step]);

  return (
    <UiExamPaperTable>
      {
        classId &&
          <UiActionBox>
            <Breadcrumbs list={BreadcrumbsList} />
          </UiActionBox>
      }
      <UiActionBox>
        <UiflexBox>
          <Select
            label={subjectPlaceholder}
            options={subjectOption}
            submitHandler={onSubjectSelectHandler}
            disabled={subjectKey === null}
          />
          <MatTextField
            variant='outlined'
            value={searchValue}
            placeholder={placeholder}
            onChange={onSearchChange}
            disabled={subjectKey === null}
          />
          <Button
            buttonColor='info'
            onClick={onFilterListHandle}
            disabled={subjectKey === null}
          >
              <Icon.Svg
                name="Zoom"
                color="#fff"
              />
          </Button>
        </UiflexBox>
        <Button
          buttonColor='info'
          icon='exitToApp'
          onClick={() => { history.goBack(); }}
        >
          回到上一頁
        </Button>
      </UiActionBox>
      <Table
        data={data}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={total}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
      <ExamModal
        editStatus={step}
        title={examModalTitle}
        isOpen={isOpenModal}
        isLoading={isLoadingModal}
        okText={okText}
        cancelText={cancelText}
        teacherData={teachersData}
        teacherTotal={teachersTotal}
        teacherNowPage={teacherNowPage}
        teacherRowsPage={teacherRowsPage}
        onSubmitHandle={onModalSubmitHandle}
        onCancelHandle={onModalCancelHandle}
        onTeacherSelectHandler={onTeacherSelectHandler}
        onTeacherInputChange={onTeacherInputChange}
        onTeacherInputClick={onTeacherInputClick}
        onChangePageHandle={onChangePageHandle}
        onChangeRowsPerPageHandle={onChangeRowsPerPageHandle}
        onChange={onChangeExamModal}
      />
      <BsModal
        open={isPromptModal}
        title="警告"
        isFull={true}
        cancelDisplay={false}
        onOk={onPrompt}
        onCancel={onPrompt}
      >
        此班級內沒有學生
      </BsModal>
    </UiExamPaperTable>
  );
};

ExamPaperTable.propTypes = {};
