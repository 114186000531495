import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Table, Button, Breadcrumbs } from 'components';
import { Box, } from '@material-ui/core';
import {
  getOrganizationExamResult
} from 'services/api/organization/exam';
import { useClass } from 'store/class';
import { usePagination } from 'utils/hooks/usePagination';
import { UiStudentAnswer } from './StudentsAnswer.style';
import { format } from 'date-fns';
import { UiActionBox } from 'styles';

/**
 * 學生作答列表
 */

 const schema = {
  attendeeNumber: {
    name: '座號',
    defaultValue: '-',
  },
  userNickname:{
    name:'學生姓名',
    defaultValue: '',
  },
  statusText:{
    name:'狀態',
    defaultValue:'',
  },
  // submitTime:{
  //   name:'交卷時間',
  //   defaultValue:'',
  // },
  // answerPeriodText:{
  //   name:'作答時間',
  //   defaultValue:'',
  // },
  // score:{
  //   name:'分數',
  //   defaultValue:'-',
  // }
};

const EXAMNOTFINISHED = 'examNotFinished';
const ANSWERED = 'answered';
const MISSED = 'missed';
const TOBECORRECTED = 'toBeCorrected';

const EXAM_STATUS = {
  [EXAMNOTFINISHED]: '尚未作答',
  [ANSWERED]: '作答完成',
  [MISSED]: '缺考',
  [TOBECORRECTED]: '待批改'
};

export const StudentsAnswer = () => {
  const { organizationId, classId, examId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [{ classes }] = useClass();

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes?.dataInfo?.name || ''} - 試卷管理`,
      link: `/organization/${organizationId}/class/${classId}/exam`,
      icon: 'file'
    }
  ];

  const { data , total } = usePagination(
    () => getOrganizationExamResult({
      organizationId,
      groupId:classId,
      examId
    }),
    {
      resKeyId:'id',
      deps: [organizationId,classId],
      page: 0,
      limit: 100,
      formatResult: data => data.map(item=>{
        const { status, submittedAt, answerPeriod } = item;
        return {
          ...item,
          statusText: <div style={{ color: status === MISSED ? '#f00' : '#505565'}}>{EXAM_STATUS[status]}</div>,
          submitTime: submittedAt ? format(submittedAt,'yyyy-MM-dd HH:mm') : '-',
          answerPeriodText: answerPeriod ? `${answerPeriod} 分` : '-',
        };
      })
    });

  // 操作
  const ActionComponents = ({ params }) => {
    const { status, examId, userId  } = params;
    const goOneExam = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/report/${examId}/${userId}`;
      window.open(url);
    };

    return (
      <Button onClick={goOneExam} disabled={status !== ANSWERED}>
        作答結果
      </Button>
    );
  };

  return (
    <UiStudentAnswer>
      {
        classId && 
          <UiActionBox>
            <Breadcrumbs list={BreadcrumbsList} />
          </UiActionBox>
      }
      <Box display="flex" justifyContent="space-between" mb={2}>
        <div className="title">
          {location.state?.examName || ''}
        </div>
        <Button
          buttonColor='info'
          icon='exitToApp'
          onClick={() => { history.goBack(); }}
        >
          回到上一頁
        </Button>
      </Box>
      <Table
        data={data}
        schema={schema}
        totalPage={total}
        ActionComponents={ActionComponents}
        isNeedPagination={false}
      />
    </UiStudentAnswer>
  );
};
