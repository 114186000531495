/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { BsModal, Table } from 'components';
import { format } from 'date-fns';
import { stringify } from 'query-string';
import { CSSTransition } from 'react-transition-group';
import { useAlert } from 'utils/hooks/useAlert';
import { TabContext as MuiTabContext, Skeleton as MuiSkeleton } from '@material-ui/lab';
import {
  UiMuiTab,
  UiMuiTabPanel,
  UiMuiTabs,
  UiRecordingTimeWrapper,
  UiStartedRecordingTime,
  UiStoppedRecordingTime,
  UiTableWrapper,
  UiTabPanelWrapper,
} from './ModalSystemLog.style';

const schemaSessionRecord = {
  userId: { name: 'USERID', defaultValue: '' },
  ip: { name: 'IP', defaultValue: '' },
  enteringAndLeaving: { name: '進出教室時間', defaultValue: '' },
  inattentive: { name: '不專心', defaultValue: '' },
  onlineTime: { name: '在線時長', defaultValue: '' },
  raiseHand: { name: '舉手次數', defaultValue: '' },
  speakerSecond: { name: '說話時長', defaultValue: '' },
  trophy: { name: '獎盃', defaultValue: '' },
};

const schemaChatRecord = {
  createString: { name: '時間', defaultValue: '' },
  userId: { name: '帳號', defaultValue: '' },
  message: { name: '內容', defaultValue: '' },
};

const tabLabels = ['上課紀錄', '聊天記錄'];


export const ModalSystemLog = (props) => {
  const {
    isOpen,
    onCancel,
    roomId,
  } = props;
  const { setAlert } = useAlert();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isTriggerTabPanelAnimation, setIsTriggerTabPanelAnimation ] = useState(new Array(tabLabels.length).fill(true));
  const [ tabIndex, setTabIndex ] = useState(0);
  const [ usersRecordApiData, setUsersRecordApiData ] = useState([]);
  const [ recordingTimeApiData, setRecordingTimeApiData ] = useState([]);
  const [ chatRecordApiData, setChatRecordApiData ] = useState([]);

  const startedRecordingTime = parseInt(recordingTimeApiData[0]?.startedRecordingTime ?? 0);
  const stoppedRecordingTime = parseInt(recordingTimeApiData[0]?.stopedRecordingTime ?? 0);
  const startedRecordingTimeString = startedRecordingTime ? format(startedRecordingTime, 'yyyy-MM-dd HH:mm:ss') : '課程尚未開始';
  const stoppedRecordingTimeString = stoppedRecordingTime ? format(stoppedRecordingTime, 'yyyy-MM-dd HH:mm:ss') : '課程尚未結束';
  const startedRecordingTimeText = `開始錄影：${startedRecordingTimeString}`;
  const stoppedRecordingTimeText = `結束錄影：${stoppedRecordingTimeString}`;

  /* 上課紀錄 table data */
  const usersRecordTableData = useMemo(() => {
    const userIdStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '92px' };
    return (
      usersRecordApiData.map(record => {
        const { onLineDetails, sourceIp: ip, summary } = record;
        const userId = <div style={userIdStyle}>{record.userId}</div>;
        const enteringAndLeaving = onLineDetails.map(({ joined, left }, index) => {
          const entering = `進入：${format(joined, 'yyyy-MM-dd HH:mm:ss')}`;
          const leaving = `離開：${format(left, 'yyyy-MM-dd HH:mm:ss')}`;
          return <div key={index}>{entering}，{leaving}</div>;
        });
        const inattentive = summary.inattentive.toString();
        const onlineTime = `${summary.onLineTime.toString()}秒`;
        const raiseHand = summary.raiseHand.toString();
        const speakerSecond = `${Math.floor(summary.speakerSecond / 1000).toString()}秒`;
        const trophy = summary.trophy.toString();
        return {
          userId,
          ip,
          enteringAndLeaving,
          inattentive,
          onlineTime,
          raiseHand,
          speakerSecond,
          trophy,
        };
      })
    );
  }, [usersRecordApiData]);

  /* 聊天紀錄 */
  const chatRecordTableData = useMemo(() => {
    return (
      chatRecordApiData.map(record => {
        const { message, userId } = record;
        const createAt = parseInt(record?.createAt ?? 0);
        const createString = format(createAt, 'yyyy-MM-dd HH:mm:ss');
        return {
          createString,
          userId,
          message,
        };
      })
    );
  }, [chatRecordApiData]);

  /* change 系統紀錄 tabs */
  const changeTabsHandler = (event, index) => {
    let newArray = new Array(tabLabels.length).fill(false);
    let newArray_2 = new Array(tabLabels.length).fill(true);
    setTimeout(() => setIsTriggerTabPanelAnimation(newArray), 1);
    setTimeout(() => setTabIndex(index), 300);
    setTimeout(() => setIsTriggerTabPanelAnimation(newArray_2), 500);
  };

  /* 打 API，取得錄影時間 */
  const fetchRecordingTime = async () => {
    const queryString = stringify({ roomId });
    
    try {
      const response = await fetch(`${process.env.REACT_APP_ONEBOARD_API_URL}/Prod/agora/get_recording_times?${queryString}`);
      const recordingTime = await response.json();
      if(response.ok) {
        const { data } = recordingTime;
        setRecordingTimeApiData(data);
      }
    } catch (error) {
      if(error.toString().includes('TypeError: Failed to fetch'))
        setAlert(error, 'error', 502);
      else
        setAlert(error, 'error');
    }
  };
  /* 打 API，取得老師、學生之紀錄 */
  const fetchMultiUserRecord = async () => {
    const payload = { courseId: roomId };
    const requestInit = {
      method: 'POST',
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_ONEBOARD_API_URL}/Prod/oneboard/course/multiUserRecord`, requestInit);
      const multiUserRecord = await response.json();
      if (response.ok) {
        const { data } = multiUserRecord;
        setUsersRecordApiData(data);
      } else {
        const { errorCode, message } = multiUserRecord;
        setAlert(message, 'error', errorCode);
      }
    } catch (error) {
      if(error.toString().includes('TypeError: Failed to fetch'))
        setAlert(error, 'error', 502);
      else
        setAlert(error, 'error');
    }
  };
  /* 打 API，取得聊天紀錄 */
  const fetchChatRecord = async () => {
    const payload = stringify({ courseId: roomId });

    try {
      const response = await fetch(`${process.env.REACT_APP_ONEBOARD_API_URL}/Prod/oneboard/course/message?${payload}`);
      const chatRecord = await response.json();
      if(response.ok) {
        const { data } = chatRecord;
        setChatRecordApiData(data);
      } else {
        const { errorCode, message } = chatRecord;
        setAlert(message, 'error', errorCode);
      }
    } catch (error) {
      if(error.toString().includes('TypeError: Failed to fetch'))
        setAlert(error, 'error', 502);
      else
        setAlert(error, 'error');
    }
  };
  const fetchData = async () => {
    setIsLoading(true);
    await Promise.all([fetchRecordingTime(), fetchMultiUserRecord(), fetchChatRecord()]);
    setIsLoading(false);
  };
  useEffect(() => {
    isOpen && fetchData();
  }, [isOpen]);

  return (
    <BsModal
      cancelDisplay={false}
      isFull={true}
      maxWidth='1152px'
      oKDisplay={false}
      onCancel={onCancel}
      open={isOpen}
      title='系統紀錄'
    >
      <MuiTabContext value={tabIndex}>
        <UiMuiTabs
          value={tabIndex}
          onChange={changeTabsHandler}
        >
          {
            tabLabels.map((label, index) => 
              <UiMuiTab key={index} label={label} />
            )
          }
        </UiMuiTabs>
        <UiMuiTabPanel value={0}>
          {
            isLoading
              ? <MuiSkeleton variant='rect' width={1014} height={244} />
              : <CSSTransition
                  in={isTriggerTabPanelAnimation[0]}
                  classNames='tabPanel'
                  timeout={500}
                  unmountOnExit
                >
                  <UiTabPanelWrapper>
                    {
                      !!recordingTimeApiData.length &&
                        <UiRecordingTimeWrapper>
                          <UiStartedRecordingTime>{startedRecordingTimeText}</UiStartedRecordingTime>
                          <UiStoppedRecordingTime>{stoppedRecordingTimeText}</UiStoppedRecordingTime>
                        </UiRecordingTimeWrapper>
                    }
                    <UiTableWrapper>
                      <Table
                        data={usersRecordTableData}
                        isNeedPagination={false}
                        schema={schemaSessionRecord}
                      />
                    </UiTableWrapper>
                  </UiTabPanelWrapper>
                </CSSTransition>
          }
        </UiMuiTabPanel>
        <UiMuiTabPanel value={1}>
          {
            isLoading
              ? <MuiSkeleton variant='rect' width={1024} height={244} />
              : <CSSTransition
                  in={isTriggerTabPanelAnimation[1]}
                  classNames='tabPanel'
                  timeout={500}
                  unmountOnExit
                >
                  <UiTabPanelWrapper>
                    <UiTableWrapper>
                      <Table
                        data={chatRecordTableData}
                        isNeedPagination={false}
                        schema={schemaChatRecord}
                      />
                    </UiTableWrapper>
                  </UiTabPanelWrapper>
                </CSSTransition>
          }
        </UiMuiTabPanel>
      </MuiTabContext>
    </BsModal>
  );
};

ModalSystemLog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  roomId: PropTypes.string,
};
