import styled from 'styled-components';

export const UiCancelSessionModal = styled.div`
	display: block;
`;

export const UiErrorMessage = styled.div`
	color: ${({ theme }) => theme.palette.main.danger};
	font-size: 0.93rem;
	margin-left: 13px;

	@keyframes headShake {
		0% {
			transform: translateX(0);
		}
  	6.5% {
    	transform: translateX(-6px) rotateY(-9deg);
  	}
  	18.5% {
    	transform: translateX(5px) rotateY(7deg);
  	}
  	31.5% {
    	transform: translateX(-3px) rotateY(5deg);
  	}
  	43.5% {
    	transform: translateX(2px) rotateY(3deg);
  	}
  	50% {
			transform: translateX(0);
		}
	};

	&.errorMessage-enter {
		animation: 500ms headShake;
	};
`;