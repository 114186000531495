import React from 'react';
import PropTypes from 'prop-types';
import { BsModal, Select } from 'components';
import { CSSTransition } from 'react-transition-group';
import { useSetState } from 'utils/hooks/useSetState';
import { UiCancelSessionModal, UiErrorMessage } from './CancelSessionModal.style';


/**
 * 取消課程彈窗
 */

const options = [
  { name: '學生合法請假', value: '學生合法請假' },
  { name: '學生違法請假', value: '學生違法請假' },
  { name: '學生曠課', value: '學生曠課' },
  { name: '老師合法請假', value: '老師合法請假' },
  { name: '老師違法請假', value: '老師違法請假' },
  { name: '老師曠課', value: '老師曠課' },
  { name: '手動取消', value: '手動取消' },
];

export const CancelSessionModal = (props) => {
  const {
    cancelReason,
    isLoading,
    isOpen,
    isOpenDoubleCheck,
    onCancel,
    onCancelDoubleCheck,
    onChange,
    onOk,
    onOkDoubleCheck,
  } = props;

  const [{ triggerErrorMessageAnimation
  }, setState] = useSetState({
    triggerErrorMessageAnimation: false,
  });

  const clickOkHandler = async () => {
    await setState({ triggerErrorMessageAnimation: true });
    await setState({ triggerErrorMessageAnimation: false });
    onOk();
  };

  const changeSelectHandler = (value) => {
    onChange(value);
  };

  return (
    <UiCancelSessionModal>
      {/* 取消課程 選原因彈窗 */}
      <BsModal
        cancelDisplay={false}
        isFull={true}
        onCancel={onCancel}
        onOk={clickOkHandler}
        open={isOpen}
        title='取消課程'
      >
        <Select
          label='取消原因'
          options={options}
          submitHandler={changeSelectHandler}
          value={cancelReason}
          width='100%'
        />
        
        {!cancelReason && 
          <CSSTransition
            in={!triggerErrorMessageAnimation}
            classNames='errorMessage'
            timeout={500}
          >
            <UiErrorMessage>請選擇取消原因</UiErrorMessage>
          </CSSTransition>
        }

        <UiErrorMessage></UiErrorMessage>
      </BsModal>

      {/* 取消課程 double check 彈窗 */}
      <BsModal
        isFull={true}
        isLoading={isLoading}
        onCancel={onCancelDoubleCheck}
        onOk={onOkDoubleCheck}
        open={isOpenDoubleCheck}
        title='取消課程'
      >
        注意！取消的課程無法恢復，是否仍要取消？
      </BsModal>
    </UiCancelSessionModal>);
};

CancelSessionModal.propTypes = {
  cancelReason: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOpenDoubleCheck: PropTypes.bool,
  onCancel: PropTypes.func,
  onCancelDoubleCheck: PropTypes.func,
  onChange: PropTypes.func,
  onOk: PropTypes.func,
  onOkDoubleCheck: PropTypes.func,
};


