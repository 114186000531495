import styled from 'styled-components';

export const UiEmpty = styled.div`
	font-size: 1.5rem;
`;

export const UiActionWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-bottom: 15px;
`;

export const UiDateTimeWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #8B90A0;
	font-size: 1.1rem;
	margin-bottom: 15px;
`;

export const UiDateBox = styled.div`
`;

export const UiUpdateTime = styled.div`
`;

export const UiMonitorSessionTable = styled.div`
`;