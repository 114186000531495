import React, { useEffect,useMemo } from 'react';
import PropTypes from 'prop-types';

import { successColor } from 'assets/jss/material-dashboard-pro-react.js';
import { useHistory, useParams } from 'react-router-dom';
import { addMinutes, getTime } from 'date-fns';
import { EDIT_STATUS } from 'constants/index';
import { useCustomer } from 'store/customer';
import { useSessions } from 'store/sessions';
import { getUnixTime,format } from 'date-fns';
import { getOneTeacher } from 'services/api/home/users';
import {
  getSessionTags,
  getSessionInfo,
  getSessionResources,
  cancelOrganizationGroupSession as cancelOrganizationGroupSessionApi,
 } from 'services/api/organization/session';
 import {
  checkResourceById
 } from 'services/api/organization/resources';
import { userPaidProducts as userPaidProductsApi } from 'services/api/home/sessions';
import { getGroupInfoById } from 'services/api/organization/class';
import { useOrganization } from 'store/organization';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import {
  BsModal,
  Button,
  CancelSessionModal,
  DateTimePicker,
  Icon,
  Loading,
  RadioGroup,
  ResourceInput,
  Select,
  TransferListModal,
} from 'components';
import {
  UiSessionForm,
  UiFormWrapper,
  UiTitleContainer,
  UiButtonContainer,
  UiWarningMessageContainer,
  UiWarningMessage,
  UiTabs,
  UiTab,
  UiLabel,
  UiTipWrapper,
  UiErrorMessageWrapper,
  UiErrorMessageContainer,
  UiErrorMessage,
  UiIconLabelContainer,
  UiRadioGroupContainer,
 } from './SessionForm.style';
import {
  TextField,
  Box,
  Divider
 } from '@material-ui/core';

const SelectOptions = [
  { name: '15分', value: 15 },
  { name: '25分', value: 25 },
  { name: '30分', value: 30 },
  { name: '45分', value: 45 },
  { name: '50分', value: 50 },
  { name: '60分', value: 60 },
  { name: '90分', value: 90 },
  { name: '120分', value: 120 }
];

const OTHER = 'other';

const SessionTypeMap = {
  // normal: '一般課程',
  video: '視訊課程',
  interactive: '互動課程',
  collaborative: '協作課程'
};

const PRIORITY = {
  TEACHER:'teacher',
  TIME:'time'
};

const teacherOptions = [
  { name:'姓名', value: 'teacherName' },
  { name:'帳號ID', value: 'teacherId' },
];

export const SessionForm = ({ editStatus }) => {
  const isEdit = (editStatus === EDIT_STATUS.EDIT);
  const history = useHistory();

  const [{ customers }] = useCustomer();
  const { organizationId,classId,sessionId } = useParams();
  const [{ orgSubjects },{ getOrganizationSubjects }] = useOrganization();
  const [{ myOrganization: { organization } }] = useUser();
  const [, { createOrganizationSession, deleteOrganizationGroupSession, updateOrganizationSession }] = useSessions();
  const { setAlert } = useAlert();
  const nowTime = getUnixTime(new Date(format(Date.now(),'yyyy/MM/dd HH:mm'))) * 1000;
  const [{
    sessionType,
    subjectCode,
    startAt,
    duration,
    hostId,
    hostNickname,
    errorMsg,
    resources,
    isResourceLoading,
    timeSpanId,
    teacherTags,
    teacherTag,
    preExamId,
    postExamId,
    name,
    priority,
    teachers,
    educationName,
    firstEnter,
    teacherSearchType,
    teacherSearchValue,
    isCanceledSession,
    isLoading,
    isLoadingSubmit,
    isOpenCancelSessionModal,          // 是否開啟取消課程彈窗
    isOpenCancelSessionDoubleCheckModal,     // 是否開啟恢復課程彈窗
    isOpenSessionPointNotEnoughModal,  // 是否開啟堂數不足彈窗狀態
    isOpenSessionRemoveModal,          // 是否開啟刪除課程彈窗（舊制用）
    isPointSystem,       // 判斷是否為新制堂數系統
    productId,           // 課程類型（商品 id）
    productName,         // 課程類型（商品 name）
    cancelReason,        // 課程取消原因
    sessionPoint,        // 課程堂數
    sessionPointOptions, // 課程堂數選項
    sessionPointNotEnoughList,  // 堂數不足名單
    sessionPreparation,         // 備課狀態
    sessionPreparationData,     // 備課狀態選項
    studentIds,          // 班級內所有學生 id
    userPaidProducts,    // 學生（課程類型）資料
  },setState] = useSetState({
    sessionType:'collaborative',
    subjectCode:'',
    startAt: nowTime,
    duration: 30,
    hostId:'',
    hostNickname:'',
    errorMsg:'',
    resources:[],
    isResourceLoading:false,
    timeSpanId:'',
    teacherTags:[],
    teacherTag:'',
    preExamId:'',
    postExamId:'',
    name:'',
    priority:PRIORITY.TIME,
    teachers:[],
    educationName:'',
    firstEnter: false,
    teacherSearchType: '',
    teacherSearchValue: '',
    isCanceledSession: false,
    isLoading: false,
    isLoadingSubmit: false,
    isOpenCancelSessionModal: false,
    isOpenCancelSessionDoubleCheckModal: false,
    isOpenSessionPointNotEnoughModal: false,
    isOpenSessionRemoveModal: false,
    isPointSystem: true,
    productId: '',
    productName: '',
    cancelReason: '',
    sessionPoint: '',
    sessionPointOptions: [{ name: 1, value: 1 }, { name: 2, value: 2 }, { name: 3, value: 3 }, {name: 4, value: 4}],
    sessionPointNotEnoughList: [],
    sessionPreparation: 'ready',
    sessionPreparationData: [{ label: '準備中', value: 'ready'}, { label: '備課完成', value: 'new'}],
    studentIds: [],
    userPaidProducts: [],
  });

  const changePriority = value => {
    setState({
      priority:value,
      hostNickname:'',
      hostId:''
    });
  };

  /* change 課程類型選項 */
  const changeProductOptions = (value) => {
    const productId = value;
    const productName = productOptions.find(option => option.value === productId).name;
    setState({ productId, productName });
  };

  /* change 取消課程彈窗 取消原因 */
  const changeCancelReasonHandler = (reason) => {
    setState({ cancelReason: reason });
  };
  /* click 取消課程彈窗 確認按鈕 */
  const clickOkOfCancelSessionModalHandler = async () => {
    cancelReason &&
      setState({
        isCanceledSession: true,
        isOpenCancelSessionModal: false,
      });
  };
  /* click 取消課程彈窗 取消按鈕 */
  const clickCancelOfCancelSessionModalHandler = () => {
    setState({
      isOpenCancelSessionModal: false,
    });
  };
  /* click 確認取消課程彈窗 確認按鈕 */
  const clickOkOfCancelSessionDoubleCheckHandler = async () => {
    if(isCanceledSession){
      setState({ isLoading: true });
      const payload = { cancelReason };
      const { status } = await cancelOrganizationGroupSessionApi(organizationId, classId, sessionId, payload);
      setState({ 
        isLoading: false,
        isOpenCancelSessionDoubleCheckModal: false,
      });

      status === 'success'
        ? setAlert(ALERT_MESSAGE.CANCEL_SUCCESS, 'success')
        : setAlert(ALERT_MESSAGE.CANCEL_FAIL, 'error');

      history.goBack();
    }
  };
  /* click 取消課程 double check 彈窗 取消按鈕 */
  const clickCancelOfCancelSessionDoubleCheckHandler = () => {
    setState({ isOpenCancelSessionDoubleCheckModal: false });
  };

  /* click 刪除課程彈窗 確認按鈕（舊制用） */
  const clickOkOfSessionRemoveHandler = async () => {
    setState({ isLoading: true });
    const isSuccess = await deleteOrganizationGroupSession(classId, sessionId, timeSpanId);
    if(isSuccess){
      setState({ isOpenSessionRemoveModal: false, });
      history.goBack();
    }
    setState({ isLoading: false, });
  };

  let selectTypeOptions = organization?.availableSessionTypeSetting.map(sessionType => ({
    name: SessionTypeMap[sessionType],
    value: sessionType
  }));

  const onChange = (key,value) => {
    setState({
      [key]: value
    });
  };

  const formatTeacherListData = (teacherList = []) => {
    return teacherList.map(item => ({
      id: item.id,
      imagePath: item.thumbnailUrl || '',
      title: item.nickname,
      content: item.mobileNumber || item.email,
    }));
  };

  const isTeacherChooseDisable = useMemo(()=>{
    if(!educationName) return true;
    if(priority === PRIORITY.TIME){
      if(!startAt || !duration || !subjectCode) return true;
      return false;
    }else{
      if(!subjectCode) return true;
      return false;
    }
  },[startAt,duration,priority,subjectCode,educationName]);

  const fetchOneTeacher = async (params) => {
    const { isSuccess,data } = await getOneTeacher(organizationId,params);
    if(isSuccess){
      setState({
        teachers:data
      });
      if(hostId && firstEnter){
        const isExist = data.some(teacher=> teacher.id === hostId);
        if(!isExist){
          setState({
            hostId:'',
            hostNickname:''
          });
        }
      }
    }else{
      setState({
        teachers:[],
        hostId:'',
        hostNickname:''
      });
    }
    setState({
      firstEnter:true
    });
  };

  const fetchTeacherTags = async () => {
    const { isSuccess,data } = await getSessionTags();
    if(isSuccess){
      const nextData = data.map(item=>{
        return {
          name:item.name,
          value:item.id
        };
      });
      setState({
        teacherTags:nextData
      });
      if(!teacherTag) {
        setState({
          teacherTag:nextData[0].value
        });
      }
    }
  };

  const fetchSessionInfo = async () => {
    setState({ isLoading: true });
    const { isSuccess,data } = await getSessionInfo(organizationId,classId,sessionId);
    const { isSuccess : resourcesIsSuccess,data : resourcesData } = await getSessionResources(organizationId,sessionId);
    const {
      name,
      hostId,
      hostName,
      startAt,
      subjects,
      endAt,
      type,
      preExamId,
      postExamId,
      teacherTag,
      timeSpanId,
      usedPoint,
      productId = data[0].product.productId,
      productName = data[0].product.productName,
      status,
      cancelReason,
    } = data[0];
    if(resourcesIsSuccess){
      const nextResourcesData = resourcesData.map(resource=>{
        const { resourceId,displayName } = resource;
        return {
          resourceId,
          resourceName:displayName
        };
      });
      setState({
        resources:nextResourcesData
      });
    }
    if(isSuccess){
      // 編輯課程頁面且已取消課程
      if(editStatus === EDIT_STATUS.EDIT && status === 'cancel') {
        setState({
          isCanceledSession: true,
          cancelReason
        });
      }
      if(editStatus === EDIT_STATUS.COPY){
        setState({
          name,
          sessionType:type,
          subjectCode:subjects[0].code,
          // startAt,
          duration:(endAt - startAt) / 1000 / 60,
          preExamId,
          postExamId,
          teacherTag,
          priority: PRIORITY.TIME,
          timeSpanId,
          sessionPoint: usedPoint,
          productId,
          productName,
          sessionPreparation: status === 'cancel' ? 'ready' : status,
          cancelReason: null,
        });
      }else{
        setState({
          name,
          sessionType:type,
          subjectCode:subjects[0].code,
          startAt,
          duration:(endAt - startAt) / 1000 / 60,
          hostId,
          hostNickname:hostName,
          preExamId,
          postExamId,
          teacherTag,
          priority: PRIORITY.TIME,
          timeSpanId,
          sessionPoint: usedPoint,
          productId,
          sessionPreparation: status,
          isPointSystem: (productId && productName),
        });
      }
    }
    setState({ isLoading: false });
  };

  const fetchGroupById = async () => {
    const { isSuccess,data } = await getGroupInfoById(organizationId,classId);
    if(isSuccess) {
      setState({
        educationName:data.educationName
      });
    }else{
      setState({
        educationName:''
      });
    }
  };

  /* 打API，取得課程類型（學生可用課程）*/
  const fetchUserPaidProducts = async () => {
    const userIds = customers.data.map(student => student.id);
    const payload = { userIds };
    const { isSuccess, data } = await userPaidProductsApi(payload);

    if(isSuccess) setState({ userPaidProducts: data.data });
  };
  
  const onCheckResources = async value => {
    /* 限制教材數量 */
    // const isOver = (resources.length + value.length) > 6;
    // if(isOver){
    //   setState({
    //     errorMsg:'教材數量已超過最大限制6筆。'
    //   });
    //   return;
    // }
    const resourceIds = resources.map(resource=> resource.resourceId);
    const filterRepeat = resourceIds.concat(value);
    const isRepeat = filterRepeat.filter(function(element, index, arr){
      return arr.indexOf(element) !== index;
    }).length > 0;
    if(isRepeat) {
      setState({
        errorMsg:'教材ID重複，請重新加入。'
      });
      return;
    }
    setState({
      isResourceLoading:true,
    });
    let error = '';
    const res = await Promise.all(value.map(async item=>{
      const { isSuccess,data } = await checkResourceById(organizationId,item);
      if(isSuccess){
        const { resourceId,displayName } = data;
        return {
          resourceId,
          resourceName:displayName
        };
      }else{
        error = '資料錯誤，請重新加入';
        return null;
      }
    }));
    if(error) {
      setState({
        errorMsg:error
      });
    }else{
      const newResources = resources.concat(res);
      setState({
        errorMsg:'',
        resources: newResources
      });
    }
    setState({
      isResourceLoading:false,
    });
  };

  const onSubmitHandler = async () => {
    const isEdit = editStatus === EDIT_STATUS.EDIT;

    // 檢查是否取消課程
    if(isCanceledSession) {
      setState({ isOpenCancelSessionDoubleCheckModal: true });
      return;
    }

    if(resources.length === 0) {
      setAlert('請重新選擇教材');
      return;
    }
    if (!hostId) {
      return;
    }

    // 未選擇課程類型
    if(!productId && !productName && isPointSystem){
      setAlert('請選擇課程類型');
      return;
    }

    // 未選擇使用堂數
    if(!sessionPoint && isPointSystem){
      setAlert('請選擇使用堂數');
      return;
    }

    // 未填寫課程名稱
    if(!name){
      setAlert('請填寫課程名稱');
      return;
    }

    // 檢查剩餘堂數不足 且 非編輯課程
    if(isAvailablePointError && !isEdit){
      const list = Object.entries(availablePointNotEnoughList).map(([studentId, value]) => {
        const studentName = customers.data.find(item => item.id === studentId).nickname;
        return studentName;
      });
      setState({
        isOpenSessionPointNotEnoughModal: true,
        sessionPointNotEnoughList: list,
      });
      return;
    }

    const resourceIdList = resources.map(resource => resource.resourceId);
    const payload = {
      name,
      sessionType,
      subjectCode,
      startAt,
      endAt:getTime(addMinutes(startAt, duration)),
      hostId,
      resourceIdList,
      preExamId,
      postExamId,
      timeSpanId,
      teacherTag,
      isForced: priority === PRIORITY.TIME ? false : true,
      status: sessionPreparation,
    };

    if(!isEdit) {
      payload.productId = productId;
      payload.productName = productName;
      payload.point = sessionPoint;
      payload.userIds = studentIds;
    }

    setState({ isLoadingSubmit: true });
    isEdit ? await updateOrganizationSession({
      data:payload
    }) : await createOrganizationSession({
      data:payload
    });

    setState({ isLoadingSubmit: false });
  };

  const onDeleteTag = (resId) => {
    const nextResources = resources.filter(resource=> resource.resourceId !== resId);
    setState({
      resources:nextResources,
      errorMsg:''
    });
  };

  useEffect(()=>{
    fetchUserPaidProducts();  // 打API，取得課程類型
    fetchGroupById();
  },[]);

  //取得oneTeacher
  useEffect(()=>{
    if(isTeacherChooseDisable) return;
    const params = {
      educationalSystem: educationName,
      subjectCode,
      [teacherSearchType]: teacherSearchValue,
    };
    if(priority === PRIORITY.TIME){
      params.startAt = startAt;
      params.endAt = getTime(addMinutes(startAt, duration));
    }
    if(editStatus === EDIT_STATUS.EDIT){
      params.includingSessionId = sessionId;
    }
    fetchOneTeacher(params);
  },[startAt,duration,priority,subjectCode,educationName,teacherSearchType, teacherSearchValue]);

  const goBack = () => {
    history.push(`/organization/${organizationId}/class/${classId}/session`);
  };

  const fetchResource = async () => {
    if( educationName !== OTHER) getOrganizationSubjects(organizationId,classId);
  };

  const onSearchTeacherChange = (value, key) => {
    if (key === 'searchValue') {
      if (teacherSearchType === '') return;
      setState({teacherSearchValue: value});
    }else if(key === 'searchType'){
      setState({teacherSearchType : value});
    }
  };

  /* 課程類型選項：所有學生 productId, productName 交集 */
  const productOptions = useMemo(() => {
    // 所有學生課程類型（商品）名稱的集合陣列，ex. [[{ productId: aaa, productName: 國小課堂}, { productId: bbb, productName: 國小輔導}], [{},{}]]
    const data = userPaidProducts.map(student => 
      student.paidProducts.map(product => {
        return {
          productId: product.productId,
          productName: product.productName
        };
      })
    );
    if(data.length === 0) return;

    // 取集合陣列之交集
    const intersection = data.reduce((prev, curr) =>
      prev.filter(prevItem => 
        curr.map(currItem => currItem.productId).includes(prevItem.productId)
      )
    );

    intersection.forEach(obj => {
      obj.name = obj.productName;
      obj.value = obj.productId;
      delete obj.productName;
      delete obj.productId;
    });

    return intersection;
  }, [userPaidProducts]);

  /* 可用堂數不足名單 */
  const availablePointNotEnoughList = useMemo(() => {
    if(!productId || !sessionPoint) return {};
    // obj，{每個學生對應的商品}
    const studentEntries = userPaidProducts.reduce((acc, item) => {
      return {
        ...acc,
        [item.userId]: (item.paidProducts || []).filter(item =>
          item.productId === productId && item.availablePoint < sessionPoint
        )
      };
    }, {});
    return studentEntries;
  }, [productId, sessionPoint]);

  /* 可用堂數是否錯誤 */
  // availablePointNotEnoughList 轉 array
  const pointNotEnoughList = Object.values(availablePointNotEnoughList);
  // 「使用堂數」欄位是否錯誤。必須為 堂數系統 且 非編輯課程 且 可用堂數不足名單 > 0
  const isAvailablePointError = sessionPoint && !isEdit && pointNotEnoughList.some(item => item.length > 0);

  useEffect(()=>{
    fetchTeacherTags();
  },[]);

  useEffect(()=>{
    fetchResource();
  },[educationName]);

  useEffect(()=>{
    if(!sessionId || editStatus === EDIT_STATUS.CREATE) return;
    fetchSessionInfo();
  },[]);

  useEffect(() => {
    setState({ studentIds: customers.data.map(student => student.id)});
  }, [customers]);

  return (
    <>
      {/* 剩餘堂數不足彈窗 */}
      <BsModal
        cancelDisplay={false}
        isFull={true}
        onCancel={()=>setState({ isOpenSessionPointNotEnoughModal: false })}
        onOk={()=>setState({ isOpenSessionPointNotEnoughModal: false })}
        open={isOpenSessionPointNotEnoughModal}
        title='剩餘堂數不足'
      >
        {sessionPointNotEnoughList.join(', ')} 剩餘堂數不足，無法開課
      </BsModal>

      {/* 取消課程彈窗 */}
      <CancelSessionModal
        cancelReason={cancelReason}
        isLoading={isLoading}
        isOpen={isOpenCancelSessionModal}
        isOpenDoubleCheck={isOpenCancelSessionDoubleCheckModal}
        onCancel={clickCancelOfCancelSessionModalHandler}
        onCancelDoubleCheck={clickCancelOfCancelSessionDoubleCheckHandler}
        onChange={changeCancelReasonHandler}
        onOk={clickOkOfCancelSessionModalHandler}
        onOkDoubleCheck={clickOkOfCancelSessionDoubleCheckHandler}
      />

      {/* 刪除課程確認彈窗 */}
      <BsModal
        isFull={true}
        onCancel={()=>setState({ isOpenSessionRemoveModal: false})}
        onOk={clickOkOfSessionRemoveHandler}
        open={isOpenSessionRemoveModal}
        title='刪除課程'
      >
        注意！刪除的課程無法恢復，是否仍要刪除？
      </BsModal>

      {
        isLoading
          ? <Loading />
          : <UiSessionForm>
              <Box mb={2.5} display='flex' justifyContent='space-between' alignItems='center'>
                <UiTitleContainer>{editStatus === EDIT_STATUS.EDIT ? '編輯課程':'新增課程'}</UiTitleContainer>
                {
                  editStatus === EDIT_STATUS.EDIT &&
                    (isPointSystem
                      ? <UiButtonContainer>
                          <Button
                            buttonColor='highlight'
                            disabled={sessionPreparation === 'cancel'}
                            onClick={() => setState({ isOpenCancelSessionModal: true })}
                          >
                            取消課程
                          </Button>
                        </UiButtonContainer>
                      : <UiButtonContainer>
                          <Button
                            buttonColor='highlight'
                            onClick={() => setState({ isOpenSessionRemoveModal: true })}
                          >
                            刪除課程
                          </Button>
                        </UiButtonContainer>
                )}
              </Box>
              {
                isCanceledSession &&
                  <Box mb={4} px={2} height={76} display='flex' alignItems='center' bgcolor='#FFF4E5' borderRadius={4}>
                    <Icon
                      haveBg={false}
                      icolor='#FB8C00'
                      name='reportProblemOutlined'
                      size='1.6rem'
                    />
                    <UiWarningMessageContainer>
                      <UiWarningMessage fontSize='1.07rem'>課程已取消</UiWarningMessage>
                      <UiWarningMessage fontSize='0.93rem'>取消原因：{cancelReason}</UiWarningMessage>
                    </UiWarningMessageContainer>
                  </Box>
              }
              <UiFormWrapper disabled={isCanceledSession}>
                {
                  isPointSystem &&
                    <>
                      <Box py={1}>
                        <Select
                          label='課程類型*'
                          options={productOptions}
                          submitHandler={(value)=>{changeProductOptions(value);}}
                          value={productId}
                          width='100%'
                          disabled={editStatus === EDIT_STATUS.EDIT}
                        />
                        <UiTipWrapper>*必填</UiTipWrapper>   
                      </Box>
                      <Box py={1}>
                        <Select
                          error={isAvailablePointError}
                          label='使用堂數'
                          options={sessionPointOptions}
                          submitHandler={(value) => onChange('sessionPoint', value)}
                          value={sessionPoint}
                          width='100%'
                          disabled={editStatus === EDIT_STATUS.EDIT}
                        />
                        { // 非編輯課程才需要顯示
                          !isEdit &&
                            <UiErrorMessageWrapper>
                              {
                                Object.entries(availablePointNotEnoughList).map(([studentId, value]) => {
                                  if(value.length === 0) return null;
                                  const studentName = customers.data.find(item => item.id === studentId).nickname;
                                  return (
                                    <UiErrorMessageContainer key={studentId}>
                                      <Icon
                                        haveBg={false}
                                        icolor='#F44336'
                                        name='error'
                                        size='1.5rem'
                                      />
                                      <UiErrorMessage>
                                        {studentName} 可用堂數不足（可用堂數：{value[0].availablePoint}）
                                      </UiErrorMessage>
                                    </UiErrorMessageContainer>
                                  );
                                })
                              }
                            </UiErrorMessageWrapper>
                        }
                      </Box>
                      <Box py={3}>
                        <Divider style={{border: '1px solid #d5d7de'}} />
                      </Box>
                      <Box py={1}>
                        <UiTitleContainer fontSize='1.34rem'>課程安排</UiTitleContainer>
                      </Box>
                    </>

                }
                <Box py={1}>
                  <Select
                    label="科目*"
                    options={orgSubjects.data}
                    value={subjectCode}
                    submitHandler={(value) => onChange('subjectCode',value)}
                    width="100%"
                  />
                  <UiTipWrapper>*必填</UiTipWrapper>   
                </Box>
                <Box py={1}>
                  <Select
                    label="上課方式"
                    options={selectTypeOptions}
                    value={sessionType}
                    submitHandler={(value) => onChange('sessionType',value)}
                    width="100%"
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    onChange={event => onChange('name',event.target.value)}
                    value={name}
                    variant="outlined"
                    label="課程名稱"
                    fullWidth
                  />
                </Box>
                <Box py={3}>
                  <Divider style={{border: '1px solid #d5d7de'}}/>
                </Box>
                <Box py={1} display="flex" alignItems="center">
                  <UiLabel>請選擇排課方式</UiLabel>
                  <UiTabs>
                    <UiTab
                      className={priority === PRIORITY.TIME && 'active'}
                      disabled={!!cancelReason}
                      onClick={()=>changePriority(PRIORITY.TIME)}
                    >
                        時間優先
                    </UiTab>
                    <UiTab
                      className={priority === PRIORITY.TEACHER && 'active'}
                      disabled={!!cancelReason}
                      onClick={()=>changePriority(PRIORITY.TEACHER)}
                    >
                      教師優先
                    </UiTab>
                  </UiTabs>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box py={1}>
                    <DateTimePicker
                      style={{width: '100%'}}
                      onChange={value=>{
                        onChange('startAt',value);
                      }}
                      label='上課時間'
                      value={startAt}
                      needHide={true}
                    />
                  </Box>
                  <Box py={1}>
                    <Select
                      label="課程時長"
                      options={SelectOptions}
                      value={duration}
                      submitHandler={(value) => onChange('duration',value)}
                      width="48%"
                    />
                  </Box>
                  <Box py={1} display="flex" alignItems="center" order={priority === PRIORITY.TEACHER ? '-1' : '1'}>
                    <UiLabel>授課老師</UiLabel>
                    <TransferListModal
                      name='hostId'
                      title="選擇授課教師"
                      data={formatTeacherListData(teachers)}
                      buttonName="選擇授課教師"
                      type='singleChoice'
                      isShowSearch={true}
                      selectOptions={teacherOptions}
                      onSearchChange={onSearchTeacherChange}
                      onChange={({id,title}) => {
                        setState({
                          hostId:id,
                          hostNickname:title
                        });
                      }}
                      disabled={isTeacherChooseDisable}
                    />
                    {hostNickname}
                </Box>
                </Box>
                <Box py={3}>
                  <Divider style={{border: '1px solid #d5d7de'}}/>
                </Box>
                <Box py={3}>
                  <ResourceInput
                    onSubmit={onCheckResources}
                    value={resources}
                    isLoading={isResourceLoading}
                    errorMsg={errorMsg}
                    onDeleteTag={onDeleteTag}
                  />
                </Box>
                <Box py={1}>
                  <Select
                    label="課程標籤"
                    options={teacherTags}
                    value={teacherTag}
                    submitHandler={(value) => onChange('teacherTag',value)}
                    width="100%"
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    variant="outlined"
                    label="課前測驗ID"
                    value={preExamId}
                    onChange={event => onChange('preExamId',event.target.value)}
                    fullWidth
                  />
                </Box>
                <Box py={1}>
                  <TextField
                    variant="outlined"
                    label="課後測驗ID"
                    value={postExamId}
                    onChange={event => onChange('postExamId',event.target.value)}
                    fullWidth
                  />
                </Box>
                {
                  isPointSystem &&
                    <Box my={4} px={2.5} py={2} display='flex' justifyContent='space-between'
                      bgcolor={sessionPreparation === 'ready' ? '#FFF4E5' : '#DFF1E0'}
                    >
                      <UiIconLabelContainer>
                        <Icon
                          haveBg={false}
                          icolor={sessionPreparation === 'ready' ? '#FB8C00' : successColor[0]}
                          name={sessionPreparation === 'ready' ? 'error' : 'checkCircle'}
                          size='1.5rem'
                          title='備課狀態'
                        />
                        <span>備課狀態</span>
                      </UiIconLabelContainer>
                      <UiRadioGroupContainer>
                        <RadioGroup
                          data={sessionPreparationData}
                          onChange={(e) => setState({ sessionPreparation: e.target.value })}
                          value={sessionPreparation}
                        />
                      </UiRadioGroupContainer>
                    </Box>
                }
              </UiFormWrapper>
              <Box py={1} display="flex" justifyContent="flex-end">
                <Box mr={1}>
                  <Button loading={isLoadingSubmit} onClick={() => goBack()}>返回</Button>
                </Box>
                {
                  sessionPreparation !== 'cancel' &&
                    <Button loading={isLoadingSubmit} onClick={()=> onSubmitHandler()}>送出</Button>
                }
              </Box>
            </UiSessionForm>
      }
    </>
  );
};

SessionForm.propTypes = {
  editStatus: PropTypes.string,
};