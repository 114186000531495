import styled from 'styled-components';

export const UiSessionForm = styled.div`
	display: block;
	width: 100%;
	max-width: 550px;
`;

export const UiFormWrapper = styled.div`
	display: block;
	width: 100%;

	${({ disabled }) =>
		disabled &&
		{
			opacity: 0.6,
			position: 'relative',
			zIndex: -999
		}
	}
`;

export const UiTitleContainer = styled.div`
	font-family: 'Noto Sans CJK TC';
	font-size: ${({ fontSize }) => fontSize || '1.6rem' };
	color: #242c3f;
	line-height: 36px;
`;

export const UiButtonContainer = styled.div`
`;

export const UiWarningMessageContainer = styled.div`
	margin-left: 12px;
`;

export const UiWarningMessage = styled.div`
	color: #663C00;
	font-size: ${({fontSize}) => fontSize};
	line-height: 26px;
`;

export const UiLabel = styled.div`
	margin-right: 32px;
	color: #3a4052;
`;

export const UiTabs = styled.div`
	display: flex;
	align-items: center;
`;

export const UiTab = styled.div`
	margin-right: 16px;
	padding: 8px 24px;
	font-size: 14px;
	color: #3a4052;
	border: 1px solid #e4e7ec;
	line-height: 24px;
	border-radius: 20px;
	cursor: pointer;
	transition: 0.35s;

	&.active {
		color: #fff;
		background: #121232;
	}

	&:hover {
		color: #fff;
		background: #121232;
	}

	${({ theme: { media }}) => media.phone`
		font-size: 1rem;
	`}
`;

export const UiTipWrapper = styled.div`
	color: rgba(0, 0, 0, 0.6);
	font-family: 'Roboto';
	font-size: 0.8rem;
	padding-left: 14px;
`;

export const UiErrorMessageWrapper = styled.div`
	margin-top: 13px;
`;

export const UiErrorMessageContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const UiErrorMessage = styled.div`
	color: ${({ theme }) => theme.palette.main.danger};
	font-size: 0.93rem;
	margin-left: 13px;

	@keyframes headShake {
		0% {
			transform: translateX(0);
		}
  	6.5% {
    	transform: translateX(-6px) rotateY(-9deg);
  	}
  	18.5% {
    	transform: translateX(5px) rotateY(7deg);
  	}
  	31.5% {
    	transform: translateX(-3px) rotateY(5deg);
  	}
  	43.5% {
    	transform: translateX(2px) rotateY(3deg);
  	}
  	50% {
			transform: translateX(0);
		}
	};

	&.errorMessage-enter {
		animation: 500ms headShake;
	};
`;

export const UiIconLabelContainer = styled.div`
	display: flex;
	align-items: center;

	& > svg {
		margin-right: 0.7rem;
	};
`;

export const UiRadioGroupContainer = styled.div`
`;