import styled from 'styled-components';
import { SESSION_START_TIME_TYPE } from 'constants/index';

export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;

	& > button {
		margin: 0 0.5rem;

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const UiIconBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	& > * + * {
		margin-left: 3px;
	}

	> .recordingIcon {
		background-color: #f00;
	}
`;

export const UiStartTimeIcon = styled.div`
display: flex;
align-items: center;
justify-content: center;

& > span {
	display: inline-block;
	padding-left: 0.5rem;
}

& > .icon {
	color:
		${(
			{ theme,
			type }
		) => {
		switch (type) {
		case SESSION_START_TIME_TYPE.YET:
		return theme.palette.main.info;
		case SESSION_START_TIME_TYPE.ALREADY:
		return theme.palette.main.disable;
		default:
		return theme.palette.main.warning;
		}
		}};
}
`;

export const UiVerticalLine = styled.hr`
	width: 2px;
	height: 24px;
	border: none;
	border-radius: 2px;
	margin: 0 8px;
	background: #D5D7DE;
`;