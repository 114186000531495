import React, { useEffect, useCallback,useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useUser } from 'store/user';
import { useTeacher } from 'store/teacher';
import { useAlert } from 'utils/hooks/useAlert';
import { emailReg, phoneNumberValid } from 'utils/validation';
import { EDIT_STATUS,ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { TextField } from '@material-ui/core';
import { Form, AvatarUpload, PhoneForm, Select, CheckboxGroup, Button, Modal } from 'components';
import { 
  UiTeacherManagementForm,
  FontBox,
  UiCoustomerButtonBox,
  UiContent,
  UiFormText
} from './TeacherManagementForm.style';


/**
 * 教師管理 表單
 */

export const TeacherManagementForm = ({ editStatus }) => {
  const [{
    isLoading,
    imgUrl,
    selectOption,
    blackList,
    permissionMap,
    editData,
    modalState,
    permissionGroupsData,
  }, setState] = useSetState({
    isLoading: editStatus !== EDIT_STATUS.CREATE,
    imgUrl: '',
    selectOption: 'staffId',
    blackList: [],
    permissionMap: [],
    editData: null,
    modalState: false,
    permissionGroupsData: [],
  });
  const [{ myOrganization }, { changeUserRole }] = useUser();
  const { setAlert } = useAlert();
  const { upload } = useFirebaseStorage();
  const history = useHistory();
  const { organizationId, teacherId } = useParams();
  const [{ teachers }, { createTeachers, updateTeachers }] = useTeacher();
  const { dataMaps } = teachers;
  const schema = {
    staffId: {
      component: TextField,
      elementProps: {
        label: '教師 id',
        value: '',
        helperText: ''
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        maxLength: {
          value: 99,
          message: '最大長度為 99 個字元'
        }
      }
    },
    mobileNumber: {
      component: PhoneForm,
      elementProps: {
        label: '手機',
        defaultPhoneNum: '',
        defaultCountryNum: '',
        helperText: '',
        onChange: (n) => {
          if (n[1] === '') {
            return '';
          } else {
            return (n[0] + n[1]);
          }
        }
      },
      rules: {
        validate: value => {
          if (!value) return;
          return phoneNumberValid(value) || '電話格式錯誤';
        }
      }
    },
    email: {
      component: TextField,
      elementProps: {
        label: '教師 email',
        value: '',
        helperText: '',
        defaultValue: ''
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        pattern: {
          value: emailReg,
          message: 'mail 格式錯誤',
        }
      }
    },
    nickname: {
      component: TextField,
      elementProps: {
        label: '機構暱稱',
        value: '',
        helperText: ''
      },
    },
    // originalName: {
    //   component: TextField,
    //   elementProps: {
    //     label: '姓名',
    //     value: '',
    //     helperText: ''
    //   },
    // },
    // originalNickname: {
    //   component: TextField,
    //   elementProps: {
    //     label: '暱稱',
    //     value: '',
    //     helperText: ''
    //   },
    //   rules: {
    //     required: {
    //       value: true,
    //       message: '本欄位為必填'
    //     },
    //     maxLength: {
    //       value: 99,
    //       message: '最大長度為 99 個字元'
    //     }
    //   }
    // },
    brief: {
      component: TextField,
      elementProps: {
        label: '簡介',
        multiline: true,
        rows: 5,
        helperText: ''
      }
    },
    permissionGroups: {
      elementProps: {
        label: '權限',
        helperText: ''
      }
    }
  };


  const isOwner = useMemo(()=>{
    if(!myOrganization.isLoaded) return true;
    if(myOrganization.organization.ownerId === teacherId) return false;
    return true;
  },[myOrganization.isLoaded]);

  useEffect(() => {
    const permission = myOrganization.permissionGroupsMap;
    let permissionMap = Object.entries(permission).map(([, value]) => {
      return {
        value: value.id,
        label: value.title,
        name: value.name
      };
    });

    if (teacherId) {
      if (dataMaps) {
        // 取 store 資料
        let teachersInfo = dataMaps[teacherId];
        teachersInfo['thumbnailUrl'] &&
          setState({
            imgUrl: teachersInfo['thumbnailUrl']
          });

        setState({ editData: teachersInfo });
      }
    }

    permissionMap = permissionMap.filter(item => {
      return item.name !== 'owner';
    });

    setState({ permissionMap });
  }, []);

  useEffect(() => {
    if (editStatus === EDIT_STATUS.EDIT) return;
    let nextBlackList = [];
    switch (selectOption) {
      case 'staffId':
        nextBlackList = ['mobileNumber', 'email'];
        break;
      case 'mobileNumber':
        nextBlackList = ['staffId', 'email'];
        break;
      case 'email':
        nextBlackList = ['staffId', 'mobileNumber'];
        break;
      default:
        break;
    }

    setState({ blackList: nextBlackList });
  }, [selectOption]);


  useEffect(() => {
    if (teacherId) {
      if (dataMaps) {
        // 取 store 資料
        let teachersInfo = dataMaps[teacherId];
        setState({
          editData: teachersInfo,
          permissionGroupsData: teachersInfo.permissionGroups,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!editData) return;
    setState({ isLoading: false });
  }, [editData]);

  const nextData = useCallback(() => {
    // 不可被編輯的欄位
    const blackList = ['staffId','originalName','originalNickname'];
    let isRead = false;

    // 根據網址狀態判斷
    switch (editStatus) {
      // 編輯
      case EDIT_STATUS.EDIT:
        if (!editData) return;
        break;
      // 檢視
      case EDIT_STATUS.READ:
        isRead = true;
        if (!editData) return;
        break;
      // 建立 & 預設
      case EDIT_STATUS.CREATE:
      default:
        return schema;
    }

    // schema 比對
    let newData = Object.assign({}, schema);
    Object.entries(newData).forEach(([key]) => {

      if (!editData['email']) {
        delete newData['email'].rules;
      }

      if (!editData['mobileNumber']) {
        delete newData['mobileNumber'].rules;
      }

      // set data
      if (key === 'staffId') {
        newData[key].elementProps.defaultValue = editData['id'];
      }else if(key === 'mobileNumber'){
        let mobileNumberValue = editData['mobileNumber'];
        const reg= /^[09]{2}[0-9]{8}$/;
        if (reg.test(mobileNumberValue)){
          mobileNumberValue = '+886' + mobileNumberValue.substring(1);
        }
        newData[key].elementProps.defaultValue = mobileNumberValue;
      } else {
        newData[key].elementProps.defaultValue = editData[key];
      }

      // 黑名單的判斷
      if (editStatus === EDIT_STATUS.READ) {
        newData[key].elementProps.disabled = isRead;
      } else {
        newData[key].elementProps.disabled = blackList.includes(key) ? !isRead : isRead;
      }
    });

    return newData;
  }, [editData]);

  // 下拉選單系列
  const selectProps = {
    label: '註冊方式',
    options: [
      {
        name: '教師 id',
        value: 'staffId'
      },
      {
        name: '教師手機號碼',
        value: 'mobileNumber'
      },
      {
        name: '教師 email',
        value: 'email'
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value });
    }
  };



  // 上傳檔案
  const onUploaded = async file => {//編輯才有上傳圖片
    const enCodeTecherId = encodeURIComponent(teacherId).replace('.', '');
    const fileType = file.type.split('/')[1];
    const uploadPath = `${organizationId}/avatars/${enCodeTecherId}.${fileType}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      setState({ imgUrl: url });
      setAlert('上傳成功!', 'success');
    } else {
      setAlert('上傳失敗!', 'wrarning');
    }
  };


  // 表單上傳
  const submitFormHandler = async data => {
    setState({ isLoading: true });
    if (editStatus === EDIT_STATUS.EDIT) {
      let isValidNumber = false;
      let isValidEmail = false;

      if (data.mobileNumber) {
        isValidNumber = phoneNumberValid('+' + data.mobileNumber);
        if (!isValidNumber) {
          setAlert('電話格式錯誤', 'error');
          setState({ isLoading: false });
          return;
        }
      }else{
        delete data.mobileNumber;
      }

      if (data.email) {
        isValidEmail = emailReg.test(data.email);
        if (!isValidEmail) {
          setAlert('mail格式錯誤', 'error');
          setState({ isLoading: false });
          return;
        }
      } else {
        delete data.email;
      }

      const nextData = data && Object.assign(data, { thumbnailUrl: imgUrl });
      const responseData = await updateTeachers(nextData);
      setState({ isLoading: false });
      if (!responseData) return;
      history.push(`/organization/${organizationId}/teachers`);
    } else {
      // TODO permissionGroups API Props
      const nextData = data && Object.assign(data, { thumbnailUrl: imgUrl });
      const responseData = await createTeachers(nextData);
      setState({ isLoading: false });
      if (!responseData) return;
      history.push(`/organization/${organizationId}/teachers`);
    }
  };

  // 表單按鈕
  const buttons = [
    {
      text: '確認',
      type: 'submit'
    },
    {
      text: '回到上一頁',
      color: 'info',
      icon: 'exitToApp',
      func: () => { history.push(`/organization/${organizationId}/teachers`); }
    }
  ];


  //Modal buttons
  const modalButtons = [
    {
      text: '確認',
      func: async () => {
        await changeUserRole().then(isSuccess => {
          isSuccess && history.push(`/organization/${organizationId}/customer`);
        });
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const openModal = () => {
    setState({
      modalState: true
    });
  };

  const getModalStateHandler = state => {
    setState({
      modalState: state
    });
  };

  return (
    <UiTeacherManagementForm>
      <UiCoustomerButtonBox>
        <Button onClick={openModal}>轉換為學生</Button>
      </UiCoustomerButtonBox>
      <UiContent>
      {(editStatus !== EDIT_STATUS.CREATE) &&
        <AvatarUpload
          imgUrl={imgUrl}
          onUploaded={onUploaded}
          disabled={myOrganization.organization.staffProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT}
        />
      }
      <FontBox>
        {(editStatus === EDIT_STATUS.CREATE) &&
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
        }
        {
          (editStatus !== EDIT_STATUS.CREATE && editData) && <>
            <UiFormText>姓名: {editData?.originalName}</UiFormText>
            <UiFormText>暱稱: {editData?.originalNickname}</UiFormText>
          </>
        }
        <Form
          schema={!isLoading ? nextData() : {}}
          isLoading={isLoading}
          onSubmit={submitFormHandler}
          buttons={buttons}
          blackList={blackList}
        >
          {
            isOwner &&
            <CheckboxGroup
              name='permissionGroups'
              data={permissionMap}
              defaultValue={permissionGroupsData || []}
            />
          }
        </Form>
      </FontBox>
      </UiContent>
      <Modal
        isOpen={modalState}
        text="確定要修改此使用者權限為學生"
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
    </UiTeacherManagementForm>
  );
};

