/* eslint-disable max-len */
import React, { useEffect, } from 'react';
import PropTypes from 'prop-types';

import { EDIT_STATUS, READY_STATE, SESSION_TYPE, minute } from 'constants/index';
import { stringify } from 'query-string';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Table,
  Button,
  IconInput,
  Select,
  TimePicker,
  DateTimePicker,
  Icon,
  Breadcrumbs,
  Loading,
} from 'components'; //IconInput, Select,
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { getSessionOptions as getSessionOptionsApi } from 'services/api/organization/session';
import { useSessions } from 'store/sessions';
import { useClass } from 'store/class';
import { useAlert } from 'utils/hooks/useAlert';
import { useUser } from 'store/user';
import { useDateInterval } from 'utils/hooks/useDateInterval';
import { SESSION_START_TIME_TYPE, } from 'constants/index';
// import { getTime } from 'date-fns';
import { UiTableInfo } from 'styles';
import { MoreActionComponents } from './MoreActionComponents';
import { UiButtonBox, UiStartTimeIcon,UiIconBox, UiVerticalLine, } from './SessionManagementTable.style';

/**
 * 課程列表
 */

const PROCESSING = 'PROCESSING';
const NOW = 'NOW';
const YESTERDAY = 'YESTERDAY';
const PREV_TO_NEXT_MONTH = 'PREV_TO_NEXT_MONTH';
const ALL = 'ALL';

const SESSION_START_TIME_TYPE_INFO = {
  [SESSION_START_TIME_TYPE.YET]: '尚未開始',
  [SESSION_START_TIME_TYPE.ALREADY]: '已結束',
  [SESSION_START_TIME_TYPE.PROCESSING]: '進行中'
};

const selectOptions = [
  {
    name: '當前課程',
    value: PROCESSING
  },
  {
    name: '今日課程',
    value: NOW
  },
  {
    name:'昨日課程',
    value:YESTERDAY
  },
  {
    name: '所有課程',
    value: PREV_TO_NEXT_MONTH,
  },
  // {
  //   name: '所有課程',
  //   value: ALL
  // }
];

export const SessionManagementTable = ({
  noFilter = false,
  defaultDuration = PREV_TO_NEXT_MONTH
}) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const history = useHistory();
  const { setAlert } = useAlert();
  const [, { todayInterval, allInterval, yesterdayInterval, prevMonthInterval, nextMonthInterval }] = useDateInterval();
  const { organizationId, classId } = useParams();
  const [{ myOrganization, profile }] = useUser();
  const [{ sessions },
    {
      getOrganizationSessions,
      // deleteOrganizationGroupSession,  // 刪除課程用狀態
  }] = useSessions();
  const [{ classes }] = useClass();

  const [
    { sessionName,
      groupName,
      hostName,
      hostMobileNumber,
      groupOwnerName,
      startAt,
      endAt,
      nowPage,
      rowsPage,
      goal,
      condition,
      dateInterval,
      schema,
      isLoading,
      educationalSystem,
      subject,
      subjectMap,
      selectOptionsEducation,
      selectOptionsSubject,
      // targetSessionId, // 刪除課程用狀態
      // targetTimeSpanId // 刪除課程用狀態
    }, setState
  ] = useSetState({
    sessionName: '',
    groupName: '',
    hostName: '',
    hostMobileNumber: '',
    groupOwnerName:'',
    startAt: null,
    endAt: null,
    nowPage: 0,
    rowsPage: classId ? 10 : 50,
    goal: '',
    condition: '',
    dateInterval: defaultDuration || NOW,
    schema: {},
    isLoading: true,
    educationalSystem: null,
    subject: null,
    subjectMap: null,
    selectOptionsEducation: null,
    selectOptionsSubject: null,
    // targetSessionId:'',  // 刪除課程用狀態
    // targetTimeSpanId:''  // 刪除課程用狀態
  });

  // 刪除課程用狀態
  // const [isOpen,setIsOpen] = useState(false);
  // const [targetSession,setTargetSession] = useState('');
  // const [targetClassId,setTargetClassId] = useState('');

  const pagesDisplay = classId ? [10, 15, 20] : [20, 50, 100];

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes?.dataInfo?.name || ''}-課程總覽`,
      link: `/organization/${organizationId}/class/${classId}/session`,
      icon: 'importContacts'
    }
  ];

  const defaultSchema = {
    groupName: {
      name: '班級名稱',
      defaultValue: ''
    },
    name: {
      name: '課程名稱',
      defaultValue: ''
    },
    statusText: {
      name: '課程狀態',
      defaultValue: '',
    },
    productName: {
      name: '課程類型',
      defaultValue: ''
    },
    // sessionType: {
    //   name: '上課方式',
    //   defaultValue:  '',
    // },
    subject:{
      name:'科目',
      defaultValue:'--'
    },
    sessionStatus:{
      name:'狀態',
      defaultValue:'--'
    },
    startTime: {
      name: '時間',
      defaultValue: ''
    },
    classTime: {
      name: '課程時數',
      defaultValue: ''
    },
    hostName: {
      name: '授課教師',
      defaultValue: ''
    },
    resourcesText: {
      name: '上課資源',
      defaultValue: ''
    },
    // attendance: {
    //   name: '出勤',
    //   defaultValue: '-'
    // }
  };

  const selectInputOptions = [
    {
      name: '課程名稱',
      value: 'sessionName',
    },
    !classId &&
    {
      name: '班級名稱',
      value: 'groupName',
    },
    {
      name: '授課教師名稱',
      value: 'hostName'
    },
    {
      name:'班級導師名稱',
      value:'groupOwnerName'
    }
  ];

  const resetFormData = (value) => {
    setState({
      sessionName: '',
      groupName: '',
      hostName: '',
      groupOwnerName:'',
      hostMobileNumber: '',
      startAt: '',
      rowsPage: 50,
      nowPage: 0,
      [goal]: value
    });
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'warning');
        return;
      } else {
        resetFormData(value);
      }
    }
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const onchangeHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const getDateDuration = dateType => {
    switch (dateType) {
      case NOW:
        return { ...todayInterval() };
      case PROCESSING:
        return { startAt: Date.now(), endAt: todayInterval().endAt };
      case PREV_TO_NEXT_MONTH:
        return { startAt: prevMonthInterval().startAt, endAt: nextMonthInterval().endAt};
      default:
      case ALL:
        return { ...allInterval() };
      case YESTERDAY:
        return { ...yesterdayInterval() };
    }
  };

  const onSelectChangeHandler = selected => {
    const dateParams = getDateDuration(selected);
    setState({
      nowPage: 0,
      dateInterval: selected,
      ...dateParams
    });
  };

  /* change 學制選項 */
  const educationChangeHandler = (value) => {
    const selectOptionsSubject = subjectMap[value].map(({ name, code }) => ({ name, value: code }));
    setState({ selectOptionsSubject, educationalSystem: value, });
  };

  /* change 科目選項 */
  const subjectChangeHandler = (value) => {
    setState({ subject: value });
  };

  const goCreateSession = () => {
    history.push(`/organization/${organizationId}/class/${classId}/session/${EDIT_STATUS.CREATE}`);
  };

  useEffect(() => {
    if (classId) delete defaultSchema.groupName;
    fetchSessionsOptions();
    const dateParams = getDateDuration(dateInterval);
    setState({
      schema: defaultSchema,
      ...dateParams,
    });
  }, []);

  const fetchSessions = async () => {
    setState({ isLoading: true });
    const dateParams = { startAt, endAt };
    const params = {
      [goal]: condition,
      hostMobileNumber,
      educationalSystem,
      subject,
      ...dateParams,
      nowPage,
      rowsPage
    };
    if(classId) delete params.groupName;
    await getOrganizationSessions(params, signal);
    !controller.signal.aborted && setState({ isLoading: false });
  };

  /* 打學制、科目 API */
  const fetchSessionsOptions = async () => {
    const { isSuccess, data: { educationalSystems, subjects }, } = await getSessionOptionsApi(organizationId);
    if(isSuccess) {
      const selectOptionsEducation = educationalSystems.map(({ name, code }) => ({ name, value: code }));
      setState({ 
        selectOptionsEducation, 
        subjectMap: subjects,
       });
    }
  };

  useEffect(() => {
    const isNoTime = !startAt && !endAt;
    if (isNoTime) return;
    fetchSessions();
    return () => controller.abort();
  }, [
    sessionName,
    groupName,
    hostName,
    groupOwnerName,
    hostMobileNumber,
    educationalSystem,
    subject,
    dateInterval,
    startAt,
    endAt,
    nowPage,
    rowsPage]);

  const getStartTimeType = (startAt, endAt) => {
    const now = new Date().getTime();
    if (now < startAt) return SESSION_START_TIME_TYPE.YET;
    if (now > endAt) return SESSION_START_TIME_TYPE.ALREADY;
    return SESSION_START_TIME_TYPE.PROCESSING;
  };

  /* 取得課程類型 */
  const getSessionType = (type) => {
    if (type === 'normal') return SESSION_TYPE.NORMAL;
    if (type === 'video') return SESSION_TYPE.VIDEO;
    if (type === 'interactive') return SESSION_TYPE.INTERACTIVE;
    if (type === 'collaborative') return SESSION_TYPE.COLLABORATIVE;
  };

  /* 取得課程狀態 */
  const getStatusText = (status, cancelReason) => {
    if (status === 'cancel') return cancelReason;
    if (status === 'new') return READY_STATE.NEW;
    if (status === 'ready') return READY_STATE.READY;
  };

  /* 取得商品名稱 */
  const getProductName = (product) => {
    return product.productName;
  };

  // eslint-disable-next-line react/prop-types
  const StartTimeIcon = ({ children, type }) => (
    <UiStartTimeIcon type={type}>
      <Icon
        className="icon"
        name="info"
        size="small"
        title={SESSION_START_TIME_TYPE_INFO[type]}
        haveBg={false}
      />
      <span>{children}</span>
    </UiStartTimeIcon>
  );

  const formatSessionsData = data => data.map(item => {
    const startTimeType = getStartTimeType(item.startAt, item.endAt);
    const sessionType = getSessionType(item.type);
    const statusText = getStatusText(item.status, item.cancelReason);
    const productName = getProductName(item.product);

    return {
      ...item,
      statusText,
      productName,
      type: item.type,
      sessionType,
      classTime: ((item.endAt - item.startAt) / 60 / 1000) + '分',
      startTime: (
        <StartTimeIcon type={startTimeType}>
          {format(new Date(item.startAt), 'yyyy-MM-dd HH:mm')} ~ {format(new Date(item.endAt), 'HH:mm')}
        </StartTimeIcon>
      ),
      // 出勤欄位
      // attendance:(
      //   <>
      //     <div>{item.hostAttendance ? item.hostName :''}</div>
      //     {item.hostAttendance?.checkInAt ? format(new Date(item.hostAttendance?.checkInAt), 'HH:mm') : '-'}
      //     {' / '}
      //     {item.hostAttendance?.checkOutAt ? format(new Date(item.hostAttendance?.checkOutAt), 'HH:mm'): '-'}
      //   </>
      // ),
    };
  });

  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params: {id: sessionId,groupId, groupName, timeSpanId,resources, startAt, endAt,name,type,users, resourcesText: resourceId, }}) => {
    const usersLength = users?.length || 0;
    // const isExpired = (getTime(new Date(endAt)) < getTime(new Date())) ? true : false;
    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${sessionId}`;
      window.open(url);
    };

    // click 課堂報告
    const gotoSessionReport = () => {
      const location = {
        pathname: classId
          ? `/organization/${organizationId}/class/${classId}/session/${sessionId}/timespan/${timeSpanId}/report`
          : `/organization/${organizationId}/class/session/${sessionId}/timespan/${timeSpanId}/report`,
      };
      history.push(location);
    };

    // for oneboard
    let classType = '';
    switch(type){
      case 'interactive':
        classType = usersLength <= 1 ? 'single' : 'group';
        break;
      case 'collaborative':
        classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
        break;
      case 'video':
        classType = 'group';
        break;
      default :
        break;
    }
    const querystring = stringify({
      bookIds: [...resources],
      classType,
      role: 'advisor',
      userName: myOrganization.userProfile?.nickname,
      userId: profile.id
    },{ arrayFormat: 'index' });

    return (
      <UiIconBox>
        {
          <Icon.Svg
            title="進入教室"
            name='Exclude'
            circleBg={true}
            onClick={() =>
              window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${sessionId}/setup?${querystring}`, '_blank')}
          />
        }
        {/* {
          !isExpired && (
            <Icon
              title="編輯"
              onClick={() =>
                history.push(
                  `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.EDIT}`
                )}
              name='edit'
            />
          )
        } */}
        {
          (type === 'interactive' || type === 'collaborative' || type === 'video') &&
          <Icon
            title="觀課"
            name="visibility"
            onClick={onViewSession}
          />
        }
        {
          // 課程類型為「協作、互動」，且「上課前十分鐘（預課時間）」，才顯示課堂報告
          ((type === 'interactive' || type === 'collaborative') && Date.now() > startAt - 10 * minute) &&
            <Icon
              title='課堂報告'
              name='assessment'
              onClick={gotoSessionReport}
            />
        }
        {/* <Icon
          title="複製課程"
          name='fileCopy'
          onClick={() =>
            history.push(
              `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.COPY}`
            )}
          /> */}
        {/* <Icon
          title="刪除"
          onClick={() => modalToggle(name,groupId,sessionId,timeSpanId)}
          name='delete' /> */}
      </UiIconBox>
    );
  };

  // 刪除課程用邏輯
  // const modalToggle = (name,targetClass,sessionId,timeSpanId) => {
  //   name && setTargetSession(name);
  //   targetClass && setTargetClassId(targetClass);
  //   sessionId && setState({
  //     targetSessionId:sessionId,
  //     targetTimeSpanId:timeSpanId
  //   });
  //   setIsOpen(!isOpen);
  // };

  // 刪除課程用邏輯
  // const onDeleteSession = async () => {
  //   setState({isLoading:true});
  //   const isSuccess = await deleteOrganizationGroupSession(targetClassId,targetSessionId,targetTimeSpanId);
  //   setState({isLoading:false});
  //   modalToggle();
  //   if(isSuccess) await fetchSessions();
  //  };

  const otherObject = {
    fetchSessions: fetchSessions,
  };

  return (
    <UiTable>
      {
        classId && 
          <UiActionBox>
            <Breadcrumbs list={BreadcrumbsList} />
            <UiButtonBox>
              <Button
                buttonColor="info"
                icon='exitToApp'
                onClick={() => history.goBack()}
              >
                回到上一頁
              </Button>
              <Button
                buttonColor='highlight'
                icon='add'
                onClick={() => goCreateSession()}>新增課程</Button>
            </UiButtonBox>
          </UiActionBox>
      }
      {
        !noFilter && (
          <UiActionBox>
            <UiflexBox>
              <Select
                options={selectOptions}
                value={dateInterval}
                submitHandler={value => onSelectChangeHandler(value)}
                label="課程區間"
              />
              <UiVerticalLine />
              <Select
                label='學制'
                options={selectOptionsEducation}
                value={educationalSystem}
                submitHandler={educationChangeHandler}
              />
              <Select
                label='科目'
                options={selectOptionsSubject}
                value={subject}
                submitHandler={subjectChangeHandler}
              />
              <UiVerticalLine />
                {
                  dateInterval === NOW || dateInterval === PROCESSING
                    ? <>
                        <TimePicker
                          label='開始時間'
                          value={startAt}
                          onChange={value => onchangeHandler(value, 'startAt')}
                        />
                        <TimePicker
                          label='結束時間'
                          value={endAt}
                          onChange={value => onchangeHandler(value, 'endAt')}
                        />
                      </>
                    : <>
                        <DateTimePicker
                          label="開始時間"
                          value={startAt}
                          onChange={value => onchangeHandler(value, 'startAt')}
                        />
                        <DateTimePicker
                          label="結束時間"
                          value={endAt}
                          onChange={value => onchangeHandler(value, 'endAt')}
                        />
                      </>
                }
              <UiVerticalLine />
              <Select
                label="搜尋目標" options={selectInputOptions}
                submitHandler={value => onSubmitHandler(value, 'goal')}
                value={goal}
              />
              <IconInput
                placeholder="搜尋"
                onClick={value => onSubmitHandler(value, 'condition')}
                onKeyPress={value => onSubmitHandler(value, 'condition')}
                value={condition}
              />
            </UiflexBox>

          </UiActionBox>
        )
      }
      {
        isLoading ? (
          <Loading />
        ) : (
          sessions.total > 0 ? (
              <Table
                data={formatSessionsData(sessions.data)}
                schema={schema}
                stateShowText={[]}
                changePage_Rows={changePage_Rows}
                totalPage={sessions.total}
                ActionComponents={ActionComponents}
                ActionComponentsText='查看課程'
                MoreActionComponents={MoreActionComponents}
                MoreActionComponentsText='編輯'
                nowPage={nowPage}
                rowPage={rowsPage}
                pagesDisplay={pagesDisplay}
                otherObject={otherObject}
              />
            ) : <UiTableInfo>尚無課程</UiTableInfo>
          )
      }
      {/* <BsModal open={isOpen} title="是否要刪除此課程" onOk={onDeleteSession} onCancel={modalToggle} isLoading={isLoading}>
          確認刪除課程 <span style={{color: '#f00'}}>{`"${targetSession}"`}</span>
      </BsModal> */}
    </UiTable>
  );
};

SessionManagementTable.propTypes = {
  noFilter: PropTypes.bool,
  defaultDuration: PropTypes.oneOf([PROCESSING, NOW, YESTERDAY, PREV_TO_NEXT_MONTH])
};
