import styled from 'styled-components';
import { 
	Icon as MatIcon, 
	// SvgIcon
 } from '@material-ui/core';

export const UiIcon = styled(MatIcon)`
	padding: 2px;
	font-size: ${({ fontSize }) => fontSize || '2rem'};
	color: ${({ icolor }) => icolor};
	border-radius: 50%;
	${({ theme, haveBg, type }) =>
    haveBg &&
    {
      backgroundColor: type === 'danger' ?
        theme.palette.main.danger :
        '#242C3F'
    }
  }
`;

export const UiSvgIcon = styled(MatIcon)`
	font-size: ${({ fontSize }) => fontSize || '2rem'};
	padding: 2px;
	
	> path {
		fill: ${({color})=>color};
	}
`;

export const UiSvgIconCircleBg = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	background-color: #242c3f;
	border-radius: 50%;
	cursor: pointer;

	> svg {
		font-size: 1.7rem;
	}
`;