import React from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { Icon } from 'components';
import { UiResourceInput,UiCircularProgress } from './ResourceInput.style';

export const ResourceInput = ({
  onSubmit,
  isLoading = false ,
  errorMsg = '',
  value = [],
  onDeleteTag = () => {}
}) => {
  const [{
    resources
  }, setState] = useSetState({
    resources:''
  });
  const onChange = (e) => {
    const targetValue = e.target.value;
    setState({
      resources: targetValue
    });
  };
  const onClick = () => {
    const resourcesArr = resources.split(',');
    setState({
      resources:''
    });
    onSubmit && onSubmit(resourcesArr);
  };

  return (
    <UiResourceInput disabled={resources.length === 0} isLoading={isLoading}>
      <label className="content">
        <input
          type="text"
          onChange={onChange}
          value={resources}
          placeholder="加入教材資源"
        />
        <button disabled={isLoading || resources.length === 0} onClick={onClick}>
          { isLoading ? <UiCircularProgress size={18}/> : '加入' }
        </button>
      </label>
      <div className="footer">
        <div className="content">
          {errorMsg && <div className="errorMsg">{errorMsg}</div>}
          {/* <div className="remind">
            <Icon name="errorOutline" haveBg={false} title="最多可輸入六本 bookID"/>
          </div> */}
        </div>
        {
          value.length > 0 &&
          <div className="tags">
            {
              value.map((item,index)=>{
                const { resourceId,resourceName } = item;
                return (
                  <div className="tag" key={resourceId}>
                    {`${resourceName}(ID:${resourceId})`}
                    <div className="close">
                      <Icon name="close" haveBg={false} onClick={() => onDeleteTag(resourceId)}/>
                    </div>
                  </div>
                );
              })
            }
          </div>
        }
      </div>
    </UiResourceInput>
  );
};


ResourceInput.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  errorMsg: PropTypes.string,
  value: PropTypes.array,
  onDeleteTag: PropTypes.func
};
