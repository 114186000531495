import { Api } from 'services/api';
import { stringify } from 'query-string';
/**
 * 取得組織邀請列表
 */
export const getOrganizationInvitations = async () => {
  const res = await Api.get('/users/organization-invitations');
  try {
    const error = {
      message: 'cant use api'
    };
    if (!res) throw error;
    return res.data;
  } catch {
    return [];
  }
};

/**
 * 取得非組織邀請列表
 */
export const getGroupInvitations = async () => {
  const res = await Api.get('/users/group-invitations');
  try {
    const error = {
      message: 'cant use api'
    };
    if (!res) throw error;
    return res.data;
  } catch {
    return [];
  }
};

/**
 * 接受組織邀請
 * @param {string} organizationId
 */
export const acceptOrganizationInvitation = async organizationId => {
  const { isSuccess } = await Api.post(`/users/organization-invitations/${organizationId}/accept`);
  return isSuccess;
};

/**
 * 接受非組織邀請
 * @param {string} classId
 */
export const acceptGroupInvitation = async classId => {
  const { isSuccess } = await Api.post(`/users/group-invitations/${classId}/accept`);
  return isSuccess;
};

/**
 * 取得已送出未接受非組織邀請的人員列表
 * @param {string} classId
 */
export const getFreeGroupUserInvitations = classId => async params => {
  const { role, nowPage, rowsPage } = params;

  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (role) {
    queryParams.role = role;
  }
  const querystring = await stringify(queryParams);

  const response = await Api.get(`/groups/${classId}/users/invitations?${querystring}`);
  return response;
};

export const cancelFreeGroupUserInvitation = classId => async (userId) => {
  const response = await Api.delete(`/groups/${classId}/users/invitations/${userId}`);
  return response;
};

//重新拉取oneCLass資料
export const refreshProfile = async () => {
  const response = await Api.get('/users/me/refresh');
  return response;
};



//更新會員資料
export const updateMyProfile = async (params) => {
  const {
    nickname,
    name,
    gender,
    birthday,
    address,
    addressData,
    thumbnailUrl
  } = params;
  const queryParams = {};
  if (nickname) queryParams.nickname = nickname;
  if (name) {
    if (name.firstName)
      queryParams.firstName = name.firstName;
    if (name.lastName)
      queryParams.lastName = name.lastName;
  }
  if (gender) queryParams.gender = gender;
  if (birthday) queryParams.birthday = birthday;
  if (address) queryParams.address = address;
  if (addressData) {
    if (addressData.county)
      queryParams.addressCountyName = addressData.county;
    if (addressData.township)
      queryParams.addressDistrictName = addressData.township;
  }
  if (thumbnailUrl) queryParams.thumbnailUrl = thumbnailUrl;
  const response = await Api.put('/users/me', queryParams);
  return response;
};


/**
 * 申請專業版會員
 * @param {*} params
 */
export const setPremiumUsers = async (params) => {
  const { mobileNumber, email, userId } = params;
  const queryParams = {};

  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }

  if (email) {
    queryParams.email = email;
  }
  if (userId) {
    queryParams.userId = userId;
  }
  const response = await Api.post('/users/premium', queryParams);
  return response;
};


/**
 * 取得私人教材列表
 * @param {*} params
 */
 export const getPrivateResources = async (params) => {
  const { nowPage = 0, rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  const querystring = await stringify(queryParams);
  const response = await Api.get(`/users/resources?${querystring}`);
  return response;
};


/**
 * 取得oneTeacher教師列表
 * @param {*} params
 */
 export const getOneTeacher = async (organizationId,params) => {
  const { 
    educationalSystem,
    subjectCode,
    startAt,
    endAt,
    includingSessionId,
    teacherId,
    teacherName,
  } = params;
  const queryParams = {
    educationalSystem,
    subjectCode,
  };
  
  if (startAt) {
    queryParams.startAt = startAt;
  }

  if (endAt) {
    queryParams.endAt = endAt;
  }

  if(includingSessionId){
    queryParams.includingSessionId = includingSessionId;
  }

  if (teacherId) {
    queryParams.teacherId = teacherId;
  }

  if (teacherName) {
    queryParams.teacherName = teacherName;
  }

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/teachers?${querystring}`);
  return response;
};

