import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 * 試卷列表
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} educationalSystem 學制
 * @param {string} subject 科目
 * @param {string} paperName 測驗名稱搜尋
 * @param {number} limit 一頁顯示幾筆
 * @param {string} beforeId 在 試卷ID 之前
 * @param {string} afterId 在 試卷ID 之後
 *
 * @return {object}
 */
export const getOrganizationGroupPaperList = async params => {
  const { organizationId, groupId, educationalSystem, subject, paperName, beforeId, afterId } = params;

  const queryParams = {
    limit: 100
  };

  if (educationalSystem) queryParams.educationalSystem = educationalSystem;

  if (paperName) queryParams.paperName = paperName;

  if (subject) queryParams.subject = subject;

  if(beforeId) queryParams.beforeId = beforeId;

  if(afterId) queryParams.afterId = afterId;

  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/papers?${querystring}`);
  return response;
};

/**
 * 試卷列表 學制＆科目 下拉選單
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 *
 * @return {object}
 */
 export const getOrganizationGroupPaperOptions = async params => {
  const { organizationId, groupId } = params;
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/papers/options`);
  return response;
};

/**
 * 測驗列表 學制＆科目 下拉選單
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 *
 * @return {object}
 */
 export const getOrganizationGroupExamOptions = async params => {
  const { organizationId, groupId } = params;
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/exams/options`);
  return response;
};

/**
 * 建立派卷
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} paperId 試卷ＩＤ
 * @param {string} name 測驗名稱
 * @param {string} startAt 開始時間
 * @param {string} endAt 結束時間
 * @param {number} period 作答時間
 * @param {array} markers 批改老師ＩＤ
 *
 * @return {object}
 */
 export const createOrganizationGroupExam = async params => {
  const { organizationId, groupId, paperId, name, startAt, endAt, period, markers } = params;
  const payload = {
    paperId,
    name,
    startAt,
    endAt,
    period,
    markers,
  };
  const response = await Api.post(`/organizations/${organizationId}/groups/${groupId}/exams`, payload);
  return response;
};

/**
 * 編輯派卷
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 測驗ＩＤ
 * @param {string} name 測驗名稱
 * @param {string} startAt 開始時間
 * @param {string} endAt 結束時間
 * @param {number} period 作答時間
 * @param {array} markers 批改老師ＩＤ
 *
 * @return {object}
 */
 export const editOrganizationGroupExam = async params => {
  const { organizationId, groupId, examId, name, startAt, endAt, period, markers } = params;
  const payload = {
    name,
    startAt,
    endAt,
    period,
    markers,
  };
  const response = await Api.put(`/organizations/${organizationId}/groups/${groupId}/exams/${examId}`, payload);
  return response;
};


/**
 * 考卷列表
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} educationalSystem 學制
 * @param {string} subject 科目
 * @param {string} examName 考卷名稱搜尋
 * @param {string} sortKey
 * @param {string} sortOrder
 * @param {number} limit 一頁顯示幾筆
 * @param {string} beforeId 在 試卷ID 之前
 * @param {string} afterId 在 試卷ID 之後
 *
 * @return {object}
 */
 export const getOrganizationGroupExamList = async params => {
  const {
    organizationId,
    groupId,
    educationalSystem,
    subject,
    examName,
    sortKey,
    sortOrder,
    beforeId,
    afterId
  } = params;

  const queryParams = {
    limit: 100
  };

  if (educationalSystem) queryParams.educationalSystem = educationalSystem;

  if (examName) queryParams.examName = examName;

  if (sortKey) queryParams.sortKey = sortKey;

  if (sortOrder) queryParams.sortOrder = sortOrder;

  if (subject) queryParams.subject = subject;

  if(beforeId) queryParams.beforeId = beforeId;

  if(afterId) queryParams.afterId = afterId;

  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/exams?${querystring}`);
  return response;
};


/**
 * 刪除測驗
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 試卷ＩＤ
 * @return {object}
 */
 export const deleteOrganizationGroupExam = async params => {
  const { organizationId, groupId, examId } = params;
  const response = await Api.delete(`/organizations/${organizationId}/groups/${groupId}/exams/${examId}`);
  return response;
};


/**
 * 試卷列表
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 在 試卷ID 之後
 *
 * @return {object}
 */
 export const getOrganizationExamResult = async params => {
  const { organizationId, groupId, examId } = params;

  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/exams/${examId}/results`);
  return response;
};
