import React, { useEffect, } from 'react';
import PropTypes from 'prop-types';
import ModalSystemLog from './ModalSystemLog/';

import { stringify } from 'query-string';
import { getCustomer as getCustomerApi } from 'services/api/organization/customer';
import { getOrganizationStaffs as getOrganizationStaffsApi } from 'services/api/organization/teacher';
import {
  Button,
  BsModal,
  Icon,
  Loading,
  Table,
} from 'components';
import { useParams, } from 'react-router-dom';
import { useUser } from 'store/user/index';
import { useSetState } from 'utils/hooks/useSetState';
import { UiActionContainer } from './ActionComponents.style';

// const tableData = [{
//   nickname: '機構暱稱1號',
//   originalName: '王豆子',
//   originalNickname: '痘痘',
//   id: 'useridyoudontknow',
//   mobileNumber: '0933112243',
// }];
const schema = {
  nickname: { name: '機構暱稱', defaultValue: '' },
  originalName: { name: '姓名', defaultValue: '' },
  originalNickname: { name: '暱稱', defaultValue: '' },
  id: { name: '帳號id', defaultValue: '' },
  mobileNumber: { name: '聯絡電話', defaultValue: '' },
};

export const ActionComponents = (props) => {
  const { hostId, resources, sessionId: roomId, type, userId, users } = props.params;

  const { organizationId, } = useParams();
  const [{ myOrganization }] = useUser();
  const [{ 
    isLoaded,
    isOpenContactInfoModal,
    isOpenSystemLogModal,
    studentData,
    teacherData,
    tableData,
  }, setState] = useSetState({
    isLoaded: false,
    isOpenContactInfoModal: false,
    isOpenSystemLogModal: false,
    studentData: null,
    teacherData: null,
    tableData: [],
  });

  /* 設定表格資料 */
  const setTableData = () => {
    const data = [
      {
        nickname: studentData.nickname,
        originalName: studentData.originalName,
        originalNickname: studentData.originalNickname,
        id: studentData.id,
        mobileNumber: studentData.mobileNumber,
      },
      {
        nickname: teacherData.nickname,
        originalName: teacherData.originalName,
        originalNickname: teacherData.originalNickname,
        id: teacherData.id,
        mobileNumber: teacherData.mobileNumber,
      }
    ];

    setState({ 
      tableData: data,
      isLoaded: true,
    });
  };

  /* 從 API 取得學生、老師資料 */
  const fetchData = async () => {
    const isHome = false;
    const promises = [
      getCustomerApi(organizationId, userId)(), 
      getOrganizationStaffsApi(isHome)(organizationId)({targetUserId: hostId})
    ];

    Promise.all(promises).then(response => {
      (response[0].isSuccess && response[1].isSuccess) &&
        setState({
          studentData: response[0]?.data,
          teacherData: response[1]?.data?.userProfiles[0],
        });
    });
  };

  /* click 進入教室 */
  const onClickEnterClass = () => {
    let classType = '';
    const usersLength = users?.length - 1 || 0; // 把老師扣除

    switch(type){
      case 'interactive':
        classType = usersLength <= 1 ? 'single' : 'group';
        break;
      case 'collaborative':
        classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
        break;
      case 'video':
        classType = 'group';
        break;
      default :
        break;
    }

    const queryString = stringify({
      bookIds: [...resources],
      classType: classType,
      role: 'advisor',
      userId: myOrganization?.userProfile?.id,
      userName: myOrganization?.userProfile?.nickname,
    }, { arrayFormat: 'index' });

    window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${roomId}/setup?${queryString}`, '_blank');
  };

  /* click 聯絡資訊 */
  const onClickContactInfoHandler = () => {
    fetchData();
    setState({ isOpenContactInfoModal: true });
  };

  const clickCancelOfModalSystemLog = () => {
    setState({isOpenSystemLogModal: false});
  };

  /* trigger 設定 tableData 狀態 */
  useEffect(() => {
    (studentData && teacherData) && setTableData();
  }, [studentData, teacherData]);

  return (
    <>
      {/* 系統記錄 */}
      <ModalSystemLog
        isOpen={isOpenSystemLogModal}
        onCancel={clickCancelOfModalSystemLog}
        roomId={roomId}
      />
      
      {/* 聯絡資訊 */}
      <BsModal
        open={isOpenContactInfoModal}
        isFull={true}
        maxWidth={'760px'}
        oKDisplay={false}
        cancelDisplay={false}
        onCancel={() => {
          setState({ isOpenContactInfoModal: false });
        }}
      >
        {
          isLoaded 
            ? <Table 
                schema={schema}
                data={tableData}
                isNeedPagination={false}
              />
            : <Loading />
        }
      </BsModal>

      <UiActionContainer>
        <Icon
          name='speakerNotes'
          size='2.2rem'
          title='系統記錄'
          onClick={()=>setState({isOpenSystemLogModal: true})}
        />
        <Icon.Svg
          circleBg={true}
          name='Exclude'
          title='進入教室'
          onClick={onClickEnterClass}
        />
        <Button
          onClick={onClickContactInfoHandler}
        >
          聯絡資訊
        </Button>
      </UiActionContainer>
    </>
  );
};

ActionComponents.propTypes = {
  params: PropTypes.shape({
    hostId: PropTypes.string,
    resources: PropTypes.array,
    sessionId: PropTypes.string,
    type: PropTypes.string,
    userId: PropTypes.string,
    users: PropTypes.array,
  })
};
