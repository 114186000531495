import { useCallback, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { useSetState } from 'utils/hooks/useSetState';

import envConfig from 'firebaseConfig.json';

const {
  LIVE_ONECLASS_API_KEY,
	LIVE_ONECLASS_AUTH_DOMAIN,
	LIVE_ONECLASS_DATABASE_URL,
	LIVE_ONECLASS_PROJECT_ID,
	LIVE_ONECLASS_STORAGE_BUCKET,
} = envConfig[process.env.REACT_APP_ENV];

const firebaseConfig = {
	apiKey: LIVE_ONECLASS_API_KEY,
	authDomain: LIVE_ONECLASS_AUTH_DOMAIN,
	databaseURL: LIVE_ONECLASS_DATABASE_URL,
	projectId: LIVE_ONECLASS_PROJECT_ID,
	storageBucket: LIVE_ONECLASS_STORAGE_BUCKET,
};

export const useFirebase = (config = firebaseConfig) => {
  const [{ storage }, setState] = useSetState({
    storage: null
  });
  const initialize = useCallback(() => {
    let app;
    if (firebase.apps.length <= 0 || !firebase.app('oneclass')) {
      app = firebase.initializeApp(config, 'oneclass');
    } else {
      app = firebase.app('oneclass');
    }

    app.auth().signInAnonymously()
      .then(() => {
        setState({ storage: app.storage() });
      })
      .catch(err => console.error('sign in anonymously error', err));
  }, [config, setState]);
  useEffect(() => {
    if (!config) return;
    initialize();
  }, [config, initialize]);
  return { storage };
};
