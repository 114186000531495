import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BsModal,
  DateTimePicker,
  NumberInput,
  Select,
  IconInput,
  Checkbox,
} from 'components';
import { useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { getGroupInfoById } from 'services/api/organization/class';
import {
  UiExamModal,
  UiFormBox,
  UiTable,
  UiTr,
  UiTd,
  UiErrorMsg,
} from './ExamModal.style';
import { UiActionBox, UiflexBox } from 'styles';
import {
  TextField as MatTextField,
  TablePagination as MatTablePagination,
  Box
 } from '@material-ui/core';


/**
 * 試卷管理
 *
 * @param {string} editStatus setUp / choose
 */

const stateOptions = [
  {
    name:'帳號',
    value: 'targetUserId'
  },
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '機構暱稱',
    value: 'nickname'
  }
];

export const ExamModal = ({
  type = 'create',
  examData,
  editStatus,
  title,
  isOpen,
  isLoading,
  okText,
  cancelText,
  teacherData = [],
  teacherTotal = 0,
  teacherNowPage,
  teacherRowsPage,
  isCanPrevious = true,
  onSubmitHandle = () => {},
  onCancelHandle = () => {},
  onTeacherSelectHandler = () => {},
  onTeacherInputChange = () => {},
  onTeacherInputClick = () => {},
  onChangePageHandle = () => {},
  onChangeRowsPerPageHandle = () => {},
  onChange = () => {},
}) => {
  const [{
    examPeriod, // 作答時間
    startAt, // 開始時間
    endAt, // 結束時間
    examName, // 測驗名稱
    correctTeachers, // 批改老師
    okDisabled,
    errorMsg, //錯誤訊息
    ownerId, // 導師id
  },setState] = useSetState({
    examPeriod: null,
    startAt: null,
    endAt: null,
    examName: '',
    correctTeachers: [],
    okDisabled: true,
    errorMsg:'',
    ownerId: null,
  });
  const { organizationId, classId } = useParams();

  // 作答Ｘ開始Ｘ結束 時間
  const onChangeTime = (value,key) => {
    if(key === 'examPeriod') {
      if(value.indexOf('.') !== -1){
        setState({
          examPeriod,
          errorMsg:''
        });
        return;
      }else{
        if(value > 180) {
          setState({
            errorMsg:'測驗時長不能超過3小時(180分)',
            examPeriod:180
          });
          return;
        }
      }

    }
    if(key === 'endAt' && ((value - startAt) < parseInt(examPeriod) * 60 * 1000) && value !== null) return;
    setState({
      [key]:value,
      errorMsg:''
    });
  };

  // 老師搜尋文字
  const onNumberInputChange = value => {
    setState({
      examName: value,
    });
  };

  // 勾選 批改老師
  const onCheckHandle = (checked, value) => {
    let teacherList = [];
    if(!checked) {
      teacherList = correctTeachers.filter(teacher=> teacher !== value);
    } else {
      teacherList = [ ...correctTeachers, value];
    }
    setState({
      correctTeachers: teacherList,
    });
  };

  /* 取得導師id */
  const fetchOwnerId = async () => {
    const { isSuccess, data } = await getGroupInfoById(organizationId, classId);
    if(!isSuccess) return;
    setState({ ownerId: data.ownerId });
  };

  useEffect(()=>{
    fetchOwnerId();
  }, []);

  /* 將導師設為預設勾選 */
  useEffect(()=>{
    setState({ correctTeachers: [ownerId]});
  }, [ownerId]);

  useEffect(() => {
    if (editStatus === 'setUp') {
      if (startAt && endAt && examPeriod && examName){
        setState({
          okDisabled: false,
        });
      } else {
        setState({
          okDisabled: true,
        });
      }
    }

    if (editStatus === 'choose') {
      if (correctTeachers.length > 0) {
        setState({
          okDisabled: false,
        });
      } else {
        setState({
          okDisabled: true,
        });
      }
    }
  }, [editStatus,examPeriod, startAt, endAt, examName, correctTeachers]);

  useEffect(()=>{
    onChange({examPeriod, startAt, endAt, examName, correctTeachers});
  },[examPeriod, startAt, endAt, examName, correctTeachers]);

  useEffect(()=>{
    if(!isOpen || type === 'create') return;
    setState({
      examPeriod: examData?.examPeriod || null,
      startAt: examData?.startAt || null,
      endAt: examData?.endAt || null,
      examName: examData?.examName || '',
      correctTeachers: examData?.correctTeachers || [],
    });
  },[examData]);

  return (
    <UiExamModal>
      <BsModal
        open={isOpen}
        title={title}
        okText={okText}
        cancelText={cancelText}
        okDisabled={okDisabled}
        onOk={onSubmitHandle}
        onCancel={onCancelHandle}
        isLoading={isLoading}
        isFull={true}
      >
        {
          editStatus === 'setUp' && <>
            <UiFormBox position={'24%' }>
              <Box display="flex" justifyContent="center" width="100%" flexWrap="wrap">
                <MatTextField
                  label="作答時間(分鐘)*"
                  variant="outlined"
                  type="number"
                  style={{width: '85%'}}
                  value={examPeriod || null}
                  onChange={e => onChangeTime(e.target.value,'examPeriod')}
                  disabled={!isCanPrevious}
                  InputProps={{ inputProps: { min: 10, max: 180 } }}
                />
                {errorMsg && <UiErrorMsg>{errorMsg}</UiErrorMsg>}
              </Box>
              <DateTimePicker
                label="開始時間*"
                style={{width: '85%', margin: '10px 0'}}
                value={startAt}
                onChange={value => onChangeTime(value,'startAt')}
                disabled={!isCanPrevious}
              />
              {
                (examPeriod && startAt) &&
                <DateTimePicker
                  label="結束時間*"
                  style={{width: '85%', margin: '10px 0'}}
                  value={endAt}
                  onChange={value => onChangeTime(value,'endAt')}
                  disabled={!isCanPrevious}
                />
              }
              <NumberInput label="測驗名稱*" onChange={onNumberInputChange} value={examName} disabled={!isCanPrevious}/>
            </UiFormBox>
          </>
        }
        {
          editStatus === 'choose' && <>
            <UiActionBox>
              <UiflexBox>
                <Select
                  label="搜尋條件"
                  options={stateOptions}
                  submitHandler={onTeacherSelectHandler}
                />
                <IconInput
                  placeholder="搜尋"
                  onChange={onTeacherInputChange}
                  onClick={onTeacherInputClick}
                />
              </UiflexBox>
            </UiActionBox>
            <UiTable>
              {
                teacherData.length > 0 && teacherData.map(item => {
                  const isChecked = correctTeachers.includes(item.id);
                  return (
                    <UiTr key={item.id}>
                      <UiTd>
                        <Checkbox
                          label={`${item.nickname ||item.originalName} (ID: ${item.id})`}
                          onChange={val => onCheckHandle(val, item.id)}
                          checkedValue={item.id}
                          checked={isChecked}
                        />
                      </UiTd>
                    </UiTr>
                  );
                })
              }
            </UiTable>
            <MatTablePagination
              count={teacherTotal}
              page={teacherNowPage}
              rowsPerPage={teacherRowsPage}
              onChangePage={onChangePageHandle}
              labelRowsPerPage='每頁顯示'
              onChangeRowsPerPage={onChangeRowsPerPageHandle}
              component="div"
            />
          </>
        }
      </BsModal>
    </UiExamModal>
  );
};

ExamModal.propTypes = {
  type:PropTypes.string,
  examData:PropTypes.object,
  editStatus: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  okText: PropTypes.string,
  teacherData: PropTypes.array,
  teacherTotal: PropTypes.number,
  teacherNowPage: PropTypes.string,
  teacherRowsPage: PropTypes.string,
  cancelText: PropTypes.string,
  isCanPrevious: PropTypes.bool,
  onSubmitHandle: PropTypes.func,
  onCancelHandle: PropTypes.func,
  onTeacherSelectHandler: PropTypes.func,
  onTeacherInputChange: PropTypes.func,
  onTeacherInputClick: PropTypes.func,
  onChangePageHandle: PropTypes.func,
  onChangeRowsPerPageHandle: PropTypes.func,
  onChange: PropTypes.func,
};


