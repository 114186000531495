import styled from 'styled-components';

export const UiOptionMenu = styled.div`
	@keyframes slider{
		0%{
			opacity: 0;
			top: 0;
		};
		100%{
			opacity: 1;
			top: 35px;
		}
	};

	position: absolute;
	top: 35px;
	right: 15px;
	z-index: 100;
	width: 80px;
	background: #FFF;
	box-shadow: 0 9px 16px -4px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	padding: 4px 0;
	animation-name: slider;
	animation-duration: 0.3s;
`;

export const UiOption = styled.div`
	color: #3A4052;
	font-size: 0.9rem;
	padding: 5px 12px;
	cursor: pointer;

	&:hover{
		background-color: #F5F5F5;
	}
`;