import React from 'react';

import { BsHeaderTitle, StudentsAnswer } from 'components';
import { UiBsContentContainer } from 'styles';


/**
 * 學生作答 頁面
 */

export const StudentsAnswerPage = () => {
  return (
    <>
      <BsHeaderTitle title="學生作答" />
      <UiBsContentContainer>
        <StudentsAnswer />
      </UiBsContentContainer>
    </>
  );
};
