/* eslint-disable max-len */
import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 -@param {string} organizationId
 *@param {string} classId
 *@param {string} hostId
 *@param {string} resourceId
 *@param {string} name
 *@param {number} startAt
 -@param {number} endAt
 *@創建課程
 */
export const createOrganizationGroupSession = (organizationId, classId) => async params => {
  const { data } = params;
  const response = await Api.post(`/organizations/${organizationId}/groups/${classId}/sessions`, data);
  return response;
};

export const getOrganizationSession = (organizationId, classId) =>  params => async signal => {
  const {
    sessionName,
    groupName,
    hostName,
    hostMobileNumber,
    educationalSystem,
    subject,
    groupOwnerName,
    startAt,
    endAt,
    nowPage,
    rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (sessionName) queryParams.sessionName = sessionName;
  if (classId) {
    queryParams.groupId = classId;
  } else if (groupName) {
    queryParams.groupName = groupName;
  }
  if (hostName) queryParams.hostName = hostName;
  if (groupOwnerName) queryParams.groupOwnerName = groupOwnerName;
  if (hostMobileNumber) queryParams.hostMobileNumber = hostMobileNumber;
  if (educationalSystem) queryParams.educationalSystem = educationalSystem;
  if (subject) queryParams.subject = subject;
  if (startAt) queryParams.startAt = startAt;
  if (endAt) queryParams.endAt = endAt;
  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/sessions?${querystring}`, {} , signal);
  return response;
};

/**
 *  更新 課程資訊
 * @param {string} hostId
 * @param {string} name
 * @param {string} timeSpanId
 * @param {number} startAt
 * @param {number} endAt
 * @param {string} resourceId
 */
export const updateOrganizationGroupSession = (organizationId, classId, sessionId) => async params => {
  const { data } = params;
  const response =
    await Api.put(`/organizations/${organizationId}/groups/${classId}/sessions/${sessionId}`, data);
  return response;
};

/**
 * 取消課程
 * @param {string} organizationId 
 * @param {string} classId 
 * @param {string} sessionId 
 * 
 * https://asia-east1-live-oneclass-dev.cloudfunctions.net/organizations/docs/#/Organization%20Administration%20-%20Sessions/post__organizationId__groups__groupId__sessions__sessionId__cancel
 */
export const cancelOrganizationGroupSession = async (organizationId, classId, sessionId, payload) => {
  const response = await Api.post(`/organizations/${organizationId}/groups/${classId}/sessions/${sessionId}/cancel`, payload);
  return response;
};


export const getSessionSummary = async (organizationId, sessionId, timeSpanId) => {
  const response =
    await Api.get(`/organizations/${organizationId}/sessions/${sessionId}/time-span/${timeSpanId}/dashboard/summary`);
  return response;
};

export const changeSessionRecordingStatus = (organizationId, sessionId, timeSpanId) => async (payload) => {
  const response =
    await Api.post(`/organizations/${organizationId}/sessions/${sessionId}/time-span/${timeSpanId}/recording`,payload);
  return response;
};

export const deleteOrganizationGroupSession = (organizationId,classId,sessionId,timeSpanId) => async () => {
  // eslint-disable-next-line max-len
  const response = await Api.delete(`/organizations/${organizationId}/groups/${classId}/sessions/${sessionId}/time-span/${timeSpanId}`);
  return response;
};

export const getSessionTags = async () => {
  const response = await Api.get('/sessions/teacher-tags');
  return response;
};

export const getSessionInfo = async (organizationId,groupId,sessionId) => {
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/sessions/${sessionId}`);
  return response;
};

export const getSessionResources = async (organizationId,sessionId) => {
  const response = await Api.get(`/organizations/${organizationId}/sessions/${sessionId}/resources`);
  return response;
};

/**
 * 取得學制、科目
 * @param {string} organizationId 
 * @returns 
 * 
 * Swagger url:
 * https://asia-east1-live-oneclass-dev.cloudfunctions.net/organizations/docs/#/Organization%20Administration%20-%20Sessions/get__organizationId__sessions_options
 */
export const getSessionOptions = async (organizationId) => {
  const response = await Api.get(`/organizations/${organizationId}/sessions/options`);
  return response;
};

/**
 * 看課管理 - 家教資料 
 * @param {string} organizationId
 * @param {integer} skip
 * @param {integer} limit
 *
 * @return {object} Live-OneClass sessions list
 * 
 * https://asia-east1-live-oneclass-dev.cloudfunctions.net/organizations/docs/#/
 * Organization%20Administration%20-%20Sessions/get__organizationId__sessions_watching
 */
export const getSessionsWatching = async (organizationId, skip, limit) => {
  const queryString = stringify({ skip, limit });
  const response = await Api.get(`/organizations/${organizationId}/sessions-watching?${queryString}`);
  return response;
};

/**
 * 取得課堂報告
 * @param {string} organizationId 
 * @param {string} sessionId 
 * @param {string} timeSpanId 
 * @param {string} section
 * 
 * Swagger url:
 * https://asia-east1-live-oneclass-dev.cloudfunctions.net/organizations/docs/#/Organization%20Administration%20-%20Session%20Report/get__organizationId__sessions__sessionId__time_span__timeSpanId__reports__section_ 
 */
export const getSessionReport = async (organizationId, sessionId, timeSpanId, section) => {
  const response = await Api.get(`/organizations/${organizationId}/sessions/${sessionId}/time-span/${timeSpanId}/reports/${section}`);
  return response;
};

/**
 * 看課管理 - OneBoard資料
 * @param {object} payload
 *
 * @return {object} users OneBoard data
 * 
 * https://asia-east1-live-oneclass-dev.cloudfunctions.net/organizations/docs/#/
 * Organization%20Administration%20-%20Sessions/post_sessions_watching_records
 */
export const getSessionsWatchingRecords = async (payload) => {
  const response = await Api.post('​/organizations/sessions-watching-records', payload);
  return response;
};