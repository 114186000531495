/* eslint-disable max-len */
import { getYear, getMonth, getUnixTime } from 'date-fns';

export const useDateInterval = () => {
  const earliestTime = '2018/01/01';
  const latestTime = '2050/12/31';
  //獲取今日 00:00:00
  const today = new Date().toLocaleDateString('zh-TW');
  const nowTimeStamp = getUnixTime(new Date(new Date().toGMTString())) * 1000;
  //獲取今日 23:59:59
  const todayLastTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
  const startAtTimeStamp = getUnixTime(new Date(today)) * 1000;
  const endAtTimeStamp = getUnixTime(new Date(todayLastTime)) * 1000;
  const todayInterval = () => {
    return {
      startAt: startAtTimeStamp,
      endAt: endAtTimeStamp
    };
  };
  const processingInterval = () => {
    return {
      startAt: getUnixTime(new Date()) * 1000,
      endAt: getUnixTime(new Date()) * 1000
    };
  };
  const pastInterval = () => {
    return {
      startAt: null,
      endAt: (getUnixTime(new Date(today)) - 1) * 1000
    };
  };
  const yesterdayInterval = () => {
    return {
      startAt: (getUnixTime(new Date(today)) - 86400) * 1000,
      endAt: (getUnixTime(new Date(today)) - 1) * 1000
    };
  };
  /* 上個月 */
  const prevMonthInterval = () => {
    const year = getYear(new Date());
    const month = getMonth(new Date()) - 1;
    return {
      startAt: new Date(year, month, 1).getTime(),
      endAt: new Date(year, month + 1, 0, 23, 59, 59).getTime(),
    };
  };
  /* 下個月 */
  const nextMonthInterval = () => {
    const year = getYear(new Date());
    const month = getMonth(new Date()) + 1;
    return {
      startAt: new Date(year, month, 1).getTime(),
      endAt: new Date(year, month + 1, 0, 23, 59, 59).getTime(),
    };
  };
  /* 未來 */
  const futureInterval = () => {
    return {
      startAt: Date.now(),
      endAt: new Date(latestTime).getTime(),
    };
  };
  /* 所有 */
  const allInterval = () => {
    return {
      startAt: new Date(earliestTime).getTime(),
      endAt: new Date(latestTime).getTime(),
    };
  };

  return [
    { nowTimeStamp, startAtTimeStamp, endAtTimeStamp },
    { todayInterval, processingInterval, pastInterval, prevMonthInterval, nextMonthInterval, futureInterval, allInterval,yesterdayInterval }
  ];
};


