import { useSelector, useDispatch } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import {
  getTeachers as getTeachersApi,
  createTeachInfo as createTeachInfoApi,
  updateTeachInfo as updateTeachInfoApi,
  removeGroupHostCandidate as removeGroupHostCandidateApi,
  getOrganizationStaffs as getOrganizationStaffsApi,
  inviteTeachersToOrganizationGroup as inviteTeachersToOrganizationGroupApi,
} from 'services/api/organization/teacher';
import { convertArrayToMap } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { ERROR_CODE } from 'errorCode/index';

const GET_TEACHERS = 'GET_TEACHERS';
const GET_ALL_TEACHERS = 'GET_ALL_TEACHERS';
const CREATE_TEACHERS = 'CREATE_TEACHERS';
const UPDATE_TEACHERS = 'UPDATE_TEACHERS';
const REMOVE_GROUP_TECHER = 'REMOVE_GROUP_TECHER';
const INVITE_TEACHER_TO_ORGANIZATION_GROUP = 'INVITE_TEACHER_TO_ORGANIZATION_GROUP';
const CLEAR_TECHERS = 'CLEAR_TECHERS';
const GET_ORGANIZATION_STAFFS = 'GET_ORGANIZATION_STAFFS';
const initState = {
  teachers: {
    data: [],
    dataMaps: null,
    total: 0
  },
  allTeachers: {
    data: [],
    total: 0
  },
  getOrganizationStaffs: {
    data: [],
    total: 0
  }
};

const actions = {
  getTeachers: ({ data, dataMaps, total }) => ({
    type: GET_TEACHERS,
    payload: { data, dataMaps, total },
  }),
  getAllTeachers: ({ data, total }) => ({
    type: GET_ALL_TEACHERS,
    payload: { data, total },
  }),
  getOrganizationStaffs: ({ data, total }) => ({
    type: GET_ORGANIZATION_STAFFS,
    payload: { data, total },
  }),
  createTeachers: () => ({
    type: CREATE_TEACHERS
  }),
  updateTeachers: () => ({
    type: UPDATE_TEACHERS
  }),
  inviteTeachers:(hosts) => ({
    type: INVITE_TEACHER_TO_ORGANIZATION_GROUP,
    payload: hosts
  }),
  removeGroupTeacher: () => ({
    type: REMOVE_GROUP_TECHER
  }),
  clearTeachers: () => ({
    type: CLEAR_TECHERS
  }),
};

export const useTeacher = () => {
  const { organizationId, classId, teacherId } = useParams();
  const { setAlert } = useAlert();
  const isHome = Boolean(useRouteMatch({ path: '/home' }));
  const dispatch = useDispatch();
  const { teachers, allTeachers, organizationStaffs } = useSelector(state => state.teacher);

  const getTeachers = async params => {
    const { isSuccess, data } = await getTeachersApi(isHome)(organizationId, classId)(params);
    if (!isSuccess) return;
    const { userProfiles, total } = data;
    const teachersMap = convertArrayToMap(userProfiles, 'id');
    dispatch(actions.getTeachers({ data: userProfiles, dataMaps: teachersMap, total }));
    return data;
  };

  const getAllTeachers = async params => {
    const { isSuccess, data } = await getTeachersApi(isHome)(organizationId, classId)({ ...params, rowsPage: 100 });
    if (!isSuccess) return;
    const { userProfiles, total } = data;
    dispatch(actions.getAllTeachers({ data: userProfiles, total }));
    return userProfiles;
  };

  const getOrganizationStaffs = async params => {
    const { isSuccess, data } = await getOrganizationStaffsApi(isHome)(organizationId, classId)({
      ...params,
      rowsPage: 100
    });
    if (!isSuccess) return;
    const { userProfiles, total } = data;
    dispatch(actions.getOrganizationStaffs({ data: userProfiles, total }));
    return data;
  };

  const createTeachers = async params => {
    try {
      const { isSuccess, data, error } = await createTeachInfoApi(organizationId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ERROR_CODE[errorCode] || '加入失敗', 'error', errorCode);
    }
  };

  const updateTeachers = async params => {
    try {
      const { isSuccess, data, error } = await updateTeachInfoApi(organizationId, teacherId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  const inviteTeachersToOrganizationGroup = async params => {
    const { isSuccess, error } = await inviteTeachersToOrganizationGroupApi(organizationId,classId)(params);
    let userError = '';
    dispatch(actions.inviteTeachers(params));

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.INVITE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.INVITE_FAIL, 'error', errorCode);
    }

    return { isSuccess, userError, error };
  };

  const removeGroupTeacher = async id => {//移出無組織教師
    dispatch(actions.removeGroupTeacher({ classId, userId: id }));
    try {
      const { isSuccess, data, error } = await removeGroupHostCandidateApi(classId)(id);
      if (!isSuccess) return error;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_ERROR, 'error', errorCode);
      return null;
    }
  };
  const clearTeachers = () => dispatch(actions.clearTeachers());

  return [
    { teachers, allTeachers, organizationStaffs }, // state
    {
      getAllTeachers,
      getTeachers,
      getOrganizationStaffs,
      createTeachers,
      updateTeachers,
      removeGroupTeacher,
      clearTeachers,
      inviteTeachersToOrganizationGroup,
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TEACHERS: {
      const { data, dataMaps, total } = action.payload;
      return {
        ...state,
        teachers: {
          data,
          dataMaps,
          total
        }
      };
    }
    case GET_ALL_TEACHERS: {
      const { data, total } = action.payload;
      return {
        ...state,
        allTeachers: {
          data,
          total
        }
      };
    }
    case GET_ORGANIZATION_STAFFS: {
      const { data, total } = action.payload;
      return {
        ...state,
        organizationStaffs: {
          data,
          total
        }
      };
    }
    case CLEAR_TECHERS: {
      return {
        ...state,
        teachers: {
          ...initState.teachers
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
