import React from 'react';
import { UiBsContentContainer } from 'styles';
import {
  BsHeaderTitle,
  CustomerInvitingTable
 } from 'components';


/**
 * 學生邀請列表
 */

export const CustomerInvitingPage = () => {
  return (
    <>
      <BsHeaderTitle title="學生管理" />
      {/* <BsCustomerManagementPageHeaderTab activeIndex={1} /> */}
      <UiBsContentContainer>
        <CustomerInvitingTable />
      </UiBsContentContainer>
    </>
  );
};



