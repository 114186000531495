import styled from 'styled-components';

export const UiExamTable = styled.div`
	display: block;

	button {
		margin: 0 10px;
	}
`;

export const UiExamIcons = styled.div`
	display: flex;

	> svg {
		font-size: 2rem;
		cursor: pointer;
	}
`;
