import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  InsertDriveFile,
  FindInPage,
  Delete,
  CloudUpload,
  Add,
  PhoneInTalk,
  Email,
  SettingsRemote,
  AttachFile,
  Build,
  MoreHoriz,
  ExitToApp,
  AccountCircle,
  VerticalAlignTop,
  Edit,
  Home,
  Refresh,
  Image,
  Copyright,
  Announcement,
  Message,
  PhotoAlbum,
  ImportContacts,
  HomeWork,
  Category,
  Textsms,
  People,
  Drafts,
  Settings,
  ThumbUp,
  Share,
  School,
  Person,
  ViewList,
  SettingsInputComponent,
  Backup,
  Visibility,
  VisibilityOff,
  Poll,
  Info,
  Tune,
  ChevronLeft,
  ChevronRight,
  ArtTrack,
  GetApp,
  LocalOffer,
  Class,
  Videocam,
  Close,
  HowToReg,
  PersonPin,
  RecordVoiceOver,
  TagFaces,
  SupervisedUserCircle,
  CheckBox,
  AccessAlarm,
  CheckBoxOutlineBlank,
  PhoneAndroid,
  VpnKey,
  History,
  CalendarToday,
  Chat,
  AssignmentTurnedIn,
  Forum,
  AddBox,
  Error,
  ErrorOutline,
  PermDeviceInformation,
  DeveloperBoard,
  RemoveRedEye,
  Done,
  EmojiFlags,
  EventAvailable,
  EmojiSymbols,
  ListAlt,
  Stars,
  ThumbUpAltOutlined,
  FileCopy,
  Assessment,
  MoreVert,
  Reply,
  ReportProblemOutlined,
  CheckCircle,
  SpeakerNotes,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { Tooltip as MatTooltip } from '@material-ui/core';
import { UiIcon } from './Icon.style';

/**
 * Icon統整 要新增icon 請自己新增
 */

const materialUiIconMap = {
  file: InsertDriveFile,
  watchFile: FindInPage,
  emojiFlags: EmojiFlags,
  delete: Delete,
  upload: CloudUpload,
  add: Add,
  phone: PhoneInTalk,
  email: Email,
  settingRemote: SettingsRemote,
  attachFile: AttachFile,
  build: Build,
  moreHoriz: MoreHoriz,
  exitToApp: ExitToApp,
  account: AccountCircle,
  verticalAlignTop: VerticalAlignTop,
  edit: Edit,
  home: Home,
  refresh: Refresh,
  image: Image,
  copyright: Copyright,
  announcement: Announcement,
  message: Message,
  photoAlbum: PhotoAlbum,
  importContacts: ImportContacts,
  homeWork: HomeWork,
  category: Category,
  textsms: Textsms,
  people: People,
  drafts: Drafts,
  settings: Settings,
  thumbUp: ThumbUp,
  share: Share,
  school: School,
  person: Person,
  viewList: ViewList,
  settingsInputComponent: SettingsInputComponent,
  backup: Backup,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  removeRedEye: RemoveRedEye,
  poll: Poll,
  info: Info,
  tune: Tune,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  artTrack: ArtTrack,
  getApp: GetApp,
  localOffer: LocalOffer,
  classIcon: Class,
  videocam: Videocam,
  close: Close,
  howToReg: HowToReg,
  personPin: PersonPin,
  recordVoiceOver: RecordVoiceOver,
  tagFaces: TagFaces,
  supervisedUserCircle: SupervisedUserCircle,
  checkBox: CheckBox,
  accessAlarm: AccessAlarm,
  checkBoxOutlineBlank: CheckBoxOutlineBlank,
  phoneAndroid: PhoneAndroid,
  done: Done,
  vpnKey: VpnKey,
  history: History,
  calendarToday: CalendarToday,
  chat: Chat,
  assignmentTurnedIn: AssignmentTurnedIn,
  forum: Forum,
  addBox: AddBox,
  error: Error,
  errorOutline: ErrorOutline,
  permDeviceInformation: PermDeviceInformation,
  developerBoard: DeveloperBoard,
  eventAvailable: EventAvailable,
  emojiSymbols: EmojiSymbols,
  listAlt: ListAlt,
  star: Stars,
  thumbUpAltOutlined:ThumbUpAltOutlined,
  fileCopy: FileCopy,
  assessment: Assessment,
  moreVert: MoreVert,
  reply: Reply,
  reportProblemOutlined: ReportProblemOutlined,
  checkCircle: CheckCircle,
  speakerNotes: SpeakerNotes,
};

const useStyles = makeStyles({
	cursor: {
		cursor: 'pointer',
	}
});

// eslint-disable-next-line react/display-name
export const Icon = forwardRef(({
  title,
  name,
  onClick,
  size,
  className,
  type = 'normal',
  haveBg = true,
  icolor = '#fff',
},ref) => {
  const classes = useStyles();
  return (
    title ? <MatTooltip title={title}>
      <UiIcon
        ref={ref}
        className={
          onClick ?
            classes.cursor + ' ' + className :
            className}
        component={materialUiIconMap[name]}
        onClick={onClick}
        fontSize={size}
        type={type}
        haveBg={haveBg}
        icolor={icolor}
      />
    </MatTooltip>
      : <UiIcon
        ref={ref}
        className={
          onClick ?
            classes.cursor + ' ' + className :
            className}
        component={materialUiIconMap[name]}
        onClick={onClick}
        fontSize={size}
        type={type}
        haveBg={haveBg}
        icolor={icolor}
      />
  );
});



Icon.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  haveBg: PropTypes.bool,
  icolor: PropTypes.string,
};


