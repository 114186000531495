import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, useRouteMatch, } from 'react-router-dom';
import { format } from 'date-fns';

import { EDIT_STATUS } from 'constants/index';
import { Table, IconInput, Select, Button, DatePicker, Icon, BsModal, } from 'components';
import { getCustomers as getCustomersApi } from 'services/api/organization/customer';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';

import { UiTable, UiActionBox, UiflexBox, UiTableButtonBox, UiEmphasize } from 'styles';


/**
 * 班級列表
 */
const schema = {
  name: {
    name: '班級名稱',
    defaultValue: ''
  },
  createDate: {
    name: '建班時間',
    defaultValue: ''
  },
  expirationTime: {
    name: '有效時間',
    defaultValue: ''
  },
  isExpired: {
    name: '班級狀態',
    defaultValue: ''
  },
  ownerName: {
    name: '導師',
    defaultValue: ''
  }
};

const selectInputOptions = [
  {
    name: '搜尋導師暱稱',
    value: 'ownerName'
  },
  {
    name: '搜尋導師手機號碼',
    value: 'ownerMobileNumber'
  }
];

const expiredOptions = [
  {
    name: '全部',
    value: 'all'
  },
  {
    name: '未到期',
    value: 'false'
  },
  {
    name: '已到期',
    value: 'true'
  }
];

const expiredShowText = [
  {
    id: 'isExpired',
    state: {
      false: '未到期',
      true: '已到期',
    }
  }
];

export const ClassTable = () => {
  const { setAlert } = useAlert();
  const history = useHistory();
  const isHome = Boolean(useRouteMatch({ path: '/home'}));
  const { organizationId } = useParams();
  const [{ classes }, { getClasses, removeClassInfo, }] = useClass();
  const { data: classData, total: classTotalPage } = classes;
  const [
    { goal,
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
      isOpenDeleteFailModal,
      isOpenDeleteSuccessModal,
      actionClassId,
      className,
    }, setState] = useSetState({
      goal: '',
      nowPage: 0,
      rowsPage: 10,
      name: '',
      ownerName: '',
      ownerMobileNumber: '',
      createdAfter: null,
      status: '',
      expired: '',
      isOpenDeleteFailModal: false,
      isOpenDeleteSuccessModal: false,
      actionClassId: null,
      className: null,
    });

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const dateHandler = value => {
    setState({
      createdAfter: value,
      nowPage: 0
    });
  };

  // table 操作
  const ActionComponents = ({ params: { id, name: className, } }) => {
    const goPage = editStatus => () => history.push(`/organization/${organizationId}/class/${id}/${editStatus}`);

    // click 刪除班級按鈕
    const clickDeleteClassHandler = async () => {
      const { isSuccess, data, error } = await getCustomersApi(isHome)(organizationId, id)({});
      if(isSuccess){
        const total = data.total;
        total > 0
          ? setState({ isOpenDeleteFailModal: true, })
          : setState({ isOpenDeleteSuccessModal: true, actionClassId: id, className, });
      }else{
        setAlert(error.description, 'error');
      }
    };

    return (
      <UiTableButtonBox>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/session`)}
        >
          課程管理
        </Button>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/customer`)}
        >
          成員管理
        </Button>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/exam`)}
        >
          試卷管理
        </Button>
        <Icon name="edit" title="編輯班級" onClick={goPage(EDIT_STATUS.EDIT)} />
        <Icon
          name="delete"
          title="刪除班級"
          type='danger'
          onClick={clickDeleteClassHandler}
        />
      </UiTableButtonBox>
    );
  };
  ActionComponents.propTypes = {
    params: {
      id: PropTypes.string,
      name: PropTypes.string,
    }
  };

  const fetchGetClasses = async () => {
    await getClasses({
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
    });
  };

  const onSubmitHandler = () => {
    if(!name) return;
    fetchGetClasses();
  };

  const onSearchChange = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'wranning');
        return;
      } else {
        setState({
          ownerName: '',
          ownerMobileNumber: '',
          [goal]: value === 'all' ? '' : value,
          nowPage: 0
        });
      }
    }
    setState({
      [key]: value === 'all' ? '' : value,
      nowPage: 0
    });
  };

  const setIsOpenDeleteFailModalState = state => {
    setState({ isOpenDeleteFailModal: state });
  };

  /* click 刪除成功彈窗 確認按鈕 */
  const clickOkOfDeleteSuccessModal = async () => {
    await removeClassInfo(actionClassId);
    await fetchGetClasses();
    setState({ isOpenDeleteSuccessModal: false });
  };

  useEffect(() => {
    fetchGetClasses();
  }, [nowPage, rowsPage, ownerName, ownerMobileNumber, createdAfter, status, expired]);

  return (
    <>
      {/* 禁止刪除班級 彈窗 */}
      <BsModal
        title='刪除班級'
        cancelDisplay={false}
        isFull={true}
        onCancel={()=>setIsOpenDeleteFailModalState(false)}
        onOk={()=>setIsOpenDeleteFailModalState(false)}
        open={isOpenDeleteFailModal}
      >
        班級內尚有學生，無法刪除班級
      </BsModal>

      {/* 準備刪除班級 彈窗 */}
      <BsModal
        title='刪除班級'
        isFull={true}
        onCancel={()=>setState({ isOpenDeleteSuccessModal: false })}
        onOk={clickOkOfDeleteSuccessModal}
        open={isOpenDeleteSuccessModal}
      >
        您確定要將 <UiEmphasize>{className}</UiEmphasize> 刪除嗎?
      </BsModal>

      <UiTable>
        <UiActionBox>
          <UiflexBox>
            <IconInput
              placeholder='搜尋班級名稱'
              onChange={value => onSearchChange(value, 'name')}
              onClick={onSubmitHandler}
            />
            <Select
              label="班級狀態"
              options={expiredOptions}
              submitHandler={value => onSearchChange(value, 'expired')}
            />
            <DatePicker
              label="搜尋建班時間"
              value={createdAfter}
              onChange={dateHandler}
            />
            <Select
              label="搜尋目標"
              options={selectInputOptions}
              submitHandler={value => onSearchChange(value, 'goal')}
            />
            {
              goal && <IconInput
                placeholder='搜尋條件'
                onChange={value => onSearchChange(value, 'condition')}
              />
            }
          </UiflexBox>
          <UiflexBox>
            <Button
              buttonColor='highlight'
              icon='add'
              onClick={() => history.push(`/organization/${organizationId}/class/create`)}>新增班級</Button>
          </UiflexBox>
        </UiActionBox>
        <Table
          data={
            classData.map(item => {
              item.createDate = format(new Date(item.createdAt), 'yyyy-MM-dd');
              item.expirationTime = format(new Date(item.expirationTime), 'yyyy-MM-dd');
              return item;
            })
          }
          schema={schema}
          stateShowText={expiredShowText}
          changePage_Rows={changePage_Rows}
          totalPage={classTotalPage}
          ActionComponents={ActionComponents}
          nowPage={nowPage}
        />
      </UiTable>
    </>
  );
};



