import React, { useEffect } from 'react';
import { EDIT_STATUS } from 'constants/index';
import { useParams, useHistory } from 'react-router-dom';
import {
  Table,
  Button,
  IconInput,
  Select,
  TransferListModal,
  Modal,
  Icon,
  Breadcrumbs
} from 'components';
import { Box } from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useCustomer } from 'store/customer';
import { useClass } from 'store/class';
import { UiAvatar } from './CustomerTable.style';

/**
 * 學生列表Table
 */
const schema = {
  nickname: {
    name: '機構暱稱',
    defaultValue: '',
  },
  originalName:{
    name:'姓名',
    defaultValue: '',
  },
  originalNickname: {
    name: '暱稱',
    defaultValue: '無'
  },
  avatar: {
    name: '照片',
    defaultValue: ''
  },
  id:{
    name: '帳號ID',
    defaultValue: ''
  },
  mobileNumber: {
    name: '聯絡電話',
    defaultValue: '無'
  },
  email: {
    name: '信箱',
    defaultValue: '無'
  },
};

const stateShowText = [
  {
    id: 'status',
    state: {
      invited: '邀請中(等待同意)',
      rejected: '拒絕邀請',
      joined: '已加入',
      departed: '已離開'
    }
  }
];

const selectOptions = [
  {
    name: '帳號',
    value: 'customerId',
  },
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '機構暱稱',
    value: 'nickname'
  }
];

const selectModalOptions = [
  {
    name: '手機號碼',
    value: 'mobileNumber'
  },
  {
    name: '機構暱稱',
    value: 'nickname'
  },
  {
    name: '帳號ID',
    value: 'customerId'
  }
];

export const CustomerTable = () => {
  const { organizationId, classId } = useParams();
  const history = useHistory();
  const [
    { customers, subtractionCustomers },
    { getCustomers, getSubtractionCustomers, getFilterCustomers, addUserToGroup, removeUserToGroup }] = useCustomer();
  const [{ classes }] = useClass();
  const [{
    nowPage,
    rowsPage,
    searchType,
    searchValue,
    modalSearchType,
    // modalSearchValue,
    customerData,
    // customerTotal,
    customerNowPage,
    // customerRowsPage,
    placeholder,
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    searchType: '',
    searchValue: '',
    modalSearchType: '',
    // modalSearchValue: '',
    customerData: [],
    // customerTotal: 0,
    customerNowPage: 0,
    // customerRowsPage: 10,
    placeholder: '搜尋條件'
  });

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: classes.dataInfo && `${classes.dataInfo?.name || ''} 學生管理`,
      link: `/organization/${organizationId}/class/${classId}/customer`,
      icon: 'people'
    }
  ];


  const goCreateSession = () => {
    history.push(`/organization/${organizationId}/customer/${EDIT_STATUS.CREATE}`);
  };
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const submitSelectHandler = value => {
    setState({
      searchType: value,
      nowPage: 0
    });
  };

  const onSubmitHandler = (value, key) => {
    if (searchType === '') return;

    setState({
      nowPage: 0,
    });

    const params = {
      [searchType]: value,
      nowPage: 0
    };

    getCustomers(params);
  };

  const transferDataFormat = arr => {
    arr.forEach(item => {
      item.imagePath = '';
      item.title = item.nickname;
      item.content = item.email;
    });
    return arr;
  };

  const getTrnasferListValue = async params => {
    const userIdList = params.map(item => item.id);
    const isSuccess = await addUserToGroup({ userIdList, userProfiles: params });
    if (isSuccess) {
      const payload = {
        nowPage,
        rowsPage,
      };
      if(searchType && searchValue) payload[searchType] = searchValue;
      await getCustomers(payload);
    }
  };

  const onTransferListSearchHandle = (value, key) => {
    if (key === 'searchValue') {
      if (modalSearchType === '') return;
      getFilterCustomers({ organizationId, [modalSearchType]:value, rowsPage:100 });
      setState({modalSearchValue: value});
    }else if(key === 'searchType'){
      setState({modalSearchType : value});
    }
  };

  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params }) => {
    const [{ isOpen }, setModal] = useSetState({
      isOpen: false
    });
    const userProfiles = params;
    const buttons = [
      {
        text: '確認',
        func: async () => {
          const isSuccess = await removeUserToGroup({ userProfiles });
          if (isSuccess) {
            const payload = {
              nowPage,
              rowsPage,
            };
            if(searchType && searchValue) payload[searchType] = searchValue;
            await getCustomers(payload);
          }
        }
      },
      {
        text: '取消',
        color: 'highlight',
      }
    ];
    const remove = () => {
      setModal({
        isOpen: true
      });
    };
    const getModalStateHandler = state => {
      setModal({
        isOpen: state
      });
    };
    const goEditCustomer = () => {
      history.push(`/organization/${organizationId}/customer/${userProfiles.id}/edit`);
    };
    return (
      <Box display="flex">
       <Icon.Svg
        name="Edit"
        color="#fff"
        onClick={goEditCustomer}
        title="編輯"
        circleBg={true}
       />
       <Icon.Svg
        name="UserMinus"
        color="#fff"
        onClick={remove}
        title="移出班級"
        circleBg={true}
       />
        <Modal
          text="此操作將會將此學生從班級移除"
          isOpen={isOpen}
          getModalState={getModalStateHandler}
          buttons={buttons}
        />
      </Box>
    );
  };

  // eslint-disable-next-line react/prop-types
  const orgCustomersActionComponents = ({ params: { id } }) => {
    const goPage = editStatus => () => history.push(`/organization/${organizationId}/customer/${id}/${editStatus}`);

    return (
      <>
        <Icon name="watchFile" onClick={goPage(EDIT_STATUS.READ)} />
        <Icon name="edit" onClick={goPage(EDIT_STATUS.EDIT)} />
      </>
    );
  };

  // const onScrollToApi = isCallApi => {
  //   if(!isCallApi) return;
  //   const params = {
  //     nowPage: customerNowPage,
  //     rowsPage: customerRowsPage,
  //   };
  //   getFilterCustomers({ organizationId, [modalSearchType]:modalSearchValue, params });
  // };


  useEffect(() => {
    if (!classId) return;
    getSubtractionCustomers({ organizationId, classId });
  }, []);

  useEffect(() => {
    const params = {
      nowPage,
      rowsPage,
    };
    if(searchType && searchValue) params[searchType] = searchValue;
    getCustomers(params);
  }, [nowPage, rowsPage]);

  useEffect(() => {
    if(subtractionCustomers.data.length > 1){
      const newCustomerData = customerData.concat(subtractionCustomers.data);
      setState({
        customerData: newCustomerData,
        customerTotal: customers.total,
        customerNowPage: customerNowPage + 1,
      });
    }else{
      setState({
        customerData: subtractionCustomers.data,
        customerTotal: customers.total,
        customerNowPage: customerNowPage + 1,
      });
    }
  }, [subtractionCustomers]);

  useEffect(() => {
    if (searchType === 'mobileNumber') {
      setState({
        placeholder: '請輸入完整手機號碼',
      });
    } else {
      setState({
        placeholder: '搜尋條件',
      });
    }
  }, [searchType]);

  return (
    <UiTable>
      {
        classId && 
          <UiActionBox>
            <Breadcrumbs list={BreadcrumbsList} />
          </UiActionBox>
      }
      <UiActionBox>
        <UiflexBox>
          <Select 
            label="搜尋條件" 
            options={selectOptions} 
            submitHandler={submitSelectHandler} 
          />
          <IconInput
            placeholder={placeholder}
            value={searchValue}
            onClick={value => onSubmitHandler(value, 'searchValue')}
          />
        </UiflexBox>
        {
          classId ?
            <UiflexBox>
              <Button
                buttonColor='info'
                icon='exitToApp'
                onClick={() => history.goBack()}
              >
                回到上一頁
              </Button>
              <TransferListModal
                name='ownerId'
                type="multipleChoice"
                title="選擇學生"
                // data={transferDataFormat(customerData) || []}  // 配合 onScrollToApi 用
                data={transferDataFormat(subtractionCustomers.data) || []}
                onChange={getTrnasferListValue}
                isShowSearch
                selectOptions={selectModalOptions}
                onSearchChange={onTransferListSearchHandle}
                // dataTotal={customerTotal}  // 配合 onScrollToApi 用
                // onScrollToApi={onScrollToApi}
              />
            </UiflexBox> :
            <Button
              buttonColor='highlight'
              icon='add'
              onClick={() => goCreateSession()}>邀請學生</Button>
        }
      </UiActionBox>
      <Table
        data={
          customers.data.map(item =>
            ({ ...item, avatar: <UiAvatar alt={item.nickname} src={item.thumbnailUrl} /> }))
        }
        schema={schema}
        stateShowText={stateShowText}
        changePage_Rows={changePage_Rows}
        totalPage={customers.total}
        ActionComponents={classId ? ActionComponents : orgCustomersActionComponents}
        nowPage={nowPage}
      />
    </UiTable>);
};
