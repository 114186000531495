import styled from 'styled-components';

export const UiNumberInput = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;

	> div {
		width: 85%;
	}
`;

export const UiNumberText = styled.div`
	text-align: right;
	color: #8b90a0;
	line-height: 18px;
`;