import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const ALERT_MESSAGE = {
  CANCEL_SUCCESS: 'alert.cancel_success',
  CANCEL_FAIL: 'alert.cancel_fail',
  CREATE_SUCCESS: 'alert.create_success',
  CREATE_FAIL: 'alert.create_fail',
  CREATED_TEACHER: 'alert.created_teacher',
  UPDATE_SUCCESS: 'alert.update_success',
  UPDATE_FAIL: 'alert.update_fail',
  DELETE_SUCCESS: 'alert.delete_success',
  DELETE_FAIL: 'alert.delete_fail',
  INVITE_SUCCESS: 'alert.invite_success',
  INVITE_FAIL: 'alert.invite_fail',
  JOIN_GROUP_SUCCESS: 'alert.join_group_success',
  JOIN_GROUP_FAIL: 'alert.join_group_fail',
  CANCEL_INVITE_SUCCESS: 'alert.cancel_invite_success',
  CANCEL_INVITE_FAIL: 'alert.cancelinvite_fail',
  INVITED_CUSTOMER: 'alert.invited_customer',
  INVITED_CODE_SUCCESS: 'alert.invited_code_success',
  INVITED_CODE_FAIL: 'alert.invited_code_fail',
  CUSTOMER_ALREADY_CLASS: 'alert.customer_already_class',
  TEACHER_ALREADY_CLASS: 'alert.teacher_already_class',
  TEACHER_ALREADY_CLASSES: 'alert.teacher_already_classes',
  FILENAME_ERROR: 'alert.fileName_error',
  GET_SCHOOL_FAIL: 'alert.get_school_fail',
  OPEN_PERMISSIONS_SUCCESS: 'alert.open_permissions_success',
  OPEN_PERMISSIONS_FAIL: 'alert.open_permissions_fail',
  ATTENDANCE_SUCCESS:'alert.attendance_success',
  ATTENDANCE_FAIL:'alert.attendance_fail',
};

export const useAlert = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const setAlert = (message, type, statusCode) => {
    const msg = statusCode ? `[${statusCode}] ${t(message)}` : t(message);
    enqueueSnackbar(msg, { variant: type });
  };

  return { setAlert };
};
