import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'components';
import { format } from 'date-fns';
import {
  UiSessionPerformance,
  UiTitleWrapper,
  UiTableWrapper,
} from './SessionPerformance.style';

/**
 * 學生課堂表現
 */

const schema = {
  attendeeNumber:{
    name: '座號',
    defaultValue: '-',
  },
  nickname:{
    name: '姓名',
    defaultValue: '-',
  },
  trophy:{
    name: '總獎盃數',
    defaultValue: '-',
  },
  raiseHand:{
    name: '舉手次數',
    defaultValue: '-',
  },
  // speakerSecond:{
  //   name: '發言秒數',
  //   defaultValue: '-',
  // },
  inattentive:{
    name: '不專心次數',
    defaultValue: '-',
  },
};

/* 假資料 */
// const data = [{
//   attendeeNumber: 1,
//   nickname: '莫札特',
//   trophy: 90,
//   raiseHand: 6,
//   speakerSecond: '01:20',
//   inattentive: 2,
// }];

export const SessionPerformance = (props) => {
  const { userRecords, userRecordAverages, users } = props?.data;

  /* 學生所有紀錄 */
  const studentRecords = (() => {
    const zip = users.map((user, index) => {
      return {...user, ...userRecords[index]};
    });

    const records = zip.map(value => {
      const { attendeeNumber, inattentive, nickname, raiseHand, speakerSecond, status, trophy } = value;

      const obj = status  === 'missed' 
        ? {
            trophy: '-',
            raiseHand: '-',
            speakerSecond: '-',
            inattentive: '-'
          }
        : {
            trophy,
            raiseHand,
            speakerSecond: format(speakerSecond, 'mm:ss'),
            inattentive
          };

      return {
        attendeeNumber,
        nickname,
        ...obj
      };
    });

    return records;
  });

  /* 學生平均紀錄 */
  const averageRecords = (() => {
    const { 
      attendeeNumber = '-', 
      inattentive, 
      nickname = '班級平均', 
      raiseHand, 
      speakerSecond, 
      trophy
    } = userRecordAverages;

    return {
      attendeeNumber,
      nickname,
      trophy,
      raiseHand,
      speakerSecond: speakerSecond ? format(speakerSecond, 'mm:ss') : '-',
      inattentive
    };
  });

  const tableData = useMemo(() => {
    return [...studentRecords(), averageRecords()];
  }, [userRecords, userRecordAverages, users]);

  return (
    <UiSessionPerformance>
      <UiTitleWrapper>學生課堂表現</UiTitleWrapper>

      <UiTableWrapper>
        <Table
          schema={schema}
          data={tableData}
          isNeedPagination={false}
        />
      </UiTableWrapper>
    </UiSessionPerformance>
  );
};

SessionPerformance.propTypes = {
  data: PropTypes.shape({
    userRecords: PropTypes.object,
    userRecordAverage: PropTypes.object,
    users: PropTypes.object,
  }),
};
