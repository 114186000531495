import styled from 'styled-components';

export const UiBsLayout = styled.div`
	display: flex;
	max-width: 100vw;
	min-height: 100vh;
`;

export const UiContent = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ drawerWidth }) => `calc(100vw - ${drawerWidth}px) `};

	${({ theme: { media }}) => media.phone`
		width: 100%;
	`}
`;

export const UiDrawer = styled.nav`
	flex-shrink: 0;
	width: ${({ width }) => `${width}px`};

	${({ theme: { media } }) => media.phone`
		width: auto;
	`}
`;

