import styled from 'styled-components';
import {
  Paper as MatPaper,
  Table as MatTable,
  TableBody as MatTableBody,
  TableCell as MatTableCell,
  TableContainer as MatTableContainer,
  TablePagination as MatTablePagination,
  TableHead as MatTableHead,
  TableRow as MatTableRow
} from '@material-ui/core';

export const UiPaper = styled(MatPaper)`
	display: flex;
	flex-direction: column;
	flex: 1;
`;
export const UiTable = styled(MatTable)`
	min-width: 700px;
	white-space: ${({ whiteSpace }) => whiteSpace };
`;
export const UiTableBody = styled(MatTableBody)``;
export const UiTableCell = styled(MatTableCell)`
color:
	${(
		{ theme,
		isimportant }
	) => isimportant === 'true' ? `${theme.table.home.tdColorDanger} !important` :
	theme.table.home.tdColor};

& > .sort {
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
	cursor: pointer;
}
`;
export const UiFlexTableCell = styled(MatTableCell)``;
export const UiTableContainer = styled(MatTableContainer)`
	flex: 1;
`;
export const UiTablePagination = styled(MatTablePagination)``;
export const UiTableHead = styled(MatTableHead)`
	background-color: ${({ theme }) => theme.palette.text.secondary};

`;
export const UiFirstTableRow = styled(MatTableRow)`
background-color:
	${(
		{ theme,
		isHome }
	) => isHome ?
	theme.palette.user.block.title :
	theme.palette.backstage.block.title};

& > th {
	font-size: 1rem;
	font-weight: 500;
	color: ${({ theme, isHome }) => isHome ? theme.table.home.thColor : theme.table.backstage.thColor};
}
`;
export const UiTableRow = styled(MatTableRow)`
color: ${({ theme }) => theme.table.backstage.tdColor};
transition: 0.35s;

&:nth-child(odd) {
	background-color:
		${(
			{ theme,
			isHome }
		) => {
		return isHome ? theme.table.backstage.tdBgColorWhite : theme.table.backstage.tdBgColorGray;
		}};
}

&:nth-child(even) {
	background-color: ${({ theme }) => theme.table.backstage.tdBgColorWhite};
}

&:hover {
	background-color: ${({ theme }) => theme.table.backstage.tdBgColorBlue};
}

& > td {
	min-width: 100px;
	color: ${({ theme }) => theme.table.backstage.tdColor};
	word-break: break-all;

	& > button {
		margin: auto;
	}

	& > div > * + * {
		margin-left: 10px;
	}
}
`;

export const InputBox = styled.div`
	display: flex;
	align-items: center;
	padding: 0 10px 10px;
`;

export const UiEmpty = styled.div`
	padding: 5rem;
	color: #8b90a0;
`;
