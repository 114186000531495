import React from 'react';
// import PropTypes from 'prop-types';
import { BsHeaderTitle, BsHeaderTab, ExamPaperTable, ExamTable, } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiExamPage,
  UiExamContainer,
} from './ExamPage.style';


/**
 * 試卷管理
 */

export const ExamPage = () => {
  const [{
    tabIndex,
  }, setState] = useSetState({
    tabIndex: 0,
  });

  const tabData = [
    { label: '試卷列表', onClick: () => {} },
    { label: '測驗列表', onClick: () => {} }
  ];

  const onTabChange = (e, newValue) => {
    setState({
      tabIndex: newValue,
    });
  };

  return (
    <UiExamPage>
      <BsHeaderTitle title="試卷管理" />
      <BsHeaderTab data={tabData} activeIndex={tabIndex} onChange={onTabChange} />
      <UiExamContainer>
        {
          tabIndex === 0 && <ExamPaperTable />
        }
        {
          tabIndex === 1 && <ExamTable />
        }
      </UiExamContainer>
    </UiExamPage>
  );
};

ExamPage.propTypes = {};


