import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

// import { UiDateTimePicker } from './DateTimePicker.style';


/**
 * 時間日期組件
 */

export const DateTimePicker = ({
  label,
  value,
  format = 'yyyy/MM/dd HH:mm',
  onChange = () => { },
  cancelLabel = '取消',
  okLabel = '確定',
  clearLabel = '清除',
  inputVariant = 'outlined',
  disablePast = false,
  ...props
}) => {

  const onValueChange = date => {
    onChange(date ? date.getTime() : null);
  };

  return (
    <KeyboardDateTimePicker
      variant="dialog"
      inputVariant={inputVariant}
      ampm={false}
      label={label}
      value={value ? new Date(value) : null}
      onChange={onValueChange}
      // onError={console.log}
      disablePast={disablePast}
      format={format}
      cancelLabel={cancelLabel}
      okLabel={okLabel}
      clearLabel={clearLabel}
      clearable
      {...props}
    />
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  value: PropTypes.number,
  format: PropTypes.string,
  onChange: PropTypes.func,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  okLabel: PropTypes.string,
  disablePast: PropTypes.bool
};


