import React from 'react';
import { UiBsContentContainer } from 'styles';
import { TeacherManagementTable, BsHeaderTitle } from 'components';


/**
 * 教師管理列表
 */

export const TeacherManagementPage = () => {
  return (
    <>
      <BsHeaderTitle title="教師管理" />
      {/* <BsTeacherManagementPageHeaderTab activeIndex={0} /> */}
      <UiBsContentContainer>
        <TeacherManagementTable />
      </UiBsContentContainer>
    </>
  );
};


