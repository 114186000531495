import React from 'react';
import PropTypes from 'prop-types';
import { BsOrganizationForm } from 'components';


/**
 * 在這邊描述這個組件
 */

export const OrganizationEdit = props => (
  <BsOrganizationForm  {...props} />
);

OrganizationEdit.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool
};


