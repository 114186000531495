import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'store/user';
import { useQuery } from 'utils/hooks/useQuery';
import {
  UiMyOrganizations,
} from './MyOrganizations.style';

/**
 * 我的組織列表
 */

export const MyOrganizations = () => {
  const history = useHistory();
  const query = useQuery();
  const [
    { myAllOrganizations },
  ] = useUser();

  useEffect(() => {
    if (!myAllOrganizations.isLoaded) return;
    const { ownOrganizationsDataMap } = myAllOrganizations;
    if(Object.keys(ownOrganizationsDataMap).length === 0 ) {
      // eslint-disable-next-line max-len
      window.location.href = `https://live${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/home`;
      return;
    }
    const id = Object.keys(ownOrganizationsDataMap)[0];
    history.push(`organization/${query.get('organizationId') || id}`);

  }, [myAllOrganizations]);

  return (
    <UiMyOrganizations>
      <div className="pulse">
          <h1>Loading...</h1>
      </div>
    </UiMyOrganizations>
  );
};


