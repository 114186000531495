import React from 'react';
// import PropTypes from 'prop-types';
import { 
  BsHeaderTitle, 
  MonitorSessionTable,
 } from 'components';
import { UiMonitorSessionPage } from './MonitorSessionPage.style';


/**
 * 看課管理頁面
 */

export const MonitorSessionPage = () => {
  return (
    <>
      <BsHeaderTitle title='看課管理' />
      <UiMonitorSessionPage>
        <MonitorSessionTable />
      </UiMonitorSessionPage>
    </>
  );
};

// MonitorSessionPage.propTypes = {};
