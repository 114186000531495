import React, { useEffect, useMemo } from 'react';
import SessionSummary from './SessionSummary';
import SessionRecord from './SessionRecord';
import SessionPerformance from './SessionPerformance';
// import SessionScreenshot from './SessionScreenshot';

import { Breadcrumbs, Icon, Loading, } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import { getGroupInfoById as getGroupInfoByIdApi } from 'services/api/organization/class';
import {
  getSessionReport as getSessionReportApi,
  getSessionSummary as getSessionSummaryApi,
} from 'services/api/organization/session';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { useServerTime } from 'utils/hooks/useServerTime';
import { 
  UiActionWrapper,
  UiActionContainer,
  UiPreviousPageContainer,
  UiVerticalLine,
  UiUpdatedTimeContainer,
  UiSessionReportWrapper,
  UiEmpty,
} from './SessionReport.style';

/**
 * 課堂報告
 */

export const SessionReport = () => {
  const history = useHistory();
  const { organizationId, classId, sessionId, timeSpanId } = useParams();
  const [{ classes } ]= useClass();
  const [, { getToday }] = useServerTime();
  const [{
    isLoading,
    classData,
    summaryData,
    attendanceData,
    performanceData,
    // screenshotData,
  }, setState]  = useSetState({
    isLoading: true,
    classData: null,
    summaryData: null,
    attendanceData: null,
    performanceData: null,
    // screenshotData: null,
  });

  /* 麵包屑 */
  const breadcrumbsList = classId
    ? [
        {
          icon: 'importContacts',
          name: '班級管理',
          link: `/organization/${organizationId}/class`
        },
        {
          icon: 'school',
          name: `${classes?.dataInfo?.name}-課程總覽`,
          link: `/organization/${organizationId}/class/${classId}/session`
        },
        {
          icon: 'assessment',
          name: '課堂報告',
          link: `/organization/${organizationId}/class/${classId}/session/${sessionId}/timespan/${timeSpanId}/report`
        }
      ]
    : [
        {
          icon: 'school',
          name: '課程總覽',
          link: `/organization/${organizationId}/class/session`
        },
        {
          icon: 'assessment',
          name: '課堂報告',
          link: `/organization/${organizationId}/class/session/${sessionId}/timespan/${timeSpanId}/report`
        }
      ];

  /* 取得課堂報告 */
  const fetchSessionReport = async () => {
    const res = await Promise.all([
      getSessionReportApi(organizationId, sessionId, timeSpanId, 'summary'),
      getSessionReportApi(organizationId, sessionId, timeSpanId, 'attendance'),
      getSessionReportApi(organizationId, sessionId, timeSpanId, 'performance'),
      // getSessionReportApi(organizationId, sessionId, timeSpanId, 'screenshot'),
    ]);

    res[0].isSuccess && setState({ summaryData: res[0].data });
    res[1].isSuccess && setState({ attendanceData: res[1].data });
    res[2].isSuccess && setState({ performanceData: res[2].data });
    // res[3].isSuccess && setState({ screenshotData: res[3].data });
  };

  /* 取得班級資訊 */
  const fetchGroupById = async () => {
    if(classId){
      const { isSuccess, data } = await getGroupInfoByIdApi(organizationId, classId);
      isSuccess && setState({ classData: data });
    }else{
      const { data: { session: { groupId }} } = await getSessionSummaryApi(organizationId, sessionId, timeSpanId);
      const { isSuccess, data } = await getGroupInfoByIdApi(organizationId, groupId);
      isSuccess && setState({ classData: data });
    }
  };

  /* 打課堂報告、班級資訊API */
  const fetchData = async () => {
    setState({ isLoading: true });
    await fetchSessionReport();
    await fetchGroupById();
    setState({ isLoading: false });
  };

  const isHaveData = useMemo(() => {
    return !summaryData || !attendanceData || !performanceData;
    // || !screenshotData // 課堂截圖之判斷
  }, [summaryData, attendanceData, performanceData]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* 回上層、麵包屑、更新時間 */}
      <UiActionWrapper>    
        <UiActionContainer>
          <UiPreviousPageContainer onClick={()=>{history.goBack();}}>
            <Icon title='回到上層' name='reply' size='1.25rem' />
            回到上層
          </UiPreviousPageContainer>
          <UiVerticalLine />
          <Breadcrumbs list={breadcrumbsList} />
        </UiActionContainer>
        <UiUpdatedTimeContainer>
          上次更新時間：{getToday('yyyy / MM / dd HH:mm')}
        </UiUpdatedTimeContainer>
      </UiActionWrapper>

      {
        isLoading
          ? <Loading />    
          : isHaveData
              ? <UiEmpty>查無此課程紀錄</UiEmpty>
              : /* 課程摘要、上課紀錄、課堂表現、課堂截圖 */
                <UiSessionReportWrapper>
                  <SessionSummary data={summaryData} classData={classData} />
                  <SessionRecord data={attendanceData} />
                  <SessionPerformance data={performanceData} />
                  {/* <SessionScreenshot data={screenshotData} /> */}
                </UiSessionReportWrapper>
      }
    </>
  );
};
