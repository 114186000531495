import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  AppBar as MatAppBar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AvatarMenu } from 'components';
import { UiHeader, UiToolbar, UiLogo } from './Header.style';
import getStatic from 'utils/getStatic';


const homeLogo = getStatic('logoW');

/**
 * Header
 */
const useStyles = makeStyles({
	paper: {
		boxShadow: '1px 2px 5px 1px rgba(0, 0, 0, 0.3)',
	},
});
export const Header = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <UiHeader>
      <MatAppBar className={classes.paper} position="static">
        <UiToolbar>
          <UiLogo onClick={() => { history.push('/home'); }}>
            <img src={homeLogo} alt='oneClass' />
          </UiLogo>
          <AvatarMenu />
        </UiToolbar>
      </MatAppBar>
    </UiHeader >
  );
};
