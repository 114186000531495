import styled from 'styled-components';
import {
  Tab as MuiTab,
  Tabs as MuiTabs,
 } from '@material-ui/core';
import { TabPanel as MuiTabPanel } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';

export const UiMuiTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: '12px',
  },
  indicator: {
    backgroundColor: theme.palette.blockTitle,
  },
}))(MuiTabs);

export const UiMuiTab = withStyles((theme) => ({
  root: {
    margin: 0,    
    padding: '0 16px',
  },
  selected: {
    color: theme.palette.blockTitle,
  },
}))(MuiTab);

export const UiRecordingTimeWrapper = styled.div`
  margin-bottom: 12px;
`;

export const UiStartedRecordingTime = styled.div`
  line-height: initial;
`;

export const UiStoppedRecordingTime = styled.div`
  line-height: initial;
`;

export const UiMuiTabPanel = withStyles({
  root: {
    padding: 0,
  },
})(MuiTabPanel);

export const UiTabPanelWrapper = styled.div`
  &.tabPanel-enter {
    opacity: 0;
    transform: scale(0.9);
  };
  &.tabPanel-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
  };
  &.tabPanel-exit {
    opacity: 1;
  };
  &.tabPanel-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  };
`;

export const UiTableWrapper = styled.div`
  max-height: 540px;
  overflow: auto;

  ::-webkit-scrollbar { width: 8px; };
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
  };
`;