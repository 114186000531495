import React, { useRef } from 'react';
import { useClickAway } from 'ahooks';
import PropTypes from 'prop-types';
import { UiOptionMenu, UiOption, } from './OptionMenu.style';


/**
 * 收納菜單
 */


export const OptionMenu = (props) => {
  const { data, toggleOptionMenu = () => {} } = props;

  const ref = useRef();
  useClickAway(() => {
    toggleOptionMenu();
  },ref);

  return <UiOptionMenu ref={ref}>
      {
        data.map((value, index) => {
          const { label, onClick, } = value;
          return <UiOption key={index} onClick={onClick}>{label}</UiOption>;
        })
      }
  </UiOptionMenu>;
};

OptionMenu.propTypes = {
  data: PropTypes.array,
  toggleOptionMenu: PropTypes.func
};


