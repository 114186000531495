import styled from 'styled-components';

export const UiExamPaperTable = styled.div`
	display: block;

	button {
		margin: 0 10px;
	}
`;

export const UiExamIcons = styled.div`
	> svg {
		font-size: 2rem;
		cursor: pointer;
	}
`;