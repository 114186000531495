import styled from 'styled-components';

export const UiStudentAnswer = styled.div`
	display: block;

	button {
		margin: 0 10px;
	}

	.title {
		font-size: 24px;
		color: #121232;
		line-height: 36px;
	}
`;
