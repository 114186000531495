import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, ResourceModal, } from 'components';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiSessionSummaryWrapper,
  UiTitleWrapper,
  UiClassNameBox,
  UiClassOwnerContainer,
  UiClassOwnerIconBox,
  UiOwnerBox,
  UiHorizontalLine,
  UiVerticalLine,
  UiContentWrapper,
  UiContentContainer,
  UiSessionSummaryBox,
  UiSummaryItemBox,
  UiSessionInfoBox,
  UiInfoIconBox,
  UiInfoContentBox,
  UiInfoTitle,
  UiInfoContent,
  UiInfoContentItem
} from './SessionSummary.style';

export const SessionSummary = (props) => {
  const { sessionId } = useParams();
  const { name: className, ownerName } = props?.classData ?? {};
  const {
    endAt,
    hostNickname,
    sessionActualEndAt,
    sessionActualStartAt,
    sessionName,
    startAt,
    userRecords,
  } = props?.data ?? {};
  const [{
    isOpenResourceModal,
  }, setState ] = useSetState({
    isOpenResourceModal: false,
  });

  const sessionDate = startAt && format(startAt, 'yyyy.MM.dd');  // 課程日期
  const sessionStartTime = startAt && format(startAt, 'HH:mm');  // 課程開始時間
  const sessionEndTime = endAt && format(endAt, 'HH:mm');        // 課程結束時間
  const sessionDur = Math.floor((endAt - startAt)/1000/60);      // 預定課程時長
  const sessionAttendance = userRecords.filter(user => user?.attendances?.length > 0).length;  // 出席人數
  const sessionAbsentee = userRecords.filter(user => user?.attendances?.length <= 0).length;   // 缺席人數
  // 實際課程時長
  const sessionActualDur = sessionActualEndAt
    ? Math.floor((sessionActualEndAt - sessionActualStartAt)/1000/60) 
    : '上課中';  

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false});
  };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = resourceValue => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank');  // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  return (
    <>
      { /* 課程教材 彈窗 */
        isOpenResourceModal &&
          <ResourceModal
            sessionId={sessionId}
            isOpenResourceModal={isOpenResourceModal}
            onCancel={clickResourceModalCancelHandler}
            onOk={clickResourceModalOkHandler}
          />
      }


      <UiSessionSummaryWrapper>
        {/* 班級名、導師名 */}
        <UiTitleWrapper>
          <UiClassNameBox>{className}</UiClassNameBox>
          <UiClassOwnerContainer>
            <UiClassOwnerIconBox>
              <Icon.Svg
                title='導師'
                name='ChalkboardTeacher'
                size='2rem'
                color='#A1A4B1'
              />
            </UiClassOwnerIconBox>
            <UiOwnerBox color='#A1A4B1'>導師</UiOwnerBox>
            <UiVerticalLine />
            <UiOwnerBox>{ownerName}</UiOwnerBox>
          </UiClassOwnerContainer>
        </UiTitleWrapper>

        <UiHorizontalLine></UiHorizontalLine>

        <UiContentWrapper>
          {/* 課程資訊 */}
          <UiContentContainer>
            <UiSessionSummaryBox>
              <UiSummaryItemBox fontSize='1.6rem' fontFamily='Noto Sans CJK TC'>{sessionName}</UiSummaryItemBox>
              <UiSummaryItemBox fontSize='1.07rem' fontFamily='Noto Sans CJK TC' color='#8B90A0'>
                {hostNickname}
              </UiSummaryItemBox>
              <UiSummaryItemBox fontSize='0.93rem'>
                {sessionDate} | {sessionStartTime}-{sessionEndTime}
              </UiSummaryItemBox>
              <UiSummaryItemBox>
                <Button
                  icon='videocam'
                  borderRadius='24px'
                  buttonPadding='12px 30px'
                  onClick={()=>window.open(`${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${sessionId}`, '_blank')}
                >
                  課程錄影
                </Button>
              </UiSummaryItemBox>
              <UiSummaryItemBox>
                <Button
                  iconSvg='BookOpen'
                  borderRadius='24px'
                  buttonColor='info'
                  buttonPadding='12px 30px'
                  onClick={()=>{setState({ isOpenResourceModal: true });}}
                >
                  查看教材
                </Button>
              </UiSummaryItemBox>
            </UiSessionSummaryBox>
          </UiContentContainer>

          {/* 預定課程時長、出席學生 */}
          <UiContentContainer>
            <UiSessionInfoBox>
              <UiInfoIconBox>
                <Icon.Svg name='CalendarSolid' size='32px' />
              </UiInfoIconBox>
              <UiInfoContentBox>
                <UiInfoTitle>預定課程時長</UiInfoTitle>
                <UiInfoContent>
                  <UiInfoContentItem>{sessionDur}</UiInfoContentItem>
                  <UiInfoContentItem>分</UiInfoContentItem>
                </UiInfoContent>
              </UiInfoContentBox>
            </UiSessionInfoBox>
            <UiSessionInfoBox>
              <UiInfoIconBox>
                <Icon.Svg name='DoorIn' size='32px' />
              </UiInfoIconBox>
              <UiInfoContentBox>
                <UiInfoTitle>出席學生</UiInfoTitle>
                <UiInfoContent>
                  <UiInfoContentItem>{sessionAttendance}</UiInfoContentItem>
                  <UiInfoContentItem>人</UiInfoContentItem>
                </UiInfoContent>
              </UiInfoContentBox>
            </UiSessionInfoBox>
          </UiContentContainer>

          {/* 實際課程時長、缺席學生 */}
          <UiContentContainer>
            <UiSessionInfoBox>
              <UiInfoIconBox>
                <Icon.Svg name='Clock' size='32px' />
              </UiInfoIconBox>
              <UiInfoContentBox>
                <UiInfoTitle>實際課程時長</UiInfoTitle>
                <UiInfoContent>
                  <UiInfoContentItem>{sessionActualDur}</UiInfoContentItem>
                  {sessionActualDur !== '上課中' && <UiInfoContentItem>分</UiInfoContentItem>}
                </UiInfoContent>
              </UiInfoContentBox>
            </UiSessionInfoBox>
            <UiSessionInfoBox>
              <UiInfoIconBox>
                <Icon.Svg name='DoorOut' size='32px' />
              </UiInfoIconBox>
              <UiInfoContentBox>
                <UiInfoTitle>缺席學生</UiInfoTitle>
                <UiInfoContent>
                  <UiInfoContentItem>{sessionAbsentee}</UiInfoContentItem>
                  <UiInfoContentItem>人</UiInfoContentItem>
                </UiInfoContent>
              </UiInfoContentBox>
            </UiSessionInfoBox>
          </UiContentContainer>
        </UiContentWrapper>
      </UiSessionSummaryWrapper>
    </>
  );
};

SessionSummary.propTypes = {
  data: PropTypes.shape({

  }),
  classData: PropTypes.shape({
    name: PropTypes.string,
    ownerName: PropTypes.string,
  })
};
