import React from 'react';
import PropTypes from 'prop-types';
import { BsModal, CancelSessionModal, Icon, OptionMenu } from 'components';
import { EDIT_STATUS } from 'constants/index';
import { getTime } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import {
  cancelOrganizationGroupSession as cancelOrganizationGroupSessionApi,
} from 'services/api/organization/session';
import { useSessions } from 'store/sessions';
import { ALERT_MESSAGE, useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { UiIconBox } from './SessionManagementTable.style';

export const MoreActionComponents = props => {
  const fetchSessions = props.otherObject.fetchSessions;  // SessionManagementTable.js 之 fetchSessions function
  const {
    endAt,
    groupId,
    id: sessionId,
    status,
    // name,      // 刪除課程用參數
    // timeSpanId // 刪除課程用參數
  } = props.params;

  const history = useHistory();
  const { setAlert } = useAlert();
  const { organizationId } = useParams();
  const [, { deleteOrganizationGroupSession }] = useSessions();
  const [
    {
      cancelReason,
      isLoading,
      isOpenCancelSessionModal,
      isOpenCancelSessionDoubleCheckModal,
      isOpenDeleteModal,
      isOpenOptionMenu,
      targetClassId,
      targetSession,
      targetSessionId,
      targetTimeSpanId,
    },
    setState
  ] = useSetState({
    cancelReason: null,
    isLoading: false,
    isOpenCancelSessionModal: false,
    isOpenCancelSessionDoubleCheckModal: false,
    isOpenOptionMenu: false,
    isOpenDeleteModal: false,
    targetClassId: null,
    targetSession: null,
    targetSessionId: null,
    targetTimeSpanId: null,
  });

  const isExpired = (getTime(new Date(endAt)) < getTime(new Date())) ? true : false;

  const optionMenuData = [
    {
      label: '複製課程',
      onClick: () => {
        history.push(
          `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.COPY}`
        );
      }
    },
    // {
    //   label: '刪除課程',
    //   onClick: () => {
    //     onClickDeleteSessionHandler(name, groupId, sessionId, timeSpanId);
    //   }
    // }
  ];

  /* 「編輯課程」插入至 optionMenuData，避免空DOM用 */
  const optionMenuDataEdit = {
    label: '編輯課程',
    onClick: () => {
      history.push(
        `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.EDIT}`
      );
    }
  };
  !isExpired && optionMenuData.unshift(optionMenuDataEdit);

  /* 「取消課程」插入至 optionMenuData，避免空DOM用 */
  const optionMenuDataCancel = {
    label: '取消課程',
    onClick: () => setState({ isOpenCancelSessionModal: true})
  };
  status !== 'cancel' && optionMenuData.push(optionMenuDataCancel);



  /* 開啟關閉 OptionMenu */
  const toggleOptionMenu = () => {
    setState({ isOpenOptionMenu: !isOpenOptionMenu });
  };

  /* 開啟關閉 delete modal */
  const toggleDeleteModal = () => {
    setState({ isOpenDeleteModal: !isOpenDeleteModal });
  };

  /* 刪除課程 handler */
  // const onClickDeleteSessionHandler = (name,targetClass,sessionId,timeSpanId) => {
  //   name && setState({targetSession: name});
  //   targetClass && setState({targetClassId: targetClass});
  //   sessionId && setState({
  //     targetSessionId: sessionId,
  //     targetTimeSpanId: timeSpanId
  //   });
  //   toggleDeleteModal();
  // };

  /* 刪除彈窗 click 確認刪除 */
  const onDeleteSession = async () => {
    setState({isLoading:true});
    const isSuccess = await deleteOrganizationGroupSession(targetClassId,targetSessionId,targetTimeSpanId);
    setState({isLoading:false});
    toggleDeleteModal();
    if(isSuccess) await fetchSessions();
  };

  /* change 取消課程彈窗 取消原因 */
  const changeCancelReasonHandler = (reason) => {
    setState({ cancelReason: reason });
  };
  /* click 取消課程彈窗 確認按鈕 */
  const clickOkOfCancelSessionModalHandler = () => {
    if(!cancelReason) return;
    setState({isOpenCancelSessionDoubleCheckModal: true});
  };
  /* click 取消課程 double check 彈窗 確認按鈕 */
  const clickOkOfCancelSessionDoubleCheckHandler = async () => {
    setState({ isLoading: true });
    const payload = { cancelReason };
    const { isSuccess } = await cancelOrganizationGroupSessionApi(organizationId, groupId, sessionId, payload);
    if(isSuccess){
      setState({
        isLoading: false,
        isOpenCancelSessionModal: false,
        isOpenCancelSessionDoubleCheckModal: false,
      });
      setAlert(ALERT_MESSAGE.CANCEL_SUCCESS, 'success');
      fetchSessions();
    }
    else{
      setAlert(ALERT_MESSAGE.CANCEL_FAIL, 'error');
    }
  };

  return (
    <>
      {/* 取消課程 彈窗 */}
      <CancelSessionModal
        cancelReason={cancelReason}
        isLoading={isLoading}
        isOpen={isOpenCancelSessionModal}
        isOpenDoubleCheck={isOpenCancelSessionDoubleCheckModal}
        onCancel={()=>setState({isOpenCancelSessionModal: false})}
        onCancelDoubleCheck={()=>setState({isOpenCancelSessionDoubleCheckModal: false})}
        onChange={(changeCancelReasonHandler)}
        onOk={clickOkOfCancelSessionModalHandler}
        onOkDoubleCheck={clickOkOfCancelSessionDoubleCheckHandler}
      />

      <BsModal
        open={isOpenDeleteModal}
        title='是否要刪除此課程'
        onOk={onDeleteSession}
        onCancel={toggleDeleteModal}
        isLoading={isLoading}
      >
        確認刪除課程 <span style={{color: '#f00'}}>{`"${targetSession}"`}</span>
      </BsModal>

      <UiIconBox>
        <Icon
          title='更多'
          name='moreVert'
          haveBg={false}
          icolor='#000'
          onClick={toggleOptionMenu}
        />
         {isOpenOptionMenu && 
          <OptionMenu
            data={optionMenuData}
            toggleOptionMenu={toggleOptionMenu}
          />
        }
      </UiIconBox>
    </>
  );
};

MoreActionComponents.propTypes = {
  params: PropTypes.shape({
    endAt: PropTypes.number,
    groupId: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.string,
    // name: PropTypes.string,       // 刪除課程用參數
    // timeSpanId: PropTypes.string  // 刪除課程用參數
  }),
  otherObject: PropTypes.shape({
    fetchSessions: PropTypes.func,
  }),
};
