import styled from 'styled-components';

export const UiActionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;
`;

export const UiActionContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const UiPreviousPageContainer = styled.div`
	width: 90px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 500;
`;

export const UiVerticalLine = styled.hr`
	height: 16px;
	width: 2px;
	background: #D5D7DE;
	border: none;
	border-radius: 2px;
	margin: 0 16px;
`;

export const UiUpdatedTimeContainer = styled.div`
	color: #8B90A0;
	font-size: 14px;
`;

export const UiSessionReportWrapper = styled.div`
	margin-left:16px;
`;

export const UiEmpty = styled.div`
`;


