import React, { useEffect, useMemo, } from 'react';

import { BsModal, BsHeaderTitle, Loading, SessionForm } from 'components';
import { useHistory } from 'react-router-dom';
import { userPaidProducts as userPaidProductsApi } from 'services/api/home/sessions';
import { useCustomer } from 'store/customer';
import { useSetState } from 'utils/hooks/useSetState';
import { UiBsContentContainer } from 'styles';
import {
  UiOrganizationSessionFormPage,
} from './OrganizationSessionFormPage.style';

/**
 * 創建課程
 */

export const OrganizationSessionFormPage = props => {
  const history = useHistory();
  const [{ customers }, { getCustomers }] = useCustomer();
  const [{
    isFetchedCustomers,   // 是否已經 fetch 學生資料
    isFetchedUserPaidProducts,  // 是否已經 fetch 課程類型資料
    isLoading,
    isOpenWarningModal,   // 是否開啟警告彈窗
    userPaidProducts,     // 學生（課程類型）資料
    warningModalText,     // 警告彈窗顯示文字
  }, setState ] = useSetState({
    isFetchedCustomers: false,
    isFetchedUserPaidProducts: false,
    isLoading: true,
    isOpenWarningModal: false,
    userPaidProducts: [],
    warningModalText: null,
  });

  /* click 警告彈窗 Ok */
  const clickOkHandler = () => {
    history.goBack();
  };

  /* 打API，取得學生資訊（數量）*/
  const fetchCustomers = async () => {
    const params = { rowsPage: 100, };
    await getCustomers(params);
    setState({ isFetchedCustomers: true });
  };
  
  /* 打API，取得課程類型（學生可用課程）*/
  const fetchUserPaidProducts = async () => {
    const userIds = customers.data.map(student => student.id);
    const payload = { userIds };
    const { isSuccess, data } = await userPaidProductsApi(payload);

    if(isSuccess) {
      setState({
        userPaidProducts: data.data,
        isFetchedUserPaidProducts: true
      });
    }
  };

  /* 取得沒有課程類型（商品）名單 */
  const noProductStudentList = useMemo(() => {
    // 取得沒有商品的學生 id
    const studentIds = userPaidProducts.filter(student => student.paidProducts.length === 0)
                                      .map(student => student.userId);
    // 取得沒有商品的學生名稱
    return customers.data.filter(item => studentIds.includes(item.id))
                        .map(item => item.nickname);
  }, [userPaidProducts]);

  /* 學生的課程類型（商品）是否交集 */
  const isIntersectionByProducts = useMemo(() => {
    // 所有學生課程類型（商品）名稱的集合陣列，ex. [[國小課堂, 國小輔導], [國小課堂, 國中課堂]]
    const data = userPaidProducts.map(student => 
      student.paidProducts.map(product => 
        product.productName
      )
    );
    if(data.length === 0) return;

    // 取集合陣列之交集
    const intersection = data.reduce((prev, curr) =>
      prev.filter(item => curr.includes(item))
    );

    if(intersection.length > 0) {
      return true;
    }else {
      return false;
    }
  }, [userPaidProducts]);

  /* 取得取得學生資訊（數量）*/
  useEffect(() => {
    setState({ isLoading: true });
    fetchCustomers();
  }, []);

  useEffect(() => {
    // 班級無學生 -> 顯示「沒有學生」彈窗
    if(isFetchedCustomers && customers.data.length === 0){
      setState({
        isOpenWarningModal: true,
        warningModalText: '班級尚未加入學生',
      });
      return;
    }

    // 打API，取得課程類型
    if(isFetchedCustomers){
      fetchUserPaidProducts();
    }
  }, [isFetchedCustomers]);

  useEffect(() => {
    // 學生沒有課程類型（商品）-> 顯示警告彈窗
    if(isFetchedUserPaidProducts && noProductStudentList.length > 0) {
      setState({
        isOpenWarningModal: true,
        warningModalText: `${noProductStudentList.join(', ')}查無可用課程`,
      });
      return;
    }

    // 學生課程類型（商品）沒有交集 -> 顯示警告彈窗
    if(isFetchedUserPaidProducts && !isIntersectionByProducts) {
      setState({
        isOpenWarningModal: true,
        warningModalText: '查無可用課程',
      });
      return;
    }

    // 結束 loading
    if(isFetchedUserPaidProducts) {
      setState({ isLoading: false });
    }
  }, [isFetchedUserPaidProducts]);

  return (
    <>
      {/* 警告彈窗 */}
      <BsModal
        cancelDisplay={false}
        isFull={true}
        open={isOpenWarningModal}
        title='新增課程失敗'
        onOk={clickOkHandler}
      >
        {warningModalText}
      </BsModal>

      <BsHeaderTitle title="課程總覽" />

      <UiOrganizationSessionFormPage>
        <UiBsContentContainer>
          {
            isLoading
              ? <Loading />
              : <SessionForm {...props} /> // 新增、編輯課程內容
          }
        </UiBsContentContainer>
      </UiOrganizationSessionFormPage>
    </>
  );
};
